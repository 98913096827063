import { API } from '../../utils/api';
import {
  RESET_SEARCH_DATA,
  RESET_SELECTED_TUTOR,
  SET_SEARCH_FILTER,
  SET_SEARCH_RESULT,
  SET_SELECTED_TUTOR,
} from './types';

export function userGetAllTutors(data) {
  return (dispatch) => {
    // return API.post('/tutor/all')
    return API.post('/tutor/list', data).then((res) => {
      return res.data;
    });
  };
}

export function setSearchFilter(data) {
  return {
    type: SET_SEARCH_FILTER,
    payload: data,
  };
}

export function setSearchResult(data) {
  return {
    type: SET_SEARCH_RESULT,
    payload: data,
  };
}

export function setSelectedTutor(data) {
  return {
    type: SET_SELECTED_TUTOR,
    payload: data,
  };
}

export function resetSelectedTutor() {
  return {
    type: RESET_SELECTED_TUTOR,
    payload: null,
  };
}

export function resetSearchData() {
  return {
    type: RESET_SEARCH_DATA,
    payload: null,
  };
}
