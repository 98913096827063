/* eslint-disable no-undef */
import authApi from '../api/auth';

export function register(value) {
	return async function (dispatch) {
		try {
			const {
				email,
				password,
				phone,
				firstName,
				lastName,
				type,
				cv,
				motivation,
			} = value;

			const files = new FormData();
			files.append('files', cv);
			files.append('files', motivation);
			const payload = {
				email,
				password,
				firstName,
				lastName,
				phone,
				type,
				files,
			};

			const { data } = await authApi.register(payload);

			return data;
		} catch (error) {
			if (error.response) {
				return error.response.data;
			} else {
				return error;
			}
		}
	};
}

export function registerStudent(value) {
	return async function (dispatch) {
		try {
			const {
				email,
				password,
				phone,
				firstName,
				lastName,
				living_in_country,
				living_in_city,
				session_type,
				learn_language,
				type,
				withRef,
			} = value;

			const payload = {
				email,
				phone,
				password,
				firstName,
				lastName,
				living_in_country,
				living_in_city,
				session_type,
				learn_language,
				type,
				withRef,
			};

			const { data } = await authApi.registerStudent(payload);

			return data;
		} catch (error) {
			if (error.response) {
				return error.response.data;
			} else {
				return error;
			}
		}
	};
}
