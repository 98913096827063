import React from 'react';
import ResetPassword from './resetPassword';

function ResetPasswordPage(props) {
  const { history } = props;
  return (
    <div className="my-50 center-vh pace-done">
      <ResetPassword history={history} />
    </div>
  );
}

export default ResetPasswordPage;
