import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const StyledCheckbox = styled.div`
  background: ${(props) => (props.checked ? '#b3c7ea' : 'white')};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: ${(props) =>
    props.checked
      ? 'solid 3px #b3c7ea'
      : props.error
      ? 'solid 3px red'
      : 'solid 3px #b3c7ea'};
  transition: all 150ms;
`;
const CustomRadioButton = ({
  className,
  checked,
  disabled,
  error,
  ...props
}) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox disabled={disabled} checked={checked} {...props} />
    <StyledCheckbox checked={checked} error={error}></StyledCheckbox>
  </CheckboxContainer>
);

export default CustomRadioButton;
