import React from 'react';
import AccountActivation from './accountActivation';

class AccountActivationPage extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div className="my-40 center-vh pace-done">
        <AccountActivation history={history} />
      </div>
    );
  }
}

export default AccountActivationPage;
