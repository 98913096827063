import React from 'react';

export default function LoadingGift() {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="spinner-dots">
        <span className="dot1"></span>
        <span className="dot2 mx-2"></span>
        <span className="dot3"></span>
      </div>
      <p className="mb-0 mx-3">Please wait</p>
      <div className="spinner-dots">
        <span className="dot1"></span>
        <span className="dot2 mx-2"></span>
        <span className="dot3"></span>
      </div>
    </div>
  );
}
