import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MDSpinner from "react-md-spinner";
import PropTypes from "prop-types";
import classnames from "classnames";
import Select from "react-select";
import queryString from "query-string";

import { validateInputStudent } from "../common/validations/signup";
import CustomCheckbox from "../common/CustomCheckbox";
import TextFieldGroupLogin from "../common/TextFieldGroupLogin";
import Notification from "../common/Notification";

import { registerStudent } from "../../redux/effects/auth";
import { setCurrentUser } from "../../redux/actions/authActions";
import {
	userGetAllLanguages,
	userGetAllWorldCountries,
	userGetCountryDetails,
} from "../../redux/actions/commonActions";
import { BOOKING_DOMAIN } from "../../utils/api";
import dataSessionType from "../../data/session_type.json";
import SelectFieldGroup from "components/common/SelectFieldGroup";
import SelectLanguageFieldGroup from "components/common/SelectLanguageFieldGroup";

const mapActionToProps = (dispatch) =>
	bindActionCreators(
		{
			registerStudent,
			setCurrentUser,
			userGetAllLanguages,
			userGetAllWorldCountries,
			userGetCountryDetails,
		},
		dispatch
	);

function SignupFormStudent(props) {
	const {
		registerStudent,
		userGetAllLanguages,
		userGetAllWorldCountries,
		userGetCountryDetails,
		setCurrentUser,
		location,
	} = props;
	const query = queryString.parse(location.search);

	const [notification, setNotification] = useState({
		text: "",
		isError: false,
	});
	const [errors, setErrors] = useState({});
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phone, setPhoneNumber] = useState("");
	const [loading, setLoading] = useState(false);
	const [isAgree, setIsAgree] = useState(false);
	const [country_options, setCountryOpts] = useState([]);
	const [city_options, setCityOpts] = useState([]);
	const [countryValue, setCountryValue] = useState({
		value: "NL",
		label: "Netherlands",
	});
	const [cityValue, setCityValue] = useState({
		value: "Amstelveen",
		label: "Amstelveen",
		country: "Netherlands",
	});
	const [language_options, setLangOpts] = useState([]);
	const [learnLangValue, setLearnLangValue] = useState({
		label: "English",
		value: "english",
	});
	const [sessionValue, setSessionValue] = useState({
		label: "Online session",
		value: "skype",
	});

	const handleInitialData = async () => {
		await userGetAllLanguages()
			.then((res) => {
				if (res.status === 200) {
					// SORT LANGUAGE OPTIONS
					const sorted_language_options = res.message.data.sort(
						(a, b) =>
							a.value > b.value ? 1 : b.value > a.value ? -1 : 0
					);
					setLangOpts(sorted_language_options);
				}
			})
			.catch((err) => {
				console.log(err);
			});

		await userGetAllWorldCountries()
			.then((res) => {
				if (res.status === 200) {
					setCountryOpts(res.message.data);
				}
			})
			.catch((err) => {
				console.log(err);
			});

		await userGetCountryDetails({
			country_code: countryValue.value,
		})
			.then((res) => {
				if (res.status === 200) {
					setCityOpts(res.message.data[0].city_options);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	async function onSubmit(e) {
		e.preventDefault();
		const { isValid, errors } = validateInputStudent({
			email,
			password,
			phone,
			first_name: firstName,
			last_name: lastName,
			is_agree: isAgree,
			mode: "student",
		});
		if (!isValid) {
			setErrors(errors);
		} else {
			setErrors({});
			setLoading(true);
			const response = await registerStudent({
				email,
				password,
				phone,
				firstName,
				lastName,
				// living_in_country: {
				// 	label: countryValue.label,
				// 	value: countryValue.value,
				// },
				// living_in_city: cityValue,
				session_type: sessionValue,
				learn_language: learnLangValue,
				type: "student",
				withRef: query && query.ref ? true : false,
			});
			if (response.status === 200) {
				if (query && query.ref) {
					const { token, user } = response.message.data;
					localStorage.setItem("token", token.key);
					localStorage.setItem("exp", token.exp);
					localStorage.setItem("user", JSON.stringify(user));
					setCurrentUser(user);
					setLoading(false);
					window.location.href = `${BOOKING_DOMAIN}/book-language-course/${query.ref}`;
				} else {
					setLoading(false);
					window.location.href =
						"https://flowently.com/thank-you-sign-up";
				}
			} else {
				setLoading(false);
				setNotification({
					text: response.message,
					isError: true,
				});
			}
		}
	}

	useEffect(() => {
		handleInitialData();
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className=''>
			<h5 className='text-center text-capitalize fs-26 font-weight-bold mb-4'>
				Sign up
			</h5>
			{notification.text && <Notification notification={notification} />}
			<div className=''>
				<TextFieldGroupLogin
					autoFocus={true}
					error={errors.email}
					label='Email'
					onChange={(e) => {
						setErrors((prevState) => ({
							...prevState,
							email: null,
						}));
						setEmail(e.target.value);
					}}
					alreadyHaveAccountButton={true}
					loginLink={"/login/student"}
					value={email}
					field='email'
					disabled={loading}
					placeholder='email@example.com'
				/>
				<TextFieldGroupLogin
					error={errors.password}
					label='Password'
					onChange={(e) => {
						setErrors((prevState) => ({
							...prevState,
							password: null,
						}));
						setPassword(e.target.value);
					}}
					value={password}
					type='password'
					field='password'
					disabled={loading}
					placeholder='Account password'
				/>
				<label className='col-form-label font-weight-bold fs-15 pl-12'>
					Name
				</label>
				<div className='row m-auto'>
					<div className='col-6 pl-0 pr-2'>
						<div
							className={classnames("form-group", {
								"has-error has-danger": errors.first_name,
							})}
						>
							<div className=''>
								<input
									value={firstName}
									onChange={(e) => {
										setErrors((prevState) => ({
											...prevState,
											first_name: null,
										}));
										setFirstName(e.target.value);
									}}
									name='first_name'
									className='form-control px-20 py-2'
									style={{ borderRadius: "25px!important" }}
									disabled={loading}
									id='first_name'
									placeholder='First name'
								/>
								{errors.first_name && (
									<div className='form-control-feedback'>
										{errors.first_name}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className='col-6 px-0'>
						<div
							className={classnames("form-group", {
								"has-error has-danger": errors.last_name,
							})}
						>
							<div className=''>
								<input
									value={lastName}
									onChange={(e) => {
										setErrors((prevState) => ({
											...prevState,
											last_name: null,
										}));
										setLastName(e.target.value);
									}}
									name='last_name'
									className='form-control px-20 py-2'
									style={{ borderRadius: "25px!important" }}
									disabled={loading}
									id='last_name'
									placeholder='Last name'
								/>
								{errors.last_name && (
									<div className='form-control-feedback'>
										{errors.last_name}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<TextFieldGroupLogin
					error={errors.phone}
					label='Phone'
					onChange={(e) => {
						setErrors((prevState) => ({
							...prevState,
							phone: null,
						}));
						setPhoneNumber(e.target.value);
					}}
					value={phone}
					field='phone'
					disabled={loading}
					placeholder='Your phone number'
				/>
				{/* <div className='row'>
					<div className='col-md-6'>
						<SelectFieldGroup
							label='Looking for a tutor in'
							placeholder='Select...'
							value={countryValue}
							options={country_options}
							onChange={(value) => {
								setCountryValue(value);
								setCityValue([]);
								setCityOpts(value.city_options);
							}}
							error={!countryValue}
							isDisabled={loading}
							isClearable={false}
							isSearchable={true}
						/>
					</div>
					<div className='col-md-6'>
						<SelectFieldGroup
							label='Area'
							placeholder='Select...'
							value={cityValue}
							options={city_options}
							onChange={(value) => {
								setCityValue(value);
							}}
							error={!cityValue || !countryValue}
							isDisabled={loading}
							isClearable={false}
							isSearchable={true}
						/>
					</div>
				</div> */}
				<SelectLanguageFieldGroup
					label='Language you want to learn'
					value={learnLangValue}
					options={language_options}
					onChange={(selectedOption) => {
						setLearnLangValue(selectedOption);
					}}
					error={!learnLangValue}
					isClearable={false}
					isDisabled={loading}
					isSearchable={false}
				/>
				<SelectFieldGroup
					label='Session'
					placeholder='Select...'
					value={sessionValue}
					onChange={(selectedOption) => {
						setSessionValue(selectedOption);
					}}
					options={dataSessionType}
					error={!sessionValue}
					isDisabled={loading}
					isClearable={false}
					isSearchable={true}
				/>

				<label className='row ml-auto px-1'>
					<div className={errors.is_agree ? "mt-4" : "mb-3 mt-4"}>
						<CustomCheckbox
							onChange={() => {
								if (!isAgree) {
									setErrors((prevState) => ({
										...prevState,
										is_agree: "",
									}));
								}
								setIsAgree((prevState) => !prevState);
							}}
							className='mr-3'
							checked={isAgree}
						/>
						<span>I agree to Flowently`s</span>
						<a
							className='mx-1 text-info'
							href='https://flowently.com/terms-conditions/'
							rel='noopener noreferrer'
							target='_blank'
						>
							Terms
						</a>
						<span>and</span>
						<a
							className='mx-1 text-info'
							href='https://flowently.com/privacy-statement/'
							rel='noopener noreferrer'
							target='_blank'
						>
							Privacy Policy
						</a>
						{errors.is_agree && (
							<p className='form-control-feedback ml-0 mt-1'>
								{errors.is_agree}
							</p>
						)}
					</div>
				</label>

				<div className='form-group mt-10'>
					{query && query.ref ? (
						<button
							disabled={loading}
							className='btn btn-block p-10 btn-main-pink fs-16 fw-500'
							onClick={onSubmit}
						>
							{loading ? <MDSpinner /> : "Sign up and Login"}
						</button>
					) : (
						<button
							disabled={loading}
							onClick={onSubmit}
							className='btn btn-block p-10 btn-main-pink fs-16 fw-500'
						>
							{loading ? <MDSpinner /> : "Sign up for Flowently"}
						</button>
					)}
				</div>

				<div className='d-flex my-20 fs-14 justify-content-between'>
					<Link
						to='/forget-password/student'
						disabled={loading}
						className='primary-hover fs-13'
					>
						Forgot your password ?
					</Link>
				</div>
			</div>
		</div>
	);
}

SignupFormStudent.propTypes = {
	userGetAllLanguages: PropTypes.func,
	userGetAllWorldCountries: PropTypes.func,
	userGetCountryDetails: PropTypes.func,
	registerStudent: PropTypes.func,
	setCurrentUser: PropTypes.func,
};

export default connect(null, mapActionToProps)(SignupFormStudent);
