import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import moment from 'moment';
import { isEmpty, sortBy } from 'lodash';
import Rating from 'react-rating';

import TextAreaFieldGroup from '../../../common/TextAreaFieldGroup';
import TextFieldGroupLogin from '../../../common/TextFieldGroupLogin';
import StudentAppointmentNotesModal from './studentAppointmentNotesModal';

import { connect } from 'react-redux';
import { validateInputReview } from '../../../common/validations/studentProfile';
import {
  userCancelAppointment,
  userGetSingleAppointment,
  userSubmitReview,
} from '../../../../redux/actions/appointmentActions';
import CancelBookingOverview from '../cancelBookingOverview';
import RebookTutorModal from './RebookTutorModal';

class studentAppointmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isBookingCanceled: false,
      rating_value: 0,
      isLoading: false,
      isLoading_review: false,
      notification: {},
      isMobileScreen: false,
      isOpenNotesModal: false,
      isUpcomingAppointment: false,
      isOpenReview: false,
      isOpenRebookModal: false,
      ratingHasChanged: false,
      errors: {},
      default_avatar: '/assets/img/icon/male.svg',
      isCancelModalOpen: false,
    };
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.data !== this.props.data) {
      this.getSingleAppointment();
      return false;
    }
    return true;
  };

  componentWillMount = () => {
    const { location } = this.props;
    if (!location.state) {
      this.props.history.push('/profile/appointments');
    } else {
      const { appointment_data } = location.state;
      const parse_notes = appointment_data.notes.map((note) => {
        return {
          ...note,
          errors: null,
          isEditing: false,
          isLoading: false,
        };
      });

      let sorted_notes = sortBy(parse_notes, (item) => {
        return moment(item.createdDate, 'MM/DD/YYYY, HH:mm:ss');
      }).reverse();

      const today = moment(new Date()).format('MM/DD/YYYY');

      this.setState({
        data: {
          ...appointment_data,
          notes: sorted_notes,
        },
        rating_value: appointment_data.rating,
        isUpcomingAppointment: moment(
          appointment_data.appointment_date,
        ).isSameOrAfter(today),
      });
    }
  };

  getSingleAppointment = () => {
    this.setState({ isLoading: true, notification: {}, errors: {} });
    const { appointment_data } = this.props.location.state;
    this.props
      .userGetSingleAppointment({ _id: appointment_data._id })
      .then((res) => {
        if (res.status === 200) {
          const parse_notes = res.message.data.notes.map((note) => {
            return {
              ...note,
              errors: null,
              isEditing: false,
              isLoading: false,
            };
          });

          let sorted_notes = sortBy(parse_notes, (item) => {
            return moment(item.createdDate, 'MM/DD/YYYY, HH:mm:ss');
          }).reverse();

          this.setState({
            data: {
              ...res.message.data,
              notes: sorted_notes,
            },
            rating_value: res.message.data.rating,
          });
        }
      });
  };

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentDidMount = () => {
    window.addEventListener('screenSize', this.screenSize());
  };

  openNotesModal = () => {
    this.setState({
      isOpenNotesModal: true,
    });
  };

  closeModal = (data) => {
    this.setState({
      isOpenNotesModal: false,
      data: {
        ...this.state.data,
        notes: data.notes,
      },
    });
  };

  onChangeInputRating = (value) => {
    this.setState({
      errors: {
        ...this.state.errors,
        rating_value: null,
      },
      ratingHasChanged: true,
      rating_value: value,
    });
  };

  onChangeReview = (e) => {
    if (e.target.name === 'title') {
      this.setState({
        data: {
          ...this.state.data,
          review: {
            ...this.state.data.review,
            title: e.target.value,
          },
        },
        errors: {
          ...this.state.errors,
          review_title: null,
        },
      });
    }

    if (e.target.name === 'description') {
      this.setState({
        data: {
          ...this.state.data,
          review: {
            ...this.state.data.review,
            description: e.target.value,
          },
        },
      });
    }
  };

  closeReviewModal = () => {
    if (this.state.ratingHasChanged) {
      this.setState({
        rating_value: this.state.data.rating,
        isOpenReview: false,
      });
    } else {
      this.setState({ isOpenReview: false });
    }
  };

  isValid = () => {
    const { errors, isValid } = validateInputReview(
      this.state.data,
      this.state.rating_value,
    );
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };

  onSubmitReview = () => {
    this.setState({ isLoading_review: true, notification: {} });
    const { review, _id } = this.state.data;
    const dataToSubmit = {
      _id,
      rating: this.state.rating_value.toString(),
      review,
    };

    if (this.isValid()) {
      this.props
        .userSubmitReview(dataToSubmit)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              isLoading_review: false,
              notification: {
                text: 'Your review has been submited successfully.',
                isError: true,
              },
              isOpenReview: false,
            });
            this.getSingleAppointment();
          } else {
            this.setState({
              isLoading_review: false,
              notification: {
                text: 'We are experiencing technical difficulties at the moment, please try again later',
                isError: true,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading_review: false,
            notification: {
              text: 'Internal server error. Please try again later',
              isError: true,
            },
          });
        });
    } else {
      this.setState({
        isLoading_review: false,
        notification: {
          text: 'Please complete the form',
          isError: true,
        },
      });
    }
  };

  onCancelBooking = () => {
    this.setState({ isLoading_cancel: true, notification_cancel: {} });
    const dataToSubmit = {
      _id: this.state.data._id,
      student_id: this.state.data.student_id._id,
      tutor_data: this.state.data.tutor_id,
      book_price: this.state.data.book_price,
    };
    this.props
      .userCancelAppointment(dataToSubmit)
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            isLoading_cancel: false,
            notification_cancel: {
              text: 'Internal server error. Please try again later.',
              isError: true,
            },
          });
        } else {
          this.props.history.push('/profile/appointments');
          this.setState({
            isLoading_cancel: false,
            isCancelModalOpen: false,
            isBookingCanceled: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading_cancel: false,
          notification_cancel: {
            text: 'Failed to cancel this appointment. Please try again later.',
            isError: true,
          },
        });
      });
  };

  openReviewHandler = () => {
    const { appointment_data } = this.props.location.state;
    this.props
      .userGetSingleAppointment({ _id: appointment_data._id })
      .then((res) => {
        if (res.status === 200 && isEmpty(res.message.data.rating)) {
          this.setState({
            isOpenReview: true,
          });
        } else if (res.status === 200 && !isEmpty(res.message.data.rating)) {
          this.setState({
            data: {
              ...this.state.data,
              review: res.message.data.review.review,
              rating: res.message.data.review.rating,
            },
            rating_value: parseInt(res.message.data.review.rating),
            isOpenReview: true,
          });
        } else {
          this.setState({
            notification: {
              text: "Failed to open this appointment's review. Please try again later. ",
              isError: true,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  openRebookModalHandler = () => {
    document.body.style.overflow = 'hidden';
    this.setState({ isOpenRebookModal: true });
  };

  closeRebookModalHandler = () => {
    document.body.style.overflow = '';
    this.setState({ isOpenRebookModal: false });
  };

  render() {
    const { location } = this.props;
    const {
      data,
      isBookingCanceled,
      rating_value,
      isMobileScreen,
      isOpenNotesModal,
      isUpcomingAppointment,
      isOpenReview,
      isLoading_review,
      errors,
      default_avatar,
      isCancelModalOpen,
      isOpenRebookModal,
      isLoading_cancel,
      notification_cancel,
    } = this.state;

    if (!location.state) {
      return <Redirect to="/profile/appointments" />;
    }

    const cancel_closed_date = moment(
      data.appointment_date,
      'MM/DD/YYYY',
    ).subtract(1, 'days');
    // const cancel_closed_date = moment('06/19/2020', 'MM/DD/YYYY').subtract(1, 'days');

    const todayTime = moment(new Date()).format('MM/DD/YYYY');
    const todayMoment = moment(todayTime, 'MM/DD/YYYY');

    const cancelClosed = moment(todayMoment).isAfter(cancel_closed_date);

    return (
      <div className="col-md-8">
        <div className="card">
          <div
            className={
              isMobileScreen ? 'card-body p-20' : 'card-body px-40 py-20'
            }
            style={{ borderBottom: '1px solid #f5f5f5' }}>
            <div className="row m-auto">
              <div className="col-md-6 mx-0 d-flex">
                {/* <img
                  alt=""
                  className='h-70px'
                  style={{borderRadius: '50%', boxShadow: "0px 2px 15px #e7e7e7"}}
                  src={data.tutor_id.avatar_display ? data.tutor_id.avatar_display : default_avatar}
                /> */}
                <div
                  alt=""
                  className="h-70px w-70px"
                  style={{
                    backgroundImage: `url("${
                      data.tutor_id.avatar_display
                        ? data.tutor_id.avatar_display
                        : default_avatar
                    }")`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '50%',
                    boxShadow: '0px 2px 15px #e7e7e7',
                  }}
                />
                <div className="ml-20">
                  <p className="mb-0 font-weight-bold">
                    {data.tutor_id.display_name
                      ? data.tutor_id.display_name
                      : `${data.tutor_id.first_name} ${data.tutor_id.last_name}`}
                  </p>
                  <Rating
                    emptySymbol="fa fa-star-o fs-16 star-rating"
                    fullSymbol="fa fa-star fs-16 star-rating"
                    initialRating={data.tutor_id.total_rating}
                    readonly
                  />
                  <p className="mb-0 fs-14">
                    {data.tutor_id.appointments.length}{' '}
                    {data.tutor_id.appointments.length > 1
                      ? 'Sessions'
                      : 'Session'}
                  </p>
                </div>
              </div>
              <div
                className={
                  isMobileScreen
                    ? 'col-md-6 mx-0 text-left mt-20'
                    : 'col-md-6 mx-0 text-right'
                }>
                <h5 className="mb-2 font-weight-bold fs-18">
                  Session ID : {data.booking_id}
                </h5>
                <p className="mb-0 fs-16">{data.book_price} Flowently Credit</p>
              </div>
            </div>
          </div>
          <div
            className={
              isMobileScreen ? 'card-body p-20' : 'card-body px-40 py-20'
            }
            style={{ borderBottom: '1px solid #f5f5f5' }}>
            <div className="row m-auto">
              <div className="col-md-4 h-80px">
                <label className="mb-0 fs-14">Order date</label>
                <p className="mb-0 fs-18 font-weight-bold">
                  {moment(data.appointment_date, 'MM/DD/YYYY').format(
                    'MMMM DD, YYYY',
                  )}
                </p>
              </div>
              <div className="col-md-4 h-80px">
                <label className="mb-0 fs-14">Session type</label>
                <p className="mb-0 fs-18 font-weight-bold">
                  {data.session_type.label}
                </p>
              </div>
              <div className="col-md-4 h-80px">
                <label className="mb-0 fs-14">Session Duration</label>
                <p className="mb-0 fs-18 font-weight-bold">
                  {`${
                    Math.floor(data.session_duration / 60) > 0
                      ? `${Math.floor(data.session_duration / 60)}h`
                      : ''
                  } ${data.session_duration % 60}m`}
                </p>
              </div>
              <div className="col-md-4 h-80px">
                <label className="mb-0 fs-14">Time</label>
                <p
                  className="mb-0 fs-18 text-uppercase"
                  style={{
                    color: !isEmpty(data.time_session)
                      ? 'black'
                      : 'rgba(0,0,0,0.5)',
                    fontWeight: !isEmpty(data.time_session) ? 'bold' : 'normal',
                  }}>
                  {!isEmpty(data.time_session) ? data.time_session : 'TBD'}
                </p>
              </div>
              {/* { data.session_type.value === 'live' ?
                <div className='col-md-4 h-80px'>
                  <label className='mb-0 fs-14'>Country</label>
                  <p
                    className='mb-0 fs-18 text-capitalize'
                    style={{
                      color: !isEmpty(data.country) ? 'black' : 'rgba(0,0,0,0.5)',
                      fontWeight: !isEmpty(data.country) ? 'bold' : 'normal'
                    }}
                  >{!isEmpty(data.country) ? data.country.label : 'TBD' }</p>
                </div>
              : null} */}
              {data.session_type.value === 'live' ? (
                <div className="col-md-4 h-80px">
                  <label className="mb-0 fs-14">Meeting Point</label>
                  <p
                    className="mb-0 fs-18 text-capitalize"
                    style={{
                      color: !isEmpty(data.meeting_point)
                        ? 'black'
                        : 'rgba(0,0,0,0.5)',
                      fontWeight: !isEmpty(data.meeting_point)
                        ? 'bold'
                        : 'normal',
                    }}>
                    {!isEmpty(data.meeting_point) ? data.meeting_point : 'TBD'}
                  </p>
                </div>
              ) : null}
              {/* { data.session_type.value === 'skype' ?
                <div className='col-md-4 h-80px'>
                  <label className='mb-0 fs-14'>Skype ID</label>
                  <p className='mb-0 fs-18 font-weight-bold'>{data.tutor_id.skype_id}</p>
                </div>
              : null } */}
              {data.session_type.value === 'live' ? (
                <div className="col-md-4 h-80px">
                  <label className="mb-0 fs-14">Phone number</label>
                  <p className="mb-0 fs-18 font-weight-bold text-capitalize">
                    {!isEmpty(data.tutor_id.phone) ? data.tutor_id.phone : '-'}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={
              isMobileScreen ? 'card-body p-20' : 'card-body px-60 py-20'
            }>
            <div
              className={
                isMobileScreen
                  ? 'd-flex flex-column-reverse'
                  : 'd-flex justify-content-between'
              }>
              <button
                onClick={() => this.props.history.push('/profile/appointments')}
                className="btn d-flex align-items-center back-button button-transparent border-transparent mb-0 pb-0"
                style={{
                  justifyContent: isMobileScreen ? 'center' : 'start',
                  marginTop: isMobileScreen ? '20px' : '',
                }}>
                <i className="ion-chevron-left mr-2 fs-15"></i>
                back
              </button>
              {data.isCanceled || isBookingCanceled ? (
                <div
                  className={
                    isMobileScreen ? 'text-center' : 'd-flex align-items-center'
                  }>
                  <p className="mb-0 font-italic" style={{ color: '#d9d9d9' }}>
                    This appointment has been canceled
                  </p>
                </div>
              ) : (
                <div
                  className={
                    isMobileScreen ? 'text-center' : 'd-flex align-items-center'
                  }>
                  <div className={isMobileScreen ? 'mb-2' : ''}>
                    <button
                      onClick={this.openNotesModal}
                      className={
                        !isUpcomingAppointment && data.notes.length === 0
                          ? 'd-none'
                          : 'btn button-transparent border-transparent letter-spacing-0 text-main-pink'
                      }>
                      {data.notes.length !== 0 ? (
                        `View notes (${data.notes.length})`
                      ) : (
                        <>
                          <i className="ion-plus-round mr-1"></i>
                          Leave a note for your tutor
                        </>
                      )}
                    </button>
                    {isUpcomingAppointment && !cancelClosed ? (
                      <button
                        onClick={() =>
                          this.setState({ isCancelModalOpen: true })
                        }
                        className="btn btn-main-pink text-center">
                        Cancel booking
                      </button>
                    ) : null}
                    {isUpcomingAppointment && isEmpty(data.rating) ? null : (
                      <span
                        className={
                          !isUpcomingAppointment && data.notes.length === 0
                            ? 'd-none'
                            : 'fs-20 font-weight-light text-main-pink'
                        }>
                        |
                      </span>
                    )}
                    {!isUpcomingAppointment && !isEmpty(data.review) ? (
                      <button
                        onClick={this.openReviewHandler}
                        className="btn button-transparent border-transparent text-main-pink letter-spacing-0">
                        See review
                      </button>
                    ) : null}
                    {!isUpcomingAppointment && isEmpty(data.review) ? (
                      <button
                        onClick={this.openReviewHandler}
                        className="btn button-transparent border-transparent text-main-pink letter-spacing-0">
                        Rate this tutor
                      </button>
                    ) : null}
                  </div>
                  {isUpcomingAppointment ? null : (
                    <button
                      onClick={this.openRebookModalHandler}
                      className="btn-lg btn-main-pink">
                      Re-book tutor
                    </button>
                  )}
                </div>
              )}
            </div>
            {isOpenNotesModal ? (
              <StudentAppointmentNotesModal
                refreshData={this.refreshData}
                isOpenNotesModal={isOpenNotesModal}
                data={data}
                closeModal={this.closeModal}
                isMobileScreen={isMobileScreen}
                addNewNote={this.addNewNote}
              />
            ) : null}
            {isOpenRebookModal ? (
              <RebookTutorModal
                history={this.props.history}
                isMobileScreen={isMobileScreen}
                closeModal={this.closeRebookModalHandler}
                appointment_data={data}
              />
            ) : null}
          </div>
        </div>
        {isOpenReview ? (
          <div>
            <div
              className="modal modal-center fade show"
              id="modal-center"
              style={{ display: 'block' }}>
              <div className="modal-dialog">
                <div
                  className="modal-content"
                  style={{ width: isMobileScreen ? '100%' : '692px' }}>
                  <div className="text-right p-10">
                    <i
                      onClick={this.closeReviewModal}
                      className="ion-close-round cursor-pointer"></i>
                  </div>
                  <div className="modal-header justify-content-between pt-20 pb-10 px-30">
                    <h5 className="modal-title fw-500 fs-25">
                      {data.tutor_id.first_name} {data.tutor_id.last_name}
                    </h5>
                    <h5 className="modal-title fw-500 fs-25">
                      ID : {data.booking_id}
                    </h5>
                  </div>
                  <div
                    className={
                      isMobileScreen
                        ? 'modal-body m-0 p-30'
                        : 'modal-body m-0 py-30 px-40'
                    }>
                    <div className="text-center mt-0 mb-20">
                      <h5 className="mb-10 fs-18 font-weight-bold">
                        Your Review
                      </h5>
                      <Rating
                        emptySymbol={
                          errors.rating_value
                            ? 'fa fa-star-o fa-2x star-rating-error'
                            : 'fa fa-star-o fa-2x star-rating'
                        }
                        fullSymbol="fa fa-star fa-2x star-rating"
                        initialRating={rating_value}
                        readonly={
                          isUpcomingAppointment || !isEmpty(data.rating)
                        }
                        onChange={this.onChangeInputRating}
                      />
                      {errors.rating_value ? (
                        <p className="fs-12 mt-10 font-italic letter-spacing-0 text-danger">
                          Please give your rate
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <TextFieldGroupLogin
                        label="Title"
                        field="title"
                        value={isEmpty(data.review) ? '' : data.review.title}
                        placeholder="Type something"
                        onChange={this.onChangeReview}
                        disabled={isLoading_review || isUpcomingAppointment}
                        // error={isEmpty(errors) ? '' : errors.review_title} REMOVE REQUIRED REVIEW TITLE - REQ CLIENT
                        readOnly={!isEmpty(data.rating)}
                      />
                      <TextAreaFieldGroup
                        label="Description (optional)"
                        field="description"
                        value={
                          isEmpty(data.review) ? '' : data.review.description
                        }
                        placeholder="Type something"
                        onChange={this.onChangeReview}
                        disabled={isLoading_review || isUpcomingAppointment}
                        error={isEmpty(errors) ? '' : errors.review_description}
                        readOnly={!isEmpty(data.rating)}
                      />
                    </div>
                    {isUpcomingAppointment || !isEmpty(data.rating) ? null : (
                      <div className="d-flex justify-content-end align-items-center mt-40">
                        <button
                          onClick={() =>
                            this.setState({
                              isOpenReview: false,
                              rating_value: 0,
                            })
                          }
                          className="btn button-transparent border-transparent text-main-pink letter-spacing-0">
                          Cancel
                        </button>
                        <button
                          onClick={this.onSubmitReview}
                          className="btn btn-main-pink">
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show"></div>
          </div>
        ) : null}
        {isCancelModalOpen ? (
          <CancelBookingOverview
            data={data}
            isLoading={isLoading_cancel}
            notification={notification_cancel}
            onCloseModal={() => this.setState({ isCancelModalOpen: false })}
            onCancelBooking={this.onCancelBooking}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  userGetSingleAppointment,
  userSubmitReview,
  userCancelAppointment,
})(studentAppointmentDetails);
