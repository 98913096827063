import React, { Component } from 'react';

import { connect } from 'react-redux';
import { userGetAllPackages } from '../../../redux/actions/packagesActions';
import PackageCard from './PackageCard';

class BuyPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileScreen: false,
      isLoading: false,
      notification: {},
      packages_data: [],
    };
  }

  componentWillMount = () => {
    this.getAllPackages();
  };

  getAllPackages = () => {
    this.setState({ isLoading: true, notification: {} });
    this.props
      .userGetAllPackages()
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            isLoading: false,
            packages_data: res.message.data,
          });
        } else {
          this.setState({
            isLoading: false,
            notification: {
              text: 'Internal server error. Please try again later',
              isError: true,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          notification: {
            text: 'We are experiencing technical difficulties at the moment, please try again later',
            isError: true,
          },
        });
      });
  };

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentDidMount = () => {
    window.addEventListener('screenSize', this.screenSize());
  };

  render() {
    const { isMobileScreen, packages_data } = this.state;

    const render_package = packages_data.map((item, idx) => {
      return (
        <PackageCard
          key={item._id}
          item={item}
          idx={idx}
          history={this.props.history}
          isMobileScreen={isMobileScreen}
        />
      );
    });

    return (
      <div id="package-wrapper" className={isMobileScreen ? 'px-0' : 'px-15'}>
        <h1 className="fs-26 font-weight-bold text-center text-uppercase mb-50">
          Choose A Discount Package
        </h1>
        <div className="row m-auto">{render_package}</div>
      </div>
    );
  }
}

export default connect(null, { userGetAllPackages })(BuyPackage);
