export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_DATA = 'SET_DATA';

export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const SET_SELECTED_TUTOR = 'SET_SELECTED_TUTOR';
export const RESET_SELECTED_TUTOR = 'RESET_SELECTED_TUTOR';
export const RESET_SEARCH_DATA = 'RESET_SEARCH_DATA';
