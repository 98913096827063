import { API } from '../../utils/api';

export function userGetSingleProfile(data) {
  return (dispatch) => {
    return API.post('/student/single', data).then((res) => {
      return res.data;
    });
  };
}

export function userUpdateProfileInfo(data) {
  return (dispatch) => {
    return API.post('/student/edit', data).then((res) => {
      return res.data;
    });
  };
}

export function userChangePassword(data) {
  return (dispatch) => {
    return API.post('/student/change-password', data).then((res) => {
      return res.data;
    });
  };
}

export function userUploadAvatar({ file, _id }) {
  let data = new FormData();
  data.append('file', file);
  // for (var pair of data.entries()) {
  // 	console.log(pair[0]+ ', ' + pair[1]);
  // }
  return (dispatch) => {
    return API.post(`/student/${_id}/upload-avatar`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
