import React from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

const Notification = ({ notification }) => {
  return (
    <div>
      {!isEmpty(notification) ? (
        <div
          className={classnames(
            'alert fs-14',
            { 'alert-danger fs-14': notification.isError },
            { 'alert-success fs-14': !notification.isError },
          )}>
          {notification.text}
          {notification && notification.path ? (
            <span>
              <Link
                to={{
                  pathname: `${notification.path}`,
                  state: {
                    email: notification.email,
                    type: notification.type,
                  },
                }}
                onClick={() => {
                  window.location.href = notification.path;
                }}
                className="ml-1"
                style={{ textDecoration: 'underline' }}>
                here
              </Link>
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Notification;
