import React, { Component } from 'react';
import { isEmpty, isString, rest } from 'lodash';
import axios from 'axios';
import { DOMAIN } from '../../../utils/api';

import {
	session_time_options,
	session_type_options,
	sort_by_options,
	topics_options,
} from '../../common/SelectOptionsData';
import LoadingGift from '../../common/LoadingGift';
import Pagination from '../../common/Pagination';
import SearchFieldGroup from '../../common/SearchFieldGroup';
import SelectFieldBookPage from '../../common/SelectFieldBookPage';
import SearchTutorResultCard from './SearchTutorResultCard';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
	resetSearchData,
	resetSelectedTutor,
	setSearchFilter,
	setSearchResult,
	userGetAllTutors,
} from '../../../redux/actions/bookActions';
import { userGetAllCountries, userGetAllLanguages } from '../../../redux/actions/commonActions';
import { userGetSingleProfile } from '../../../redux/actions/studentActions';

class SearchTutor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobileScreen: false,
			isLoading_result: true,
			notification: {},
			// sort_by: { value: "rating", label: "Rating" },
			sort_by: null,
			country_options: [],
			city_options: [],
			language_options: [],
			search_result: [],
			// FILTER //
			isFilterCollapse: true,
			filtered: [],
			search_name: '',
			topics_filter: null,
			session_filter: null,
			country_filter: null,
			city_filter: null,
			session_time_filter: null,
			learn_language_filter: null,
			bridge_language_filter: null,
			// PAGINATION //
			currentPage: 1,
			resultPerPage: 10,
			totalTutors: 0,
			totalPage: 1,
			// USP
			uspData: {},
		};
		this.scrollRef = React.createRef();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.currentPage !== this.state.currentPage) {
			this.setState({
				currentPage: nextState.currentPage,
			});
		}
		return true;
	}

	componentWillMount = async () => {
		let recent_filter = [];

		let currentUserData = {
			learn_language_filter: null,
			session_filter: null,
			country_filter: null,
			city_filter: null,
		};

		const getLangOptions = await this.getLanguagesOptions(true);

		try {
			const currentUser = JSON.parse(localStorage.user);
			await this.props
				.userGetSingleProfile({ email: currentUser.email })
				.then((res) => {
					if (res.status === 200) {
						const { learn_language, session_type, living_in_country, living_in_city } = res.message.data;

						// console.log("res.message.data", res.message.data);

						currentUserData = {
							learn_language_filter: learn_language[0]?.language,
							session_filter: session_type[0],
							country_filter: living_in_country,
							city_filter: living_in_city,
						};

						this.setState({
							learn_language_filter: learn_language[0]?.language,
							session_filter: session_type[0],
							country_filter: living_in_country,
							city_filter: living_in_city,
						});
					}
				})
				.catch((err) => {
					// console.log(err);
				});
		} catch (error) {
			// console.log("Aggregate filter data", error);
		}

		const { filter, params } = this.props;
		const { currentPage, resultPerPage, sort_by } = this.state;

		if (!isEmpty(filter)) {
			// console.log("currentUserData", currentUserData);

			if (filter.search_name)
				recent_filter.push({
					id: 'search_name',
					value: filter.search_name,
				});
			if (filter.topics_filter)
				recent_filter.push({
					id: 'topics',
					value: filter.topics_filter.value,
				});
			if (filter.session_filter)
				recent_filter.push({
					id: 'session_type',
					value: filter.session_filter?.value,
				});
			if (filter.country_filter)
				recent_filter.push({
					id: 'country',
					value: filter.country_filter?.value,
				});
			if (filter.city_filter)
				recent_filter.push({
					id: 'city',
					value: filter.city_filter?.value,
				});
			if (filter.session_time_filter)
				recent_filter.push({
					id: 'time_available',
					value: filter.session_time_filter.value,
				});
			if (filter.learn_language_filter)
				recent_filter.push({
					id: 'teach_language',
					value: filter.learn_language_filter?._id,
				});
			if (filter.bridge_language_filter)
				recent_filter.push({
					id: 'bridge_language',
					value: filter.bridge_language_filter._id,
				});

			this.setState({
				search_name: filter.search_name,
				topics_filter: filter.topics_filter,
				// session_filter: filter.session_filter || currentUserData?.session_filter,
				// country_filter: filter.country_filter || currentUserData?.country_filter,
				// city_filter: filter.city_filter || currentUserData?.city_filter,
				session_filter: filter.session_filter,
				country_filter: filter.country_filter,
				city_filter: filter.city_filter,
				session_time_filter: filter.session_time_filter,
				// learn_language_filter:
				// 	filter.learn_language_filter || currentUserData?.learn_language_filter,
				learn_language_filter: filter.learn_language_filter,
				bridge_language_filter: filter.bridge_language_filter,
				filtered: recent_filter,
			});
		} else if (currentUserData?.learn_language_filter) {
			if (currentUserData?.session_filter) {
				recent_filter.push({
					id: 'session_type',
					value: currentUserData?.session_filter?.value,
				});
			}

			if (currentUserData?.country_filter) {
				recent_filter.push({
					id: 'country',
					value: currentUserData?.country_filter?.value,
				});
			}

			if (currentUserData?.city_filter) {
				recent_filter.push({
					id: 'city',
					value: currentUserData?.city_filter?.value,
				});
			}

			if (currentUserData?.learn_language_filter) {
				recent_filter.push({
					id: 'teach_language',
					value: getLangOptions.find((item) => item.value === currentUserData?.learn_language_filter.value)
						?._id,
				});
			}
		}

		if (params?.name) {
			const lang_by_param = params?.name.replace('learn-', '');
			const indexLangOpt = recent_filter.findIndex((option) => option.id === 'teach_language');
			const currentLangParam = getLangOptions.find((option) => option.value === lang_by_param);

			if (indexLangOpt > 0) {
				recent_filter[indexLangOpt].value = currentLangParam._id;
			} else {
				recent_filter.push({
					id: 'teach_language',
					value: currentLangParam._id,
				});
			}

			const updateLearnLangFilter = this.state.filtered.filter((item) => {
				if (item.id === 'teach_language') {
					return false;
				}

				return true;
			});

			this.setState({
				currentPage: 1,
				learn_language_filter: lang_by_param,
				filtered: [...updateLearnLangFilter, { id: 'teach_language', value: currentLangParam._id }],
			});
		}

		// console.log("recent_filter", recent_filter);

		const findSessionOnline = recent_filter.find((item) => {
			return item.id === 'session_type' && item.value === 'skype';
		});

		if (findSessionOnline) {
			recent_filter = recent_filter.filter((item) => {
				if (item.id === 'country' || item.id === 'city') {
					return false;
				}

				return true;
			});
		}

		this.getAllTutors({
			pagination: {
				pageSize: resultPerPage,
				page: currentPage,
			},
			sort_by,
			filtered: recent_filter,
		});

		this.setState({
			filtered: recent_filter,
		});

		this.props.resetSelectedTutor();

		this.getUSP();
	};

	getLanguagesOptions = async (isReturn) => {
		let langOpts = [];

		await this.props
			.userGetAllLanguages()
			.then((res) => {
				if (res.status === 200) {
					// SORT LANGUAGE OPTIONS
					const sorted_language_options = res.message.data.sort((a, b) =>
						a.value > b.value ? 1 : b.value > a.value ? -1 : 0
					);

					this.setState({
						language_options: sorted_language_options,
						// learn_language_filter: getCurrentLearnLang?.value,
					});

					// const currentLearnLang = this.state.learn_language_filter;
					// const getCurrentLearnLang = sorted_language_options.find(
					// 	(option) => {
					// 		if (isString(currentLearnLang)) {
					// 			return option.value === currentLearnLang;
					// 		}

					// 		return option.value === currentLearnLang?.value;
					// 	}
					// );

					// this.setState({
					// 	language_options: sorted_language_options,
					// 	learn_language_filter: getCurrentLearnLang?.value,
					// });

					// this.getAllTutors({
					// 	pagination: {
					// 		pageSize: this.state.resultPerPage,
					// 		page: 1,
					// 	},
					// 	sort_by: this.state.sort_by,
					// 	filtered: [
					// 		...this.state.filtered,
					// 		{
					// 			id: 'teach_language',
					// 			value: getCurrentLearnLang?._id,
					// 		},
					// 	],
					// });

					langOpts = sorted_language_options;
				}
			})
			.catch((err) => {
				// console.log(err);
			});

		if (isReturn) {
			return langOpts;
		}
	};

	getUSP = async () => {
		const { params } = this.props;

		const uspData = {};

		if (params?.name) {
			const getLangOptions = await this.getLanguagesOptions(true);
			const lang_by_param = params?.name.replace('learn-', '');

			const currentLangParam = getLangOptions.find((option) => option.value === lang_by_param);

			let apiUrl = `${DOMAIN}/wp-json/flowently/v1/booking/seo/${currentLangParam?.connect_id || '0'}`;

			try {
				const res = await axios.get(apiUrl);

				if (res.status === 200) {
					let result = res?.data?.data?.response;
					let dataSeoLang = '';

					if (typeof result === 'string') {
						if (result.charAt(0) !== '{') {
							result = result.substring(result.indexOf('{"') + 1);
							result = `{${result}`;
						}

						dataSeoLang = JSON.parse(result);
					} else {
						dataSeoLang = result;
					}

					uspData['title'] = dataSeoLang?.title_seo;
					uspData['description'] = dataSeoLang?.description_seo;
				} else {
					uspData['title'] = `Tutor ${currentLangParam?.label}`;
					uspData['description'] = `Tutor Language ${currentLangParam?.label}`;
				}
			} catch {
				uspData['title'] = `Tutor ${currentLangParam?.label}`;
				uspData['description'] = `Tutor Language ${currentLangParam?.label}`;
			}
		} else {
			let apiUrl = `${DOMAIN}/wp-json/wp/v2/layout/42783`;

			const res = await axios.get(apiUrl);
			if (res.status === 200) {
				const { id, acf } = res.data;
				uspData['id'] = id;
				uspData['title'] = acf.title;
				uspData['description'] = acf.description;
			}
		}

		this.setState({ uspData });
	};

	getAllTutors = (dataToSubmit) => {
		const recent_result = this.state.search_result;
		this.setState({
			isLoading_result: true,
			notification: {},
			search_result: [],
		});
		this.props
			.userGetAllTutors(dataToSubmit)
			.then((res) => {
				if (res.status === 200) {
					const search_result = res.message.data;

					this.setState({
						isLoading_result: false,
						search_result: search_result,
						totalPage: res.message.totalPage,
						totalTutors: res.message.totalTutors,
						country_options: res.message.country_filter_options,
						city_options: res.message.city_filter_options,
					});
					this.props.setSearchResult(res.message.data);
				} else {
					this.setState({
						search_result: recent_result,
						isLoading_result: false,
						notification: {
							text: 'Internal server error. Please try again later',
							isError: true,
						},
					});
				}
			})
			.catch((err) => {
				// console.log(err);
				this.setState({
					search_result: recent_result,
					isLoading_result: false,
					notification: {
						text: 'We are experiencing technical difficulties at the moment, please try again later',
						isError: true,
					},
				});
			});
	};

	screenSize = () => {
		this.setState({ isMobileScreen: window.innerWidth <= 900 });
	};

	componentDidMount = () => {
		window.addEventListener('screenSize', this.screenSize());
	};

	componentWillUnmount = () => {
		const {
			search_name,
			learn_language_filter,
			bridge_language_filter,
			topics_filter,
			session_filter,
			session_time_filter,
			country_filter,
			city_filter,
		} = this.state;

		this.props.setSearchFilter({
			search_name,
			learn_language_filter,
			bridge_language_filter,
			topics_filter,
			session_filter,
			session_time_filter,
			country_filter,
			city_filter,
		});
	};

	onChangeSearchInput = (e) => {
		const { params } = this.props;

		if (e.target.name === 'session_filter') {
			const updateSessionState = this.state.filtered.filter((item) => {
				if (item.id === 'session_type') {
					return false;
				}

				return true;
			});

			const updateSessionFilter = this.state.filtered.filter((item) => {
				if (
					item.id === 'session_type' ||
					(e.target.value.value === 'skype' && (item.id === 'country' || item.id === 'city'))
				) {
					return false;
				}

				return true;
			});

			// console.log("updateSessionFilter", updateSessionFilter);

			this.setState({
				session_filter: e.target.value.value,
				currentPage: 1,
				filtered: [...updateSessionState, { id: 'session_type', value: e.target.value.value }],
			});
			this.getAllTutors({
				pagination: {
					pageSize: this.state.resultPerPage,
					page: 1,
				},
				sort_by: this.state.sort_by,
				filtered: [...updateSessionFilter, { id: 'session_type', value: e.target.value.value }],
			});
		}

		if (e.target.name === 'learn_language_filter') {
			const lang_by_param = params?.name.replace('learn-', '');

			if (lang_by_param !== e.target.value) {
				this.props.history.push(`/book-language-course/learn-${e.target.value.value}`);

				const updateLearnLangFilter = this.state.filtered.filter((item) => {
					if (item.id === 'teach_language') {
						return false;
					}

					return true;
				});

				this.setState({
					currentPage: 1,
					learn_language_filter: e.target.value,
					filtered: [...updateLearnLangFilter, { id: 'teach_language', value: e.target.value._id }],
				});
			}
		}

		if (e.target.name === 'bridge_language_filter') {
			const updateBridgeLangFilter = this.state.filtered.filter((item) => {
				if (item.id === 'bridge_language') {
					return false;
				}

				return true;
			});

			this.setState({
				currentPage: 1,
				bridge_language_filter: e.target.value,
				filtered: [...updateBridgeLangFilter, { id: 'bridge_language', value: e.target.value._id }],
			});
			this.getAllTutors({
				pagination: {
					pageSize: this.state.resultPerPage,
					page: 1,
				},
				sort_by: this.state.sort_by,
				filtered: [...updateBridgeLangFilter, { id: 'bridge_language', value: e.target.value._id }],
			});
		}

		if (e.target.name === 'country_filter') {
			const updateCountryFilter = this.state.filtered.filter((item) => {
				if (item.id === 'country') {
					return false;
				}

				return true;
			});

			this.setState({
				currentPage: 1,
				country_filter: e.target.value,
				city_filter: '',
				filtered: [
					...updateCountryFilter,
					{ id: 'country', value: e.target.value.value },
					{ id: 'city', value: '' },
				],
			});
			this.getAllTutors({
				pagination: {
					pageSize: this.state.resultPerPage,
					page: 1,
				},
				sort_by: this.state.sort_by,
				filtered: [...updateCountryFilter, { id: 'country', value: e.target.value.value }],
			});
		}

		if (e.target.name === 'city_filter') {
			const updateCityFilter = this.state.filtered.filter((item) => {
				if (item.id === 'city') {
					return false;
				}

				return true;
			});

			this.setState({
				currentPage: 1,
				city_filter: e.target.value,
				filtered: [...updateCityFilter, { id: 'city', value: e.target.value.value }],
			});
			this.getAllTutors({
				pagination: {
					pageSize: this.state.resultPerPage,
					page: 1,
				},
				sort_by: this.state.sort_by,
				filtered: [...updateCityFilter, { id: 'city', value: e.target.value.value }],
			});
		}

		if (e.target.name === 'session_time_filter') {
			const updateTimeFilter = this.state.filtered.filter((item) => {
				if (item.id === 'time_available') {
					return false;
				}

				return true;
			});

			this.setState({
				currentPage: 1,
				session_time_filter: e.target.value,
				filtered: [...updateTimeFilter, { id: 'time_available', value: e.target.value.value }],
			});
			this.getAllTutors({
				pagination: {
					pageSize: this.state.resultPerPage,
					page: 1,
				},
				sort_by: this.state.sort_by,
				filtered: [...updateTimeFilter, { id: 'time_available', value: e.target.value.value }],
			});
		}

		if (e.target.name === 'topics_filter') {
			const updateTopicsFilter = this.state.filtered.filter((item) => {
				if (item.id === 'topics') {
					return false;
				}

				return true;
			});

			this.setState({
				currentPage: 1,
				topics_filter: e.target.value,
				filtered: [...updateTopicsFilter, { id: 'topics', value: e.target.value.value }],
			});
			this.getAllTutors({
				pagination: {
					pageSize: this.state.resultPerPage,
					page: 1,
				},
				sort_by: this.state.sort_by,
				filtered: [...updateTopicsFilter, { id: 'topics', value: e.target.value.value }],
			});
		}

		if (e.target.name === 'search_name') {
			const updateSearchFilter = this.state.filtered.filter((item) => {
				if (item.id === 'search_name') {
					return false;
				}

				return true;
			});

			// console.log('updateSearchFilter', updateSearchFilter);

			this.setState({
				currentPage: 1,
				search_name: e.target.value,
				filtered: [...updateSearchFilter, { id: 'search_name', value: e.target.value }],
			});
			this.getAllTutors({
				pagination: {
					pageSize: this.state.resultPerPage,
					page: 1,
				},
				sort_by: this.state.sort_by,
				filtered: [...updateSearchFilter, { id: 'search_name', value: e.target.value }],
			});
		}

		this.setState({ [e.target.name]: e.target.value });
	};

	loadOptionsCountry = (inputValue, callback) => {
		this.props
			.userGetAllCountries({ query: inputValue })
			.then((response) => {
				callback(response.message.data);
			})
			.catch((error) => {
				this.setState({ isLoading: false });
			});
	};

	setPaginate = (pageNumber) => {
		const { resultPerPage, sort_by, filtered, isMobileScreen } = this.state;
		this.setState({ currentPage: pageNumber });
		this.getAllTutors({
			pagination: {
				pageSize: resultPerPage,
				page: pageNumber,
			},
			sort_by,
			filtered,
		});

		if (isMobileScreen) {
			this.scrollRef.current.scrollIntoView();
		} else {
			window.scrollTo(0, 0);
		}
	};

	onClickPrevNext = (pageNumber) => {
		const { resultPerPage, sort_by, filtered } = this.state;
		this.setState({ currentPage: pageNumber });
		this.getAllTutors({
			pagination: {
				pageSize: resultPerPage,
				page: pageNumber,
			},
			sort_by,
			filtered,
		});
	};

	onSubmitFilter = () => {
		window.scrollTo(0, 0);
	};

	resetFilter = () => {
		this.setState({
			search_name: '',
			topics_filter: null,
			session_filter: null,
			country_filter: null,
			city_filter: null,
			session_time_filter: null,
			learn_language_filter: null,
			bridge_language_filter: null,
			filtered: [],
			sort_by: null,
		});
		this.props.resetSearchData();
		this.getAllTutors({
			pagination: {
				pageSize: 5,
				page: 1,
			},
			// sort_by: { value: 'rating', label: 'Rating' },
			sort_by: null,
			filtered: [],
		});
	};

	sortHandler = (val) => {
		this.setState({ sort_by: val });
		this.getAllTutors({
			pagination: {
				pageSize: this.state.resultPerPage,
				page: 1,
			},
			sort_by: val,
			filtered: this.state.filtered,
		});
	};

	htmlDecode = (input) => {
		var e = document.createElement('div');
		e.innerHTML = input;
		return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
	};

	render() {
		const {
			totalTutors,
			totalPage,
			isMobileScreen,
			sort_by,
			search_name,
			topics_filter,
			session_filter,
			session_time_filter,
			learn_language_filter,
			bridge_language_filter,
			country_filter,
			city_filter,
			country_options,
			city_options,
			language_options,
			search_result,
			currentPage,
			resultPerPage,
			isLoading_result,
			isFilterCollapse,
			uspData,
		} = this.state;

		const { history, match } = this.props;

		const city_options_by_country = city_options.filter((item) => item.country === country_filter?.label);

		const render_filter_result = search_result.map((data, idx) => {
			return (
				<SearchTutorResultCard
					key={`tutor-${idx + 1}`}
					data={data}
					history={history}
					match={match}
				/>
			);
		});

		const showExtraResetButton =
			search_name ||
			topics_filter ||
			session_filter ||
			session_time_filter ||
			learn_language_filter ||
			bridge_language_filter ||
			country_filter ||
			city_filter;

		return (
			<>
				<Helmet>
					<title>{uspData.title}</title>
					<meta
						name='description'
						content={uspData.description}
					/>
					<meta
						name='robots'
						content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
					/>
				</Helmet>
				<div id='book-tutor'>
					<div className='text-center mb-4 pb-4 px-15'>
						<h1 className='font-weight-bold mb-1'>{uspData.title}</h1>
						<div
							className='mx-auto'
							dangerouslySetInnerHTML={{
								__html: uspData.description,
							}}
						/>
						<figure className='w-100 mt-4'>
							<a href='https://flowently.com/#tutor-maps'>
								<img
									src='/assets/img/tutor-maps-flowently.jpg'
									loading='lazy'
									className='w-100'
								/>
							</a>
						</figure>
					</div>
					<div
						className='d-flex justify-content-between align-items-center px-15'
						style={{
							flexDirection: isMobileScreen ? 'column' : 'row',
						}}
					>
						<p className='mb-0'>
							{totalTutors} {totalTutors > 1 ? 'Tutors' : 'Tutor'} Found
						</p>
						<div className='d-flex align-items-center mb-20'>
							<p className='mb-0 mr-2'>Sort by :</p>
							<SelectFieldBookPage
								name='sort_by'
								value={sort_by}
								options={sort_by_options}
								onChange={this.sortHandler}
								isSearchable={false}
								isClearable={true}
								minWidth='150px'
							/>
						</div>
					</div>
					<div
						id='filter-section'
						className='row m-auto'
					>
						<div className='col-md-3'>
							<div className='card'>
								<div className={isMobileScreen ? 'card-body accordion-wrapper p-20' : 'card-body p-40'}>
									<h5 className='font-weight-bold fs-16 mb-0'>Filter your Tutor</h5>
									<p
										className='text-gray fs-14 mb-0 mt-1'
										style={{
											maxWidth: '90%',
											lineHeight: 1.4,
										}}
									>
										Select the filters you want to use to find a tutor
									</p>
									<div className='pt-10'>
										<SearchFieldGroup
											name='search_name'
											value={search_name}
											onChange={this.onChangeSearchInput}
											addedFormClass='bg-light-gray search-tutor-name fs-14'
											placeholder='Search by tutor name'
										/>
										{/* {isMobileScreen &&
										showExtraResetButton && */}
										{this.state.filtered.length > 0 || this.state.sort_by ? (
											<button
												onClick={this.resetFilter}
												className='btn btn-main-pink letter-spacing-0 w-100 mt-4'
											>
												Reset
											</button>
										) : null}
										<div
											style={{
												borderColor: 'rgba(0,0,0,0.08)!important',
											}}
											className='border-bottom px-10 pt-20 pb-10'
										>
											<p className='mb-10 fs-12'>Language you want to learn</p>
											<SelectFieldBookPage
												name='learn_language_filter'
												value={language_options.filter((option) => {
													if (isString(learn_language_filter)) {
														return option.value === learn_language_filter;
													}

													return option.value === learn_language_filter?.value;
												})}
												options={language_options}
												onChange={(val) =>
													this.onChangeSearchInput({
														target: {
															name: 'learn_language_filter',
															value: val,
														},
													})
												}
												isSearchable={false}
											/>
										</div>
										<div
											style={{
												borderColor: 'rgba(0,0,0,0.08)!important',
											}}
											className='border-bottom px-10 pt-20 pb-10'
										>
											<p className='mb-10 fs-12'>Bridge language</p>
											<SelectFieldBookPage
												name='bridge_language_filter'
												value={bridge_language_filter}
												options={language_options}
												onChange={(val) =>
													this.onChangeSearchInput({
														target: {
															name: 'bridge_language_filter',
															value: val,
														},
													})
												}
												isSearchable={false}
											/>
										</div>
										{isMobileScreen && !session_filter && session_filter?.value !== 'skype' && (
											<div
												ref={this.scrollRef}
												style={{
													borderColor: 'rgba(0,0,0,0.08)!important',
												}}
												className='border-bottom px-10 pt-20 pb-10 mb-30'
											>
												<p className='mb-10 fs-12 '>City</p>
												<SelectFieldBookPage
													name='city_filter'
													value={city_filter}
													options={city_options_by_country}
													onChange={(val) =>
														this.onChangeSearchInput({
															target: {
																name: 'city_filter',
																value: val,
															},
														})
													}
													isSearchable={true}
												/>
											</div>
										)}
									</div>
									<div
										id={isMobileScreen ? 'collapse-1-1' : ''}
										className={isMobileScreen ? 'collapse' : ''}
									>
										<div
											style={{
												borderColor: 'rgba(0,0,0,0.08)!important',
											}}
											className='border-bottom px-10 pt-20 pb-10'
										>
											<p className='mb-10 fs-12'>Session</p>
											<SelectFieldBookPage
												name='session_filter'
												value={session_filter}
												options={session_type_options}
												onChange={(val) =>
													this.onChangeSearchInput({
														target: {
															name: 'session_filter',
															value: val,
														},
													})
												}
												isSearchable={false}
											/>
										</div>
										{session_filter && session_filter.value === 'skype' ? null : (
											<div
												style={{
													borderColor: 'rgba(0,0,0,0.08)!important',
												}}
												className='border-bottom px-10 pt-20 pb-10'
											>
												<p className='mb-10 fs-12'>Country</p>
												<SelectFieldBookPage
													name='country_filter'
													value={country_filter}
													options={country_options}
													onChange={(val) =>
														this.onChangeSearchInput({
															target: {
																name: 'country_filter',
																value: val,
															},
														})
													}
													isSearchable={false}
												/>
											</div>
										)}
										{session_filter && session_filter.value === 'skype' ? null : (
											<div
												ref={this.scrollRef}
												style={{
													borderColor: 'rgba(0,0,0,0.08)!important',
												}}
												className='border-bottom px-10 pt-20 pb-10'
											>
												<p className='mb-10 fs-12'>City</p>
												<SelectFieldBookPage
													name='city_filter'
													value={city_filter}
													options={city_options_by_country}
													onChange={(val) =>
														this.onChangeSearchInput({
															target: {
																name: 'city_filter',
																value: val,
															},
														})
													}
													isSearchable={true}
												/>
											</div>
										)}
										<div
											style={{
												borderColor: 'rgba(0,0,0,0.08)!important',
											}}
											className='border-bottom px-10 pt-20 pb-10'
										>
											<p className='mb-10 fs-12'>Time of Session</p>
											<SelectFieldBookPage
												name='session_time_filter'
												value={session_time_filter}
												options={session_time_options}
												onChange={(val) =>
													this.onChangeSearchInput({
														target: {
															name: 'session_time_filter',
															value: val,
														},
													})
												}
												isSearchable={false}
											/>
										</div>
										<div
											style={{
												borderColor: 'rgba(0,0,0,0.08)!important',
											}}
											className='border-bottom px-10 pt-20 pb-10'
										>
											<p className='mb-10 fs-12'>Topic</p>
											<SelectFieldBookPage
												name='topics_filter'
												value={topics_filter}
												options={topics_options}
												onChange={(val) =>
													this.onChangeSearchInput({
														target: {
															name: 'topics_filter',
															value: val,
														},
													})
												}
												isSearchable={false}
											/>
										</div>
										<div className='d-flex flex-wrap justify-content-center align-items-center mt-20'>
											{this.state.filtered.length > 0 || this.state.sort_by ? (
												<button
													onClick={this.resetFilter}
													className='btn button-transparent letter-spacing-0 w-100'
												>
													Reset
												</button>
											) : null}
											<button
												onClick={this.onSubmitFilter}
												className='btn btn-main-pink w-100 mt-2'
											>
												Apply
											</button>
										</div>
									</div>
									{isMobileScreen ? (
										<div className={isFilterCollapse ? 'mt-10 text-center' : 'mt-30 text-center'}>
											<a
												onClick={() =>
													this.setState({
														isFilterCollapse: !this.state.isFilterCollapse,
													})
												}
												data-toggle='collapse'
												data-parent='#accordion-1'
												aria-expanded='true'
												className='text-black d-flex align-items-center justify-content-center collapsed fs-14'
												href='#collapse-1-1'
											>
												{isFilterCollapse ? (
													<span className='mr-2'>More filters</span>
												) : (
													<span className='mr-2'>Less filters</span>
												)}
												<i className='mr-2 fa fa-chevron-up fs-13'></i>
											</a>
										</div>
									) : null}
								</div>
							</div>
						</div>

						{/* SEARCH TUTOR RESULT */}
						{isLoading_result ? (
							<div className='col-md-9 d-flex justify-content-center sticky-loading'>
								<LoadingGift />
							</div>
						) : (
							<div className='col-md-9'>
								{search_result.length > 0 ? (
									render_filter_result
								) : (
									<div
										style={{ height: '50vh' }}
										className='d-flex justify-content-center align-items-center flex-column'
									>
										<img
											src='/assets/img/icon/search.svg'
											className='h-100px'
											alt='not found'
										/>
										<div className='text-center'>
											<p className='fs-18 mb-0 lh-3'>No result match with your search filter.</p>
											<p className='fs-18 mb-0 lh-0'>Please try with another filter.</p>
										</div>
									</div>
								)}
								{totalPage && totalTutors ? (
									<Pagination
										totalPages={totalPage}
										activePage={currentPage}
										pageLimit={resultPerPage}
										totalRecords={totalTutors}
										paginate={this.setPaginate}
										onClickPrevious={() => this.onClickPrevNext(currentPage - 1)}
										onClickNext={() => this.onClickPrevNext(currentPage + 1)}
									/>
								) : null}
								<div
									className='mt-60 text-center mx-auto'
									style={{ width: '80%' }}
								>
									<h2 className='font-weight-bold'>Can we help you find a tutor ?</h2>
									<p className=''>
										Do you find it challenging to find your tutor amongst 200 candidates? Do you
										perhaps have a special language subject you want to focus on? Don’t hesitate to
										contact us, we will help you find the perfect match.
									</p>
									<a
										href='https://flowently.com/help-with-finding-a-tutor/'
										className='btn btn-main-pink w-200px py-10 mt-3'
									>
										Get in touch
									</a>
								</div>
							</div>
						)}
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		filter: state.booking.searchFilter,
	};
};

export default connect(mapStateToProps, {
	userGetAllCountries,
	userGetAllLanguages,
	userGetAllTutors,
	setSearchResult,
	setSearchFilter,
	resetSearchData,
	resetSelectedTutor,
	userGetSingleProfile,
})(SearchTutor);
