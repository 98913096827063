import isEmpty from 'lodash/isEmpty';
import Validator from 'validator';

export function validatePersonalInfo(data) {
  let errors = {};

  if (isEmpty(data.first_name)) {
    errors.first_name = 'First name is required';
  }
  if (isEmpty(data.last_name)) {
    errors.last_name = 'Last name is required';
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

export function validateDisplayInfo(data) {
  let errors = {};

  if (isEmpty(data.display_name)) {
    errors.display_name = 'Display name is required';
  }
  if (isEmpty(data.introduction)) {
    errors.introduction = 'Introduction is required';
  }

  const error_country = data.country.map((country, idx) => {
    if (isEmpty(country)) {
      return 'Please select country';
    } else {
      return null;
    }
  });

  if (error_country.every((element) => element === null)) {
    console.log('Country is valid');
  } else {
    errors.country = error_country;
  }

  const error_city = data.city.map((city, idx) => {
    if (isEmpty(city)) {
      return 'Please select city';
    } else {
      return null;
    }
  });

  if (error_city.every((element) => element === null)) {
    console.log('City is valid');
  } else {
    errors.city = error_city;
  }

  if (isEmpty(data.teach_language)) {
    errors.teach_language = 'Please select at least 1 language';
  }
  if (isEmpty(data.bridge_language)) {
    errors.bridge_language = 'Please select at least 1 language';
  }
  if (isEmpty(data.session_type)) {
    errors.session_type = 'Please select at least 1 session type';
  }
  if (isEmpty(data.topics)) {
    errors.topics = 'Please select at least 1 topics';
  }
  if (isEmpty(data.day_available)) {
    errors.day_available = 'Please check at least 1 option';
  }
  if (isEmpty(data.time_available)) {
    errors.time_available = 'Please check at least 1 option';
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

export function validateInputLoginDetails(data) {
  let errors = {};

  if (isEmpty(data.current_password)) {
    errors.current_password = 'Please input your current password';
  }
  if (isEmpty(data.new_password)) {
    errors.new_password = 'Please input your new password';
  }
  if (isEmpty(data.confirm_password)) {
    errors.confirm_password = 'Please confirm your new password';
  } else {
    if (data.new_password !== data.confirm_password) {
      errors.confirm_password = 'Password does not match with new password';
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

export function validateAddressDetails(data) {
  let errors = {};

  if (isEmpty(data.phone)) {
    errors.phone = 'Please input your phone number';
  }
  if (isNaN(data.phone)) {
    errors.phone = 'Phone number must be a number';
  }
  if (isEmpty(data.country)) {
    errors.country = 'Please select country you lives in';
  } else {
    if (isEmpty(data.city)) {
      errors.city = 'Please select country you lives in';
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

export function validateEmergencyContact(data) {
  let errors = {};

  if (isEmpty(data.email_ec)) {
    errors.email_ec = 'Email is required';
  } else {
    if (!Validator.isEmail(data.email_ec)) {
      errors.email_ec = 'Please insert a valid email';
    }
  }

  if (isEmpty(data.full_name_ec)) {
    errors.full_name_ec = 'Fullname is required';
  }

  if (isEmpty(data.street_name_ec)) {
    errors.street_name_ec = 'Street name is required';
  }

  if (isEmpty(data.house_number_ec)) {
    errors.house_number_ec = 'House number is required';
  }

  if (isEmpty(data.postcode_ec)) {
    errors.postcode_ec = 'Postal code is required';
  }

  if (isEmpty(data.phone_ec)) {
    errors.phone_ec = 'Phone number is required';
  } else {
    if (isNaN(data.phone_ec)) {
      errors.phone_ec = 'Phone number must be a number';
    }
  }

  if (isEmpty(data.country_ec)) {
    errors.country_ec = 'Please select country you lives in';
  } else {
    if (isEmpty(data.city_ec)) {
      errors.city_ec = 'Please select country you lives in';
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}
