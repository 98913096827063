import React from 'react';
import ResetToken from './resetToken';

function ResetTokenPage(props) {
  const { history, match } = props;

  return (
    <div
      className="my-50 center-vh pace-done mx-auto"
      style={{ maxWidth: 1528 }}>
      <ResetToken history={history} type={match.params.type} match={match} />
    </div>
  );
}

export default ResetTokenPage;
