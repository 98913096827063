import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropsTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MDSpinner from 'react-md-spinner';
import classnames from 'classnames';
import Select from 'react-select';

import { validateInputTutor } from '../common/validations/signup';
import TextFieldGroupLogin from '../common/TextFieldGroupLogin';
import CustomCheckbox from '../common/CustomCheckbox';
import Notification from '../common/Notification';

import { register } from '../../redux/effects/auth';


const mapActionToProps = (dispatch) =>
  bindActionCreators(
    {
      register,
    },
    dispatch,
  );

function SignupFormTutor(props) {
  const { register } = props;
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    first_name: '',
    cv: '',
    motivation: '',
    is_agree: '',
    phone: '',
  });
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cv, setCV] = useState(null);
  const [motivation, setMotivation] = useState(null);
  const [isAgree, setIsAgree] = useState(false);
  const [notification, setNotification] = useState({
    text: '',
    isError: false,
  });
  const [topHover, setTopHover] = useState(false);
  const [bottomHover, setBottomHover] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();
    setNotification({});
    const { isValid, errors } = validateInputTutor({
      cv,
      email,
      password,
      phone,
      motivation,
      first_name: firstName,
      last_name: lastName,
      is_agree: isAgree,
      mode: 'tutor',
    });
    if (!isValid) {
      setErrors(errors);
    } else {
      setLoading(true);

      const dataToSubmit = {
        email,
        password,
        phone,
        firstName,
        lastName,
        cv,
        motivation,
        type: 'tutor',
      };

      const response = await register(dataToSubmit);

      setLoading(false);

      if (response.status === 200) {
        setNotification({
          text: response.message,
          isError: false,
        });
        window.location.href = 'https://flowently.com/thank-you-tutor';
      } else {
        setNotification({
          text: response.message,
          isError: true,
        });
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function renderHover(name = '') {
    return (
      <div
        style={{
          position: 'absolute',
          width: 251,
          marginTop: -30,
          marginLeft: 25,
          padding: 8,
          zIndex: 2,
          borderRadius: 2,
          boxShadow: '0 1px 10px 0 #e8e8e8, 0 0 2px 0 #e9f0ff',
          backgroundColor: '#ffffff',
        }}>
        <div
          style={{
            fontSize: 12,
            color: 'rgba(0, 0, 0, 0.8)',
          }}>
          Explanation about {name} letter. Maximum file size is 8
          MB, with .doc or .pdf format.
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <h5 className="text-center fs-26 font-weight-bold mb-4">
        Become A Tutor
      </h5>
      {notification.text && <Notification notification={notification} />}
      <form className="" onSubmit={onSubmit}>
        <TextFieldGroupLogin
          autoFocus={true}
          error={errors.email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          alreadyHaveAccountButton={true}
          loginLink={'/login/tutor'}
          label="Email"
          value={email}
          field="email"
          disabled={loading}
          placeholder="email@example.com"
        />
        <TextFieldGroupLogin
          error={errors.password}
          label="Password"
          onChange={(e) => {
            setErrors((prevState) => ({
              ...prevState,
              password: null,
            }));
            setPassword(e.target.value);
          }}
          value={password}
          type="password"
          field="password"
          disabled={loading}
          placeholder="Account password"
        />
        <label className="col-form-label font-weight-bold fs-15 pl-12">
          Name
        </label>
        <div className="row m-auto">
          <div className="col-6 pl-0 pr-2">
            <div
              className={classnames('form-group', {
                'has-error has-danger': errors.first_name,
              })}>
              <div className="">
                <input
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  name="first_name"
                  className="form-control px-20 py-2"
                  style={{ borderRadius: '25px' }}
                  disabled={loading}
                  id="first_name"
                  placeholder="First name"
                />
                {errors.first_name && (
                  <div className="form-control-feedback">
                    {errors.first_name}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-6 px-0">
            <div
              className={classnames('form-group', {
                'has-error has-danger': errors.last_name,
              })}>
              <div className="">
                <input
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  name="last_name"
                  className="form-control px-20 py-2"
                  style={{ borderRadius: '25px' }}
                  disabled={loading}
                  id="last_name"
                  placeholder="Last name"
                />
                {errors.last_name && (
                  <div className="form-control-feedback">
                    {errors.last_name}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <TextFieldGroupLogin
          error={errors.phone}
          label="Phone"
          onChange={(e) => {
            setErrors((prevState) => ({
              ...prevState,
              phone: null,
            }));
            setPhoneNumber(e.target.value);
          }}
          value={phone}
          field="phone"
          disabled={loading}
          placeholder="Your phone number"
        />

        <div className="row m-auto">
          <div className="col-5 pl-0 pr-2">
            <label
              className="form-control px-10 py-2 text-center mb-0"
              style={{
                borderColor: 'var(--mainPink)',
                color: 'black',
                cursor: 'pointer',
                overflow: 'hidden',
              }}>
              <span className="text-main-pink">Upload CV</span>
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    file['file_type'] = 'cv';
                    if (file.name.length > 25) {
                      file['diplay_name'] = file.name.substr(0, 19) + '...';
                    } else {
                      file['diplay_name'] = file.name;
                    }
                    setErrors((prevState) => ({
                      ...prevState,
                      cv: '',
                    }));
                    setCV(file);
                  }
                }}
              />
            </label>
            {errors.cv && (
              <div className="form-control-feedback mb-3">{errors.cv}</div>
            )}
          </div>
          <div className="col-6 px-0">
            <p className="px-10 py-2 font-italic font-weight-light fs-14">
              {cv ? cv.diplay_name : 'select your file'}
            </p>
          </div>
          <div className="col-1 px-0 py-2">
            <img
              onMouseOver={() => {
                setTopHover(true);
              }}
              onMouseOut={() => {
                setTopHover(false);
              }}
              src="/assets/img/icon/notif.svg"
              alt="notif icon"
              style={{ cursor: 'pointer' }}
            />
            {topHover && renderHover('CV')}
          </div>
        </div>

        <div className="row m-auto">
          <div className="col-5 pl-0 pr-2">
            <label
              className="form-control px-10 py-2 text-center mb-0"
              style={{
                borderColor: 'var(--mainPink)',
                color: 'black',
                cursor: 'pointer',
                overflow: 'hidden',
              }}>
              <span className="text-main-pink">Motivation</span>
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    file['file_type'] = 'motivation';
                    if (file.name.length > 25) {
                      file['diplay_name'] = file.name.substr(0, 19) + '...';
                    } else {
                      file['diplay_name'] = file.name;
                    }
                    setErrors((prevState) => ({
                      ...prevState,
                      motivation: '',
                    }));
                    setMotivation(file);
                  }
                }}
              />
            </label>
            {errors.motivation && (
              <div className="form-control-feedback">{errors.motivation}</div>
            )}
          </div>
          <div className="col-6 px-0">
            <p className="px-10 py-2 font-italic font-weight-light fs-14">
              {motivation ? motivation.diplay_name : 'select your file'}
            </p>
          </div>
          <div className="col-1 px-0 py-2">
            <img
              onMouseOver={() => {
                setBottomHover(true);
              }}
              onMouseOut={() => {
                setBottomHover(false);
              }}
              src="/assets/img/icon/notif.svg"
              alt="notif icon"
              style={{ cursor: 'pointer' }}
            />
            {bottomHover && renderHover('Motivation')}
          </div>
        </div>

        <label className="row ml-auto px-1">
          <div className={errors.is_agree ? 'mt-4' : 'mb-3 mt-4'}>
            <CustomCheckbox
              onChange={() => {
                if (!isAgree) {
                  setErrors((prevState) => ({
                    ...prevState,
                    is_agree: '',
                  }));
                }
                setIsAgree((prevState) => !prevState);
              }}
              className="mr-3"
              checked={isAgree}
            />
            <span>I agree to Flowently`s</span>
            <a
              className="mx-1 text-info"
              href="https://flowently.com/terms-conditions/"
              rel="noopener noreferrer"
              target="_blank">
              Terms
            </a>
            <span>and</span>
            <a
              className="mx-1 text-info"
              href="https://flowently.com/privacy-statement/"
              rel="noopener noreferrer"
              target="_blank">
              Privacy Policy
            </a>
            {errors.is_agree && (
              <p className="form-control-feedback ml-0 mt-1">
                {errors.is_agree}
              </p>
            )}
          </div>
        </label>

        <div className="form-group mt-10">
          <button
            disabled={loading}
            className="btn btn-block p-10 btn-main-pink fs-16 fw-500">
            {loading ? <MDSpinner /> : 'Sign up as tutor'}
          </button>
        </div>

        <div className="d-flex my-20 fs-14 justify-content-between">
          <Link
            to="/forget-password/tutor"
            disabled={loading}
            className="primary-hover fs-13">
            Forgot your password ?
          </Link>
        </div>
      </form>
    </div>
  );
}

SignupFormTutor.propTypes = {
  register: PropsTypes.func,
};

export default connect(null, mapActionToProps)(SignupFormTutor);
