import React, { Component } from 'react';
import Rating from 'react-rating';
import moment from 'moment';
import { isEmpty, sortBy } from 'lodash';

import TextFieldGroupLogin from '../../../components/common/TextFieldGroupLogin';
import TextAreaFieldGroup from '../../../components/common/TextAreaFieldGroup';

import { connect } from 'react-redux';
import { validateInputReview } from '../../common/validations/studentProfile';
import {
  userGetAppointmentBySessionID,
  userSubmitReview,
} from '../../../redux/actions/appointmentActions';
import LoginModal from '../../booking/views/LoginModal';

class RateTutor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booking_id: this.props.match.params.id,
      data: {},
      rating_value: 0,
      isLoading: false,
      isLoading_review: false,
      notification: {},
      isMobileScreen: false,
      isOpenNotesModal: false,
      isUpcomingAppointment: false,
      isOpenReview: false,
      isOpenRebookModal: false,
      ratingHasChanged: false,
      errors: {},
      default_avatar: '/assets/img/icon/male.svg',
      isCancelModalOpen: false,
    };
  }

  componentWillMount = () => {
    const { auth } = this.props;
    if (!auth.isAuthenticated) {
      this.setState({ showLoginModal: true });
    } else {
      this.getSingleAppointment();
    }
  };

  getSingleAppointment = () => {
    this.setState({ isLoading: true, notification: {}, errors: {} });
    this.props
      .userGetAppointmentBySessionID({ booking_id: this.state.booking_id })
      .then((res) => {
        if (res.status === 200) {
          if (res.message.data.student_id._id === this.props.auth.user.id) {
            if (!isEmpty(res.message.data.review)) {
              this.props.history.push('/profile/appointments');
            } else {
              const parse_notes = res.message.data.notes.map((note) => {
                return {
                  ...note,
                  errors: null,
                  isEditing: false,
                  isLoading: false,
                };
              });

              let sorted_notes = sortBy(parse_notes, (item) => {
                return moment(item.createdDate, 'MM/DD/YYYY, HH:mm:ss');
              }).reverse();

              this.setState({
                isLoading: false,
                data: {
                  ...res.message.data,
                  notes: sorted_notes,
                },
                rating_value: res.message.data.rating,
              });
            }
          } else {
            this.props.history.push('/profile/appointments');
          }
        } else {
          this.setState({
            isLoading: false,
            notification: {
              isError: true,
              text: res.message,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentDidMount = () => {
    window.addEventListener('screenSize', this.screenSize());
  };

  onChangeInputRating = (value) => {
    this.setState({
      errors: {
        ...this.state.errors,
        rating_value: null,
      },
      ratingHasChanged: true,
      rating_value: value,
    });
  };

  onChangeReview = (e) => {
    if (e.target.name === 'title') {
      this.setState({
        data: {
          ...this.state.data,
          review: {
            ...this.state.data.review,
            title: e.target.value,
          },
        },
        errors: {
          ...this.state.errors,
          review_title: null,
        },
      });
    }

    if (e.target.name === 'description') {
      this.setState({
        data: {
          ...this.state.data,
          review: {
            ...this.state.data.review,
            description: e.target.value,
          },
        },
      });
    }
  };

  isValid = () => {
    const { errors, isValid } = validateInputReview(
      this.state.data,
      this.state.rating_value,
    );
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };

  onSubmitReview = () => {
    this.setState({ isLoading: true, notification: {} });
    const { review, _id } = this.state.data;
    const dataToSubmit = {
      _id,
      rating: this.state.rating_value.toString(),
      review,
    };

    if (this.isValid()) {
      this.props
        .userSubmitReview(dataToSubmit)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              isLoading: false,
              notification: {
                text: 'Your review has been submited successfully.',
                isError: true,
              },
              isOpenReview: false,
            });
            this.props.history.push('/profile/appointments');
          } else {
            this.setState({
              isLoading: false,
              notification: {
                text: 'We are experiencing technical difficulties at the moment, please try again later',
                isError: true,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
            notification: {
              text: 'Internal server error. Please try again later',
              isError: true,
            },
          });
        });
    } else {
      this.setState({
        isLoading_review: false,
        notification: {
          text: 'Please complete the form',
          isError: true,
        },
      });
    }
  };

  closeModal = () => {
    this.setState({ showLoginModal: false });
    window.location.reload();
  };

  render() {
    const {
      data,
      showLoginModal,
      isMobileScreen,
      rating_value,
      default_avatar,
      isLoading,
      errors,
    } = this.state;

    const { auth } = this.props;

    if (isEmpty(data)) {
      return (
        <div>
          {!auth.isAuthenticated ? (
            <LoginModal
              closeModal={this.closeModal}
              getStudentData={() => {}}
            />
          ) : null}
        </div>
      );
    }

    return (
      <div className="w-700px mx-auto">
        <div className="d-flex justify-content-between pt-20 pb-10 px-30">
          <div>
            <div className="d-flex align-items-center mb-2">
              <img
                alt=""
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  boxShadow: '0px 2px 15px #e7e7e7',
                }}
                src={
                  !isEmpty(data.tutor_id.avatar_display)
                    ? data.tutor_id.avatar_display
                    : default_avatar
                }
              />
              <div className="ml-2">
                <h5 className="fw-500 fs-22 mb-0" style={{ lineHeight: '1.2' }}>
                  {data.tutor_id.first_name} {data.tutor_id.last_name}
                </h5>
                <h5 className="fw-500 fs-16 mb-0" style={{ color: '#9c9c9c' }}>
                  Session date :{' '}
                  {moment(data.appointment_date).format('DD MMMM YYYY')}
                </h5>
              </div>
            </div>
          </div>
          <h5 className="fw-500 fs-25 lh-1">Session ID : {data.booking_id}</h5>
        </div>
        <div className={isMobileScreen ? 'm-0 p-30' : 'm-0 py-30 px-40'}>
          <div className="text-center mt-0 mb-20">
            <h5 className="mb-10 fs-18 font-weight-bold">Your Review</h5>
            <Rating
              emptySymbol={
                errors.rating_value
                  ? 'fa fa-star-o fa-2x star-rating-error'
                  : 'fa fa-star-o fa-2x star-rating'
              }
              fullSymbol="fa fa-star fa-2x star-rating"
              initialRating={rating_value}
              readonly={!isEmpty(data.rating)}
              onChange={this.onChangeInputRating}
            />
            {errors.rating_value ? (
              <p className="fs-12 mt-10 font-italic letter-spacing-0 text-danger">
                Please give your rate
              </p>
            ) : null}
          </div>
          <div>
            <TextFieldGroupLogin
              label="Title"
              field="title"
              value={isEmpty(data.review) ? '' : data.review.title}
              placeholder="Type something"
              onChange={this.onChangeReview}
              disabled={isLoading}
              // error={isEmpty(errors) ? '' : errors.review_title} REMOVE REQUIRED REVIEW TITLE - REQ CLIENT
              readOnly={!isEmpty(data.rating)}
            />
            <TextAreaFieldGroup
              label="Description (optional)"
              field="description"
              value={isEmpty(data.review) ? '' : data.review.description}
              placeholder="Type something"
              onChange={this.onChangeReview}
              disabled={isLoading}
              error={isEmpty(errors) ? '' : errors.review_description}
              readOnly={!isEmpty(data.rating)}
            />
          </div>
          {!isEmpty(data.rating) ? null : (
            <div className="d-flex justify-content-end align-items-center mt-40">
              <button
                onClick={this.onSubmitReview}
                className="btn btn-main-pink">
                Submit
              </button>
            </div>
          )}
        </div>
        {showLoginModal ? (
          <LoginModal closeModal={this.closeModal} getStudentData={() => {}} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  userGetAppointmentBySessionID,
  userSubmitReview,
})(RateTutor);
