import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export function validateInputReceiverInfo(data) {
  let errors = {};
  let isValid = true;

  if (!isEmpty(data.receiver_email)) {
    if (!Validator.isEmail(data.receiver_email)) {
      errors.receiver_email = 'Email not valid';
      isValid = false;
    }
  } else {
    errors.receiver_email = 'Email is required';
    isValid = false;
  }

  if (isEmpty(data.receiver_first_name)) {
    errors.receiver_first_name = 'First name is required';
    isValid = false;
  }

  if (isEmpty(data.receiver_last_name)) {
    errors.receiver_last_name = 'Last name is required';
    isValid = false;
  }

  if (!isEmpty(data.receiver_phone)) {
    if (isNaN(data.receiver_phone)) {
      errors.receiver_phone = 'Phone number is not valid';
      isValid = false;
    }
  } else {
    errors.receiver_phone = 'Phone number is required';
    isValid = false;
  }

  return {
    errors,
    isValid,
  };
}

export function validateInputPersonalInfo(data) {
  let errors = {};
  let isValid = true;

  if (!isEmpty(data.email)) {
    if (!Validator.isEmail(data.email)) {
      errors.email = 'Email is not valid';
      isValid = false;
    }
  } else {
    errors.email = 'Email is required';
    isValid = false;
  }

  if (isEmpty(data.first_name)) {
    errors.first_name = 'First name is required';
    isValid = false;
  }

  if (isEmpty(data.last_name)) {
    errors.last_name = 'Last name is required';
    isValid = false;
  }

  if (isEmpty(data.living_in_city_text)) {
    errors.living_in_city_text = 'City is required';
    isValid = false;
  }

  // if(isEmpty(data.living_in_country)) {
  //     errors.living_in_country = 'Please select country';
  //     isValid = false
  // };

  // if(isEmpty(data.living_in_city)) {
  //     errors.living_in_city = 'Please select city';
  //     isValid = false
  // };

  if (isEmpty(data.street_name)) {
    errors.street_name = 'Street name is required';
    isValid = false;
  }

  if (isEmpty(data.house_number)) {
    errors.house_number = 'House number is required';
    isValid = false;
  }

  if (isEmpty(data.postcode)) {
    errors.postcode = 'Postal code is required';
    isValid = false;
  }

  if (!isEmpty(data.phone)) {
    if (isNaN(data.phone)) {
      errors.phone = 'Phone number is not valid';
      isValid = false;
    }
  } else {
    errors.phone = 'Please input your phone number';
    isValid = false;
  }

  return {
    errors,
    isValid,
  };
}

export function validatePayment(data) {
  let errors = {};
  let isValid = true;

  if (isEmpty(data.selected_payment)) {
    errors.selected_payment = 'Please select payment method';
    isValid = false;
  }

  if (!data.terms_and_conditions_checked) {
    errors.terms_and_conditions_checked =
      'Please read and accept the terms and conditions to proceed with your order.';
    isValid = false;
  }

  return {
    errors,
    isValid,
  };
}
