// export const AUTH_ACTIONS = Object.freeze({
//   SET_DATA: 'myapp/auth/set-data',
//   LOGIN: 'myapp/auth/login',
// })

// export const authActions = Object.freeze({
//   setData: (field, value) => ({
//     type: AUTH_ACTIONS.SET_DATA,
//     field,
//     value,
//   }),
//   login: value => ({
//     type: AUTH_ACTIONS.LOGIN,
//     value,
//   }),
// })

// const initialState = {
//   user: null,
//   token: null,
//   type: null,
// }

// const reducer = (state = initialState, { type, field, value }) => {
//   switch (type) {
//     case AUTH_ACTIONS.SET_DATA:
//       return {
//         ...state,
//         [field]: value,
//       }
//     case AUTH_ACTIONS.LOGIN:
//       return {
//         ...state,
//         user: value.user,
//         token: value.token,
//         type: value.type,
//       }
//     default:
//       return state
//   }
// }

// export default reducer

import { SET_CURRENT_USER, UPDATE_USER } from '../actions/types';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  isAuthenticated: false,
  user: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        isAuthenticated: !isEmpty(action.user),
        user: action.user,
      };
    case UPDATE_USER:
      const { save_tanggal_lahir, tempat_lahir, nomor_telepon, email_pribadi } =
        action.user;
      const newState = Object.assign({}, state);
      newState.user.tanggal_lahir = save_tanggal_lahir;
      newState.user.tempat_lahir = tempat_lahir;
      newState.user.nomor_telepon = nomor_telepon;
      newState.user.email_pribadi = email_pribadi;
      return newState;
    default:
      return state;
  }
};
