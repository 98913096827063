import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import MDSpinner from 'react-md-spinner';

import { connect } from 'react-redux';
import {
  userCheckPayment,
  userUpdatePurchaseGtmTrackedStatus,
} from '../../../redux/actions/paymentActions';

class PaymentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_id: '',
      payment_status: 'open',
      package_title: null,
      payment_method: null,
      checkout_url: '',
      isNewStudent: false,
      isLoading: false,
      notification: {},
      isMobileScreen: false,
    };
  }

  initializeGTM = (purchase_data, package_id) => {
    const tax = parseFloat((parseFloat(purchase_data.amount.value) / 121) * 21);
    const tagManagerArgs = {
      gtmId: 'GTM-NG4WRF7',
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          transaction_id: purchase_data.order_id,
          value: purchase_data.amount.value,
          tax: tax.toString(),
          shipping: 0,
          currency: "EUR",
          items: [{
            item_id: package_id,
            item_name: purchase_data.description,
            item_category: 'package',
            price: purchase_data.amount.value,
            quantity: 1,
          }],
          currencyCode: 'EUR',
          purchase: {
            actionField: {
              id: purchase_data.order_id,
              revenue: purchase_data.amount.value,
              tax: tax.toString(),
              shipping: 0,
            },
            products: [
              {
                id: package_id,
                name: purchase_data.description,
                category: 'package',
                price: purchase_data.amount.value,
                quantity: 1,
              },
            ],
          },
        },
        // 'ecommerce': {
        //   'purchase': {
        //     'transaction_id': purchase_data.order_id,
        //     'value': purchase_data.amount.value,
        //     'tax': tax.toString(),
        //     'shipping': '0',
        //     'currency': 'EUR',
        //     'items': [{
        //       'item_name': purchase_data.description,
        //       // 'item_id': '12345',
        //       'item_price': purchase_data.amount.value,
        //       'item_brand': 'Flowently',
        //       'item_category': 'Package',
        //       'quantity': 1
        //     }]
        //   }
        // }
      },
    };

    TagManager.initialize(tagManagerArgs);

    this.props
      .userUpdatePurchaseGtmTrackedStatus({ order_id: purchase_data.order_id })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    return true;
  };

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentDidMount = () => {
    window.addEventListener('screenSize', this.screenSize());
  };

  componentWillMount = () => {
    this.setState({ isLoading: true, notification: {} });
    const { order_id } = this.props.match.params;
    this.props
      .userCheckPayment({ order_id })
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            isLoading: false,
            notification: {
              text: res.message,
              isError: true,
            },
          });
        } else {
          const {
            payment_status,
            gtmTracked,
            package_title,
            order_id,
            checkout_url,
          } = res.message.data;
          if (payment_status === 'paid' && !gtmTracked) {
            this.initializeGTM(res.message.data, res.message.package_id);
          }

          this.setState({
            isLoading: false,
            order_id,
            payment_status,
            package_title,
            checkout_url,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          notification: {
            text: 'Failed to load your payment progress',
            isError: true,
          },
        });
      });
    // if(this.props.location.state) {
    //   const { payment_method, package_title, checkout_url, isNewStudent } = this.props.location.state;
    //   this.setState({ payment_method, package_title, checkout_url, isNewStudent })
    // }
  };

  render() {
    const {
      isLoading,
      // order_id,
      payment_status,
      checkout_url,
      isNewStudent,
    } = this.state;

    return (
      <div className="main-content mx-auto">
        {isLoading ? (
          <div className="card py-150" style={{ borderRadius: 8 }}>
            <div className="text-center">
              <MDSpinner />
              <p className="mb-0 mt-4 fs-14">Please wait...</p>
              <p className="mb-0 fs-14">Checking your payment status</p>
            </div>
          </div>
        ) : (
          <div className="card py-150" style={{ borderRadius: 8 }}>
            <div className="text-center mb-10">
              {payment_status === 'paid' ? (
                <img
                  src="/assets/img/icon/success.svg"
                  className="h-50px"
                  alt="success"
                />
              ) : null}
            </div>
            {payment_status === 'paid' ? (
              <h1 className="mb-0 text-center fs-25">
                Thank you for your order.
              </h1>
            ) : payment_status === 'open' ? (
              <h1 className="mb-0 text-center fs-25">
                Your order has been received - Please complete your payment.
              </h1>
            ) : (
              <h1 className="mb-0 text-center fs-25">
                Your payment is {payment_status}
              </h1>
            )}
            {payment_status === 'paid' ? (
              <div className="text-center">
                <p className="mb-0">
                  Before your appointment, read the latest articles on our
                  <span className="mx-2">
                    <a
                      className="text-black"
                      rel="noopener noreferrer"
                      href={'https://flowently.whellostudio.id/language-tips'}>
                      Blog
                    </a>
                  </span>
                  or connect with us on our social media.
                </p>
                {isNewStudent ? (
                  <p>
                    Your email has been added as a new account. You need to
                    confirm your account before you can Login. Please check your
                    email for account confirmation.
                  </p>
                ) : null}
              </div>
            ) : payment_status === 'expired' ? (
              <div className="text-center">
                <p className="mb-0">You need to buy package again.</p>
              </div>
            ) : (
              <div className="text-center">
                {isNewStudent ? (
                  <p>
                    Your email has been added as a new account. You need to
                    confirm your account before you can Login.
                    <br />
                    Please check your email for account confirmation.
                  </p>
                ) : null}
              </div>
            )}
            <div className="text-center mt-30">
              {payment_status === 'open' ? (
                <a
                  href={checkout_url}
                  className="btn btn-main-pink text-white letter-spacing-0">
                  Continue payment
                </a>
              ) : payment_status === 'paid' ? (
                <Link to="/profile/account">
                  <button className="btn btn-main-pink">
                    See transaction history
                  </button>
                </Link>
              ) : (
                <Link to="/packages">
                  <button className="btn btn-main-pink">Buy package</button>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, {
  userCheckPayment,
  userUpdatePurchaseGtmTrackedStatus,
})(PaymentStatus);
