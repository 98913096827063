import {
  RESET_SEARCH_DATA,
  RESET_SELECTED_TUTOR,
  SET_SEARCH_FILTER,
  SET_SEARCH_RESULT,
  SET_SELECTED_TUTOR,
} from '../actions/types';

const initialState = {
  searchFilter: {},
  searchResult: [],
  selectedTutor: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.payload,
      };
    case SET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.payload,
      };
    case SET_SELECTED_TUTOR:
      return {
        ...state,
        selectedTutor: action.payload,
      };
    case RESET_SELECTED_TUTOR:
      return {
        ...state,
        selectedTutor: {},
      };
    case RESET_SEARCH_DATA:
      return {
        searchFilter: {},
        searchResult: [],
        selectedTutor: {},
      };
    default:
      return state;
  }
};
