import React from 'react';
import Select from 'react-select';

export default function SelectFieldBookPage({
  name,
  value,
  options,
  onChange,
  isClearable,
  isDisabled,
  isSearchable,
  minWidth,
  placeholder,
}) {

  return (
    <div>
      <Select
        styles={{
          // ...styles,
          option: (base, state) => ({
            ...base,
            fontSize: '14px',
            color: state.isFocused
              ? '#000000'
              : state.isSelected
              ? '#ffffff'
              : base.color,
            backgroundColor: state.isSelected ? '#f37a4f' : base.color,
            '&:hover': {
              backgroundColor: state.isSelected ? '#f37a4f' : '#ffe5db75',
              color: state.isSelected ? '#ffffff' : '#000000',
            },
            transition: '0.2s all ease-in-out',
          }),
          control: (base, state) => ({
            ...base,
            borderWidth: 0,
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            width: minWidth ? minWidth : '',
            fontSize: 15,
          }),
          container: (base, state) => ({
            ...base,
            outline: '0px !important',
            outlineOffset: '0px !important',
            border: '0px',
          }),
          indicatorSeparator: (base, state) => ({
            ...base,
            backgroundColor: 'transparent!important',
          }),
        }}
        name={name}
        isDisabled={isDisabled}
        value={value}
        onChange={onChange}
        options={options}
        isClearable={isClearable}
        isSearchable={isSearchable}
        placeholder={placeholder ? placeholder : 'Select...'}
      />
    </div>
  );
}
