import React from 'react';
import SignupFormTutor from './signupFormTutor';
import { Redirect, Route, Switch } from 'react-router-dom';
import SignupFormStudent from './signupFormStudent';

class loginPage extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <div className="main-content">
        <div className="login-wrapper mx-20">
          <div className="row m-auto">
            <div className="col-md-6 signup-form-wrapper">
              <Switch>
                <Route
                  path={`${match.path}/tutor`}
                  component={SignupFormTutor}
                />
                <Route
                  path={`${match.path}/student`}
                  component={SignupFormStudent}
                />
                <Redirect to={`${match.path}/student`} />
              </Switch>
            </div>
            <div className="col-md-6 bg-signup overflow-hidden"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default loginPage;
