import React, { Component } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { DOMAIN } from '../../../utils/api';
import MDSpinner from 'react-md-spinner';

import CustomCheckbox from '../../common/CustomCheckbox';
import CustomRadioButton from '../../common/CustomRadioButton';
import Notification from '../../common/Notification';
import TextFieldGroupLogin from '../../common/TextFieldGroupLogin';

import { connect } from 'react-redux';
import { userGetPaymentMethodsList } from '../../../redux/actions/paymentActions';
import { userCreateNewBookPayment } from '../../../redux/actions/singleSessionActions';
import {
  validateInputPersonalInfo,
  validatePayment,
} from '../../common/validations/checkoutPayment';
class CheckoutBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tutor_data: {},
      appointment_data: {},
      package_data: {},
      isMobileScreen: false,
      // STUDENT PERSONAL INFO
      account_errors: {},
      isLoading_account: false,
      account_notification: {},
      student_id: null,
      first_name: '',
      last_name: '',
      street_name: '',
      house_number: '',
      living_in_city_text: '',
      postcode: '',
      phone: '',
      email: '',
      // CHECKOUT
      isLoading_checkout: false,
      checkout_notification: {},
      // PAYMENT
      selected_payment: '',
      payment_methods_list: [],
      payment_errors: {},
      payment_notification: {},
      isLoading_payment: false,
      terms_and_conditions_checked: false,
      subscribe_checked: false,
      paymentInProgress: false,
      checkout_url: '',
    };
    this.topPageRef = React.createRef();
  }

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  getPaymentMethodsList = () => {
    this.setState({ isLoading_payment: true, payment_notification: {} });
    this.props
      .userGetPaymentMethodsList()
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            isLoading_payment: false,
            payment_notification: {
              text: 'Internal server error. Please try again later',
              isError: true,
            },
          });
        } else {
          this.setState({
            isLoading_payment: false,
            payment_methods_list: res.message.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading_payment: false,
          payment_notification: {
            text: 'We are experiencing technical difficulties while load payment methods, please try again later.',
            isError: true,
          },
        });
      });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const { state } = this.props.history.location;
    if (!isEmpty(state)) {
      const { student_data, tutor_data, appointment_data, package_data } =
        state;

      this.setState({
        student_id: student_data._id,
        first_name: student_data.first_name,
        last_name: student_data.last_name,
        street_name: student_data.street_name,
        house_number: student_data.house_number,
        living_in_city_text: student_data.living_in_city_text,
        postcode: student_data.postcode,
        phone: student_data.phone,
        email: student_data.email,
        tutor_data,
        appointment_data,
        package_data,
      });
    }
    window.addEventListener('screenSize', this.screenSize());
    this.getPaymentMethodsList();
  };

  onChangeAccountDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      account_errors: {
        ...this.state.account_errors,
        [e.target.name]: null,
      },
    });
  };

  onChangeSelectedPayment = (method_id) => {
    this.setState({
      selected_payment: method_id,
      payment_errors: {
        ...this.state.payment_errors,
        selected_payment: null,
      },
      checkout_notification: {},
    });
  };

  onChangeRadioButton = (e) => {
    if (e.name === 'terms_and_conditions_checked') {
      this.setState({
        terms_and_conditions_checked: !this.state.terms_and_conditions_checked,
        payment_errors: {
          ...this.state.payment_errors,
          terms_and_conditions_checked: null,
        },
        checkout_notification: {},
      });
    } else {
      this.setState({
        subscribe_checked: !this.state.subscribe_checked,
      });
    }
  };

  isValidPersonalInfo = () => {
    const { errors, isValid } = validateInputPersonalInfo(this.state);
    if (!isValid) {
      this.setState({ account_errors: errors });
    }
    return isValid;
  };

  isValidPayment = () => {
    const { errors, isValid } = validatePayment(this.state);
    if (!isValid) {
      this.setState({ payment_errors: errors });
    }
    return isValid;
  };

  onCheckout = () => {
    this.setState({ checkout_notification: {}, isLoading_checkout: true });
    if (this.isValidPersonalInfo()) {
      if (this.isValidPayment()) {
        const {
          email,
          first_name,
          last_name,
          living_in_city_text,
          street_name,
          house_number,
          postcode,
          phone,
          appointment_data,
          tutor_data,
          package_data,
          selected_payment,
        } = this.state;

        const dataToSubmit = {
          student_data: {
            _id: this.props.auth.user.id,
            email,
            first_name,
            last_name,
            living_in_city_text,
            street_name,
            house_number,
            postcode,
            phone,
          },
          appointment_data: {
            student_id: this.props.auth.user.id,
            tutor_id: tutor_data._id,
            person: appointment_data.person.value,
            appointment_date: appointment_data.appointment_date,
            book_price: appointment_data.book_price,
            session_type: {
              value: appointment_data.session_type.value,
              label: appointment_data.session_type.label,
            },
            session_duration: appointment_data.session_duration.value,
          },
          tutor_data,
          package_data,
          payment_method: selected_payment,
        };

        this.props
          .userCreateNewBookPayment(dataToSubmit)
          .then((res) => {
            if (res.status !== 200) {
              this.setState({
                isLoading_checkout: false,
                checkout_notification: {
                  text: res.message,
                  isError: true,
                },
              });
            } else {
              const { payment_data } = res.message.data;

              this.setState({
                isLoading: false,
                paymentInProgress: true,
                checkout_url: payment_data._links.checkout.href,
              });

              window.open(payment_data._links.checkout.href, '_self');
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              isLoading_checkout: false,
              checkout_notification: {
                text: 'Internal server error. Please try again later',
                isError: true,
              },
            });
          });
      } else {
        this.setState({
          isLoading_checkout: false,
          checkout_notification: {
            text: 'Please complete the payment form below',
            isError: true,
          },
        });
      }
    } else {
      window.scrollTo(-100, 0);
      this.setState({
        isLoading_checkout: false,
        account_notification: {
          text: 'Please complete the form below',
          isError: true,
        },
      });
    }
  };

  render() {
    const {
      isMobileScreen,
      tutor_data,
      appointment_data,
      // CHECKOUT INFO
      isLoading_checkout,
      checkout_notification,
      package_data,
      // STUDENT PERSONAL INFO
      account_errors,
      account_notification,
      isLoading_account,
      first_name,
      last_name,
      street_name,
      house_number,
      living_in_city_text,
      postcode,
      phone,
      email,
      // PAYMENT
      payment_methods_list,
      selected_payment,
      payment_errors,
      payment_notification,
      isLoading_payment,
      terms_and_conditions_checked,
      subscribe_checked,
      paymentInProgress,
      checkout_url,
    } = this.state;

    let render_benefit = null;
    if (!isEmpty(package_data)) {
      render_benefit = package_data.benefit_list.map((item, idx) => (
        <div
          key={`benefit-${idx + 1}`}
          className={
            isMobileScreen ? 'd-flex mb-2' : 'd-flex align-items-center mb-2'
          }>
          <i
            className={
              isMobileScreen
                ? 'fa fa-check-circle-o mr-2 mt-1'
                : 'fa fa-check-circle-o mr-2'
            }
            style={{ color: '#2acf7f' }}></i>
          <h6 className="fs-16 text-capitalize mb-0">{item.text}</h6>
        </div>
      ));
    }

    let render_payment_methods;
    if (!isEmpty(payment_methods_list)) {
      render_payment_methods = payment_methods_list.map((method, idx) => {
        return (
          <div
            key={`method-${idx + 1}`}
            style={{ width: '95%' }}
            className="pt-30 pb-20 border-bottom mx-auto">
            <label
              className={
                isMobileScreen
                  ? 'd-flex align-items-center px-20'
                  : 'd-flex align-items-center px-25'
              }>
              <CustomCheckbox
                error={
                  !isEmpty(payment_errors) &&
                  !isEmpty(payment_errors.selected_payment)
                }
                onChange={() => this.onChangeSelectedPayment(method.id)}
                className="mr-2"
                checked={selected_payment === method.id}
              />
              <h6 className="mb-0 fs-16 mr-3">{method.description}</h6>
              <img src={method.image.svg} alt={method.id} />
            </label>
          </div>
        );
      });
    } else {
      render_payment_methods = (
        <div className={isMobileScreen ? 'px-20' : 'px-40'}>
          <Notification notification={payment_notification} />
        </div>
      );
    }

    return (
      <div ref={this.topPageRef} className="row mx-auto">
        <div className="col-md-7">
          {/* PERSONAL INFORMATION CARD */}
          <div
            className={
              isMobileScreen
                ? 'card card-body p-20'
                : 'card card-body py-20 px-60'
            }
            style={{ borderRadius: 14 }}>
            <h1 className="fs-25 mt-20 mb-0 text-black font-weight-bold">
              Personal Information
            </h1>
            {isLoading_account ? (
              <div className="d-flex justify-content-center flex-column my-40">
                <div className="text-center">
                  <MDSpinner />
                </div>
                <div className="text-center mt-3">
                  <small>
                    Getting your personal information. Please wait...
                  </small>
                </div>
              </div>
            ) : (
              <>
                <div className="my-30">
                  <Notification notification={account_notification} />
                  <div className="row">
                    <div className="col-md-6">
                      <TextFieldGroupLogin
                        label="First name *"
                        field="first_name"
                        value={first_name}
                        placeholder="First name"
                        onChange={this.onChangeAccountDetails}
                        disabled={isLoading_account || isLoading_checkout}
                        error={account_errors.first_name}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextFieldGroupLogin
                        label="Last name *"
                        field="last_name"
                        value={last_name}
                        placeholder="Last name"
                        onChange={this.onChangeAccountDetails}
                        disabled={isLoading_account || isLoading_checkout}
                        error={account_errors.last_name}
                      />
                    </div>
                  </div>
                  <TextFieldGroupLogin
                    label="Street Name"
                    field="street_name"
                    value={street_name}
                    placeholder="Street name"
                    onChange={this.onChangeAccountDetails}
                    disabled={isLoading_account || isLoading_checkout}
                    error={account_errors.street_name}
                  />
                  <TextFieldGroupLogin
                    label="House Number"
                    field="house_number"
                    value={house_number}
                    placeholder="House number"
                    onChange={this.onChangeAccountDetails}
                    disabled={isLoading_account || isLoading_checkout}
                    error={account_errors.house_number}
                  />
                  <TextFieldGroupLogin
                    label="City"
                    field="living_in_city_text"
                    value={living_in_city_text}
                    placeholder="City"
                    onChange={this.onChangeAccountDetails}
                    disabled={isLoading_account || isLoading_checkout}
                    error={account_errors.living_in_city_text}
                  />
                  <TextFieldGroupLogin
                    label="Postal code"
                    field="postcode"
                    value={postcode}
                    placeholder="Postal code"
                    onChange={this.onChangeAccountDetails}
                    disabled={isLoading_account || isLoading_checkout}
                    error={account_errors.postcode}
                  />
                  <TextFieldGroupLogin
                    label="Phone Number"
                    field="phone"
                    value={phone}
                    placeholder="Phone number"
                    onChange={this.onChangeAccountDetails}
                    disabled={isLoading_account || isLoading_checkout}
                    error={account_errors.phone}
                  />
                  <TextFieldGroupLogin
                    label="Email *"
                    field="email"
                    value={email}
                    placeholder="Email"
                    onChange={this.onChangeAccountDetails}
                    readOnly={true}
                    disabled={isLoading_account || isLoading_checkout}
                    error={account_errors.email}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-md-5">
          {/* BOOKING OVERVIEW */}
          {!isEmpty(appointment_data) ? (
            <div className="card" style={{ borderRadius: 14 }}>
              <div className="card-body py-20">
                <h3 className="text-center font-weight-bold">
                  Booking overview
                </h3>
                <hr
                  className="my-20 mx-auto border-top"
                  style={{ width: '95%' }}
                />
                <div className={isMobileScreen ? 'px-10' : 'px-40'}>
                  <div className="d-flex align-items-center justify-content-between mt-20">
                    <p className="mb-0 fs-14">Tutor</p>
                    <p className="mb-0 fs-15 font-weight-bold">
                      {tutor_data.display_name}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-20">
                    <p className="mb-0 fs-14">Date</p>
                    <p className="mb-0 fs-15 font-weight-bold">
                      {moment(
                        appointment_data.appointment_date,
                        'MM/DD/YYYY',
                      ).format('MMM DD, YYYY')}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-20">
                    <p className="mb-0 fs-14">Session type</p>
                    <p className="mb-0 fs-14 text-capitalize">
                      {appointment_data.session_type.value}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-20">
                    <p className="mb-0 fs-14">Session duration</p>
                    <p className="mb-0 fs-15 font-weight-bold">
                      {appointment_data.session_duration.label}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-20">
                    <p className="mb-0 fs-14">Person</p>
                    <p className="mb-0 fs-15 font-weight-bold">
                      x {appointment_data.person.value}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* PACKAGE OVERVIEW */}
          {!isEmpty(package_data) ? (
            <div className="card" style={{ borderRadius: 14 }}>
              <div className="card-body py-20">
                <h3 className="text-center font-weight-bold">Order overview</h3>
                <hr
                  className="my-20 mx-auto border-top"
                  style={{ width: '95%' }}
                />
                <div className={isMobileScreen ? 'px-10' : 'px-40'}>
                  <h4 className="mb-10">{`${package_data.credit_value} ${
                    package_data.credit_value > 1
                      ? 'hours package'
                      : 'hour package'
                  }`}</h4>
                  {package_data.benefit_list.length > 0 ? (
                    <div className="mb-10">{render_benefit}</div>
                  ) : (
                    <p className="mb-10 fs-14">{package_data.description}</p>
                  )}
                </div>
                <hr
                  className="my-20 mx-auto border-top"
                  style={{ width: '95%' }}
                />
                <div
                  className={
                    isMobileScreen
                      ? 'd-flex justify-content-between align-items-center px-10 mt-0 mb-20'
                      : 'd-flex justify-content-between align-items-center px-40 mt-0 mb-20'
                  }>
                  <h6 className="fs-16 mb-0">Price</h6>
                  <h6 className="fs-16 mb-0">
                    &euro; {package_data.sale_price}
                  </h6>
                </div>
              </div>
            </div>
          ) : null}

          {/* PAYMENT CARD */}
          <div className="card card-body pt-20" style={{ borderRadius: 14 }}>
            <h1 className="fs-25 mt-0 mb-0 text-center text-black font-weight-bold">
              Payment
            </h1>
            {isLoading_payment ? (
              <div className="d-flex justify-content-center flex-column my-40">
                <div className="text-center">
                  <MDSpinner />
                </div>
                <div className="text-center mt-3">
                  <small className="fs-14">
                    Getting available payment methods. Please wait...
                  </small>
                </div>
              </div>
            ) : (
              <>
                <div className={isMobileScreen ? 'px-20 mt-20' : 'px-30 mt-20'}>
                  <Notification notification={checkout_notification} />
                </div>
                <div className={checkout_notification ? 'mt-0 mb-10' : 'my-10'}>
                  {render_payment_methods}
                </div>
                {/* Payment form validation error message */}
                {!isEmpty(payment_errors) &&
                  !isEmpty(payment_errors.selected_payment) && (
                    <div
                      className="form-control-feedback mx-0"
                      style={{ padding: isMobileScreen ? '0 20px' : '0 36px' }}>
                      {payment_errors.selected_payment}
                    </div>
                  )}
                {isEmpty(payment_notification) ? (
                  <div
                    className="my-20"
                    style={{ padding: isMobileScreen ? '0 20px' : '0 36px' }}>
                    <div className="mb-20">
                      <label
                        className={
                          isMobileScreen
                            ? 'd-flex'
                            : 'd-flex align-items-center px-1'
                        }>
                        <CustomRadioButton
                          error={
                            !isEmpty(payment_errors) &&
                            !isEmpty(
                              payment_errors.terms_and_conditions_checked,
                            )
                          }
                          onChange={() =>
                            this.onChangeRadioButton({
                              name: 'terms_and_conditions_checked',
                            })
                          }
                          className={isMobileScreen ? 'mt-1 mr-2' : 'mr-2'}
                          checked={terms_and_conditions_checked}
                          disabled={isLoading_checkout}
                        />
                        <div className="d-flex align-items-center">
                          <p
                            className="mb-0 fs-16 letter-spacing-0"
                            style={{ lineHeight: '1.5' }}>
                            I have read and agree to the website
                            <a
                              href={`${DOMAIN}/terms-and-conditions`}
                              rel="noopener noreferrer"
                              target="_blank"
                              className="ml-1 text-black text-bold">
                              terms and conditions*
                            </a>
                          </p>
                        </div>
                      </label>
                      {/* Payment form validation error message */}
                      {!isEmpty(payment_errors) &&
                        !isEmpty(
                          payment_errors.terms_and_conditions_checked,
                        ) && (
                          <div className="form-control-feedback mx-0 pl-24">
                            {payment_errors.terms_and_conditions_checked}
                          </div>
                        )}
                    </div>
                    <div className="">
                      <label
                        className={
                          isMobileScreen
                            ? 'd-flex'
                            : 'd-flex align-items-center px-1'
                        }>
                        <CustomRadioButton
                          onChange={() =>
                            this.onChangeRadioButton({
                              name: 'subscribe_checked',
                            })
                          }
                          className={isMobileScreen ? 'mt-1 mr-2' : 'mr-2'}
                          checked={subscribe_checked}
                          disabled={isLoading_checkout}
                        />
                        <p
                          className="mb-0 fs-16 letter-spacing-0"
                          style={{ lineHeight: '1.5' }}>
                          Send me promos and product updates
                        </p>
                      </label>
                    </div>
                    <div className="form-group mt-40 mb-0">
                      {paymentInProgress && !isEmpty(checkout_url) ? (
                        <a
                          href={checkout_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-block p-10 btn-main-pink fs-18 fw-500">
                          Continue payment
                        </a>
                      ) : (
                        <button
                          onClick={this.onCheckout}
                          disabled={isLoading_checkout}
                          className="btn btn-block p-10 btn-main-pink fs-18 fw-500">
                          {isLoading_checkout ? <MDSpinner /> : 'Place order'}
                        </button>
                      )}
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {
  userGetPaymentMethodsList,
  userCreateNewBookPayment,
})(CheckoutBooking);
