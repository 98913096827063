import { API } from '../../utils/api';
import { SET_CURRENT_USER, SET_DATA, SET_TOKEN } from './types';

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

export function setData(data) {
  return {
    type: SET_DATA,
    data,
  };
}

export function setToken(token) {
  return {
    type: SET_TOKEN,
    token,
  };
}

export function userLogin(data) {
  return (dispatch) => {
    return API.post('/auth/customer-login', data).then((res) => {
      if (res.data.status === 200) {
        const { token, user } = res.data.message;
        localStorage.setItem('token', token.key);
        localStorage.setItem('exp', token.exp);
        localStorage.setItem('user', JSON.stringify(user));
        API.defaults.headers.common['Authorization'] = token.key;
        dispatch(setCurrentUser(user));
      }
      return res.data;
    });
  };
}

export function userActivateAccount(data) {
  return (dispatch) => {
    return (
      API.post('/auth/activate', data)
        // return API.post('/auth/create-password', data )
        .then((res) => {
          return res.data;
        })
    );
  };
}

export function userForgotPassword(data) {
  return (dispatch) => {
    return API.post(`/auth/forgot-${data.type}`, data).then((res) => {
      return res.data;
    });
  };
}

export function userResetToken(data) {
  return (dispatch) => {
    return API.post(`/auth/reset-token-${data.type}`, data).then((res) => {
      return res.data;
    });
  };
}

export function userResetPassword(data) {
  return (dispatch) => {
    return API.post(`/auth/reset-${data.type}`, data).then((res) => {
      return res.data;
    });
  };
}

export function userSetNewPassword(data) {
  return (dispatch) => {
    return API.post(`/auth/set-password-${data.type}`, data).then((res) => {
      return res.data;
    });
  };
}

export function userLogout(data) {
  return (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('exp');
    localStorage.removeItem('user');
    localStorage.removeItem('state');
    dispatch(setCurrentUser({}));
  };
}

export function cleanCache() {
  return (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('exp');
    localStorage.removeItem('user');
    localStorage.removeItem('state');
  };
}
