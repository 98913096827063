import React, { Component } from 'react';
import moment from 'moment';
import TextFieldDetailsGroup from '../../../common/TextFieldDetailsGroup';
import LoadingGift from '../../../common/LoadingGift';
import Notification from '../../../common/Notification';

class TransactionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoading_cancel: false,
      notification: {},
      errors: {},
    };
  }

  render() {
    const { isLoading, notification } = this.state;

    const {
      order_id,
      // payment_id,
      payment_method,
      payment_status,
      description,
      // amount,
      settlementAmount,
      created_date,
      paid_date,
    } = this.props.data;

    return (
      <div>
        <div
          className="modal modal-center fade show"
          id="modal-center"
          style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex align-items-center">
                  <h4 className="mb-0 font-weight-bold">
                    {this.props.modal_title} Order Details
                  </h4>
                  <div
                    id="muted-text-group"
                    style={{ right: 34 }}
                    className="position-absolute d-flex align-items-center justify-content-center cursor-pointer font-weight-bold btn-transparent border-0 p-0">
                    <h4 className="mb-0 mr-2">Order ID :</h4>
                    <h4 className="mb-0">{order_id}</h4>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                {isLoading ? (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ minHeight: 400 }}>
                    <LoadingGift />
                    <p className="mb-0 fs-14 text-center letter-spacing-0">
                      Collecting the order details data
                    </p>
                  </div>
                ) : (
                  <div className="">
                    <Notification notification={notification} />
                    <div className="">
                      <div className="row">
                        {/* <div className='col-md-6'>
													<TextFieldDetailsGroup
														label='Payment ID'
														value={payment_id ? payment_id : '-'}
													/>
												</div> */}
                        <div className="col-md-6">
                          <TextFieldDetailsGroup
                            label="Payment Status"
                            addStyle={{
                              textTransform: 'Capitalize',
                              color:
                                payment_status && payment_status === 'open'
                                  ? '#d9bc00'
                                  : payment_status && payment_status === 'paid'
                                  ? '#15db1f'
                                  : payment_status &&
                                    payment_status === 'canceled'
                                  ? 'red'
                                  : 'gray',
                            }}
                            value={payment_status ? payment_status : '-'}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextFieldDetailsGroup
                            label="Payment Method"
                            addStyle={{
                              textTransform: 'Capitalize',
                            }}
                            value={payment_method ? payment_method : '-'}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextFieldDetailsGroup
                            label="Order Date"
                            addStyle={{
                              textTransform: 'Capitalize',
                            }}
                            value={
                              created_date
                                ? moment(
                                    created_date,
                                    'MM/DD/YYYY, HH:mm:ss',
                                  ).format('MMM DD, YYYY - HH:mm:ss')
                                : '-'
                            }
                          />
                        </div>
                        {payment_status === 'paid' ? (
                          <div className="col-md-6">
                            <TextFieldDetailsGroup
                              label="Paid Date"
                              addStyle={{
                                textTransform: 'Capitalize',
                              }}
                              value={
                                paid_date
                                  ? moment(
                                      paid_date,
                                      'MM/DD/YYYY, HH:mm:ss',
                                    ).format('MMM DD, YYYY - HH:mm:ss')
                                  : '-'
                              }
                            />
                          </div>
                        ) : payment_status === 'canceled' ? (
                          <div className="col-md-6">
                            <TextFieldDetailsGroup
                              label="Canceled Date"
                              addStyle={{
                                textTransform: 'Capitalize',
                              }}
                              value={
                                paid_date
                                  ? moment(
                                      paid_date,
                                      'MM/DD/YYYY, HH:mm:ss',
                                    ).format('MMM DD, YYYY - HH:mm:ss')
                                  : '-'
                              }
                            />
                          </div>
                        ) : null}
                        {/* <div className='col-md-6'>
													<TextFieldDetailsGroup
														label='Amount'
														currency='&euro;'
														addStyle={{
															textTransform: 'Capitalize',
														}}
														value={amount ? `${amount.value}` : '-'}
													/>
												</div> */}
                        {payment_status === 'paid' ? (
                          <div className="col-md-6">
                            <TextFieldDetailsGroup
                              label="Settlement Amount"
                              currency="&euro;"
                              addStyle={{
                                textTransform: 'Capitalize',
                              }}
                              value={
                                settlementAmount
                                  ? `${settlementAmount.value}`
                                  : '-'
                              }
                            />
                          </div>
                        ) : null}
                        <div className="col-md-6">
                          <TextFieldDetailsGroup
                            label="Package"
                            addStyle={{
                              textTransform: 'Capitalize',
                            }}
                            value={description ? description : '-'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  className="btn btn-main-pink"
                  onClick={this.props.onClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    );
  }
}

export default TransactionDetails;
