import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { session_type_options } from '../../../common/SelectOptionsData';
import DateSelectGroup from '../../../common/DateSelectGroup';
import SelectFieldGroup from '../../../common/SelectFieldGroup';
import { connect } from 'react-redux';
import Notification from '../../../common/Notification';

class RebookTutorModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			notification: false,
			errors: {},
			person: {
				label: '1 person',
				value: 1,
				minus_percentage: 0,
			},
			tutor_data: this.props.appointment_data.tutor_id,
			student_data: this.props.appointment_data.student_id,
			appointment_date: null,
			session_type: this.props.appointment_data.session_type,
			session_options: [],
			session_duration: this.props.appointment_data.session_duration,
		};
	}

	componentWillMount = () => {
		const { tutor_id } = this.props.appointment_data;
		const session_options = [];

		const live_session_available = tutor_id.session_type.find((item) => item.value === 'live');

		if (live_session_available) {
			session_options.push({
				value: 'live',
				label: 'Live session',
			});
		}

		session_type_options.map((type) => {
			let result = tutor_id.session_type.map((session) => {
				const session_exist = session_options.find((item) => item.value === session.value);
				if (session.value === type.value && !session_exist) {
					session_options.push(type);
					return session;
				}
				return session;
			});
			return result;
		});

		let session_duration;

		if (session_options[1].value === this.state.session_type.value) {
			session_duration = {
				label: '1 hour',
				value: 60,
				price: 1,
			};
		} else {
			session_duration = {
				label: '1.5 hours',
				value: 90,
				price: 1.5,
			};
		}

		this.setState({
			session_duration,
			session_options,
		});
	};

	onChangeDateSelect = (value) => {
		this.setState({
			appointment_date: value,
			errors: {
				appointment_date: null,
			},
		});
	};

	onChangeSelect = (e) => {
		if (e.target.name === 'session_type') {
			let default_duration;
			if (e.target.value.value === 'live') {
				default_duration = {
					label: '1.5 hours',
					value: 90,
					price: 1.5,
				};
			} else {
				default_duration = {
					label: '1 hour',
					value: 60,
					price: 1,
				};
			}
			this.setState({
				session_type: e.target.value,
				session_duration: default_duration,
			});
		} else {
			this.setState({
				[e.target.name]: e.target.value,
			});
		}
	};

	onClickBookNow = (price) => {
		this.setState({ errors: {}, notification: {} });
		const { appointment_date, session_type, session_duration, person, student_data, tutor_data } = this.state;

		if (!isEmpty(appointment_date) || appointment_date instanceof Date) {
			const appointment_data = {
				person,
				session_type,
				session_duration,
				book_price: price,
				appointment_date: moment(appointment_date).format('MM/DD/YYYY'),
			};

			const name = tutor_data.display_name
				? tutor_data.display_name.toLowerCase()
				: `${tutor_data.first_name.toLowerCase()} ${tutor_data.last_name.toLowerCase()}`;
			const params = name.replace(/\s/g, '-');

			this.props.history.push({
				// pathname: `/book-language-course/booking-overview`,
				pathname: `/book-language-course/tutor-${params}/booking-overview`,
				state: {
					student_data,
					appointment_data,
					tutor_data,
				},
			});
		} else {
			this.setState({
				errors: {
					appointment_date: 'Please select the date',
				},
				notification: {
					text: 'Please complete the form above.',
					isError: true,
				},
			});
		}
	};

	render() {
		const { closeModal, isMobileScreen } = this.props;

		const {
			isLoading,
			errors,
			notification,
			appointment_date,
			person,
			tutor_data,
			student_data,
			session_type,
			session_options,
			session_duration,
		} = this.state;

		let price = session_duration.price;
		let disc_price = 0;

		if (person.value === 2) {
			disc_price = (price * person.minus_percentage) / 100;
		}

		let book_price = price * person.value - disc_price;

		let session_duration_options = [];

		if (session_type.value === 'live') {
			session_duration_options = [
				{ value: 90, label: '1.5 hours', price: 1.5 },
				{ value: 120, label: '2 hours', price: 2 },
				{ value: 180, label: '3 hours', price: 3 },
			];
		} else {
			session_duration_options = [
				{ value: 60, label: '1 hour', price: 1 },
				{ value: 90, label: '1.5 hours', price: 1.5 },
				{ value: 120, label: '2 hours', price: 2 },
				{ value: 180, label: '3 hours', price: 3 },
			];
		}

		const person_options = [
			// minus price in %
			{ value: 1, label: '1 person', minus_percentage: 0 },
			{ value: 2, label: '2 persons', minus_percentage: 50 },
		];

		const todayDate = moment(new Date());
		let creditExpiryDate = '';
		let isCreditExpired = false;

		if (student_data.credit_expired && !isEmpty(student_data.credit_expired)) {
			creditExpiryDate = moment(student_data.credit_expired, 'MM/DD/YYYY');
			isCreditExpired = moment(todayDate).isAfter(creditExpiryDate);
		}

		let mobileStyle = {};
		if (isMobileScreen) {
			mobileStyle = {
				overflowY: 'scroll',
				maxHeight: '80vh',
			};
		}

		return (
			<div>
				<div
					className='modal modal-center fade show'
					id='modal-center'
					style={{ display: 'block' }}
				>
					<div className='modal-dialog'>
						<div
							className='modal-content'
							style={mobileStyle}
						>
							<div className='text-right px-30 pt-20'>
								<i
									onClick={closeModal}
									className='ion-close-round cursor-pointer'
								></i>
							</div>
							<div className='modal-body m-0 p-30'>
								<h1 className='mb-0 fs-20 font-weight-bold text-center'>Re-book this tutor</h1>
								<hr className='my-20' />
								<DateSelectGroup
									minDate={moment().add(1, 'day').endOf('day').toDate()}
									name='appointment_date'
									label='Session date'
									value={appointment_date}
									dateAvailableArray={tutor_data.day_available}
									onChange={this.onChangeDateSelect}
									isDisabled={isLoading}
									error={errors.appointment_date}
								/>
								<SelectFieldGroup
									label='Session type'
									value={session_type}
									options={session_options}
									onChange={(val) =>
										this.onChangeSelect({
											target: { name: 'session_type', value: val },
										})
									}
									isClearable={false}
									isDisabled={isLoading}
									isSearchable={false}
									labelWeightNormal={false}
								/>
								<SelectFieldGroup
									label='Session duration'
									value={session_duration}
									options={session_duration_options}
									onChange={(val) =>
										this.onChangeSelect({
											target: { name: 'session_duration', value: val },
										})
									}
									isClearable={false}
									isDisabled={isLoading}
									isSearchable={false}
									labelWeightNormal={false}
								/>
								<SelectFieldGroup
									label='How many persons'
									value={person}
									options={person_options}
									onChange={(val) =>
										this.onChangeSelect({
											target: { name: 'person', value: val },
										})
									}
									isClearable={false}
									isDisabled={isLoading}
									isSearchable={false}
									labelWeightNormal={false}
								/>
								<div className='d-flex align-items-center justify-content-between mt-25'>
									<div className='d-flex align-items-center'>
										<img
											src='/assets/img/icon/flowently-credit.svg'
											alt=''
										/>
										<p className='mb-0 font-weight-bold ml-2'>Flowently credit</p>
									</div>
									<p className='mb-0 font-weight-bold fs-16'>{book_price}</p>
								</div>
								<hr className='my-20' />
								<Notification notification={notification} />
								<div>
									<div>
										{isCreditExpired ? (
											<p className='mb-0 fs-14 text-center text-danger'>
												Your credit has expired.
												<br />
												You can't book tutor with your expired credits. You need to top up your
												credit balance to book this tutor.
											</p>
										) : (
											<p className='mb-0 fs-14 text-center'>
												You have
												<span className='fs-14 text-main-pink mx-2'>
													{!isEmpty(student_data)
														? `${student_data.credit_balance.toFixed(2)} flowently credits`
														: '0 flowently credit'}{' '}
												</span>
												left on your account.
												{student_data && student_data.credit_balance.toFixed(2) < book_price
													? 'You need to top up your credit balance to book this tutor.'
													: null}
											</p>
										)}
										<div className='mt-20 text-center'>
											{student_data.credit_balance.toFixed(2) < book_price || isCreditExpired ? (
												<button
													onClick={() => this.props.history.push('/packages')}
													className='btn btn-main-pink py-10'
													style={{ width: '100%' }}
												>
													Buy package
												</button>
											) : (
												<button
													onClick={() => this.onClickBookNow(book_price)}
													className='btn btn-main-pink py-10'
													style={{ width: '100%' }}
												>
													Book now
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='modal-backdrop fade show'></div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps, {})(RebookTutorModal);
