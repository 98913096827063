import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { DOMAIN } from '../../../utils/api';
import MDSpinner from 'react-md-spinner';
import queryString from 'query-string';
import YouTube from 'react-youtube';
import Rating from 'react-rating';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import LoginModal from './LoginModal';
import LoadingGift from '../../common/LoadingGift';
import Notification from '../../common/Notification';
import DateSelectGroup from '../../common/DateSelectGroup';
import SelectFieldGroup from '../../common/SelectFieldGroup';
import { session_type_options } from '../../common/SelectOptionsData';

import { connect } from 'react-redux';
import { userGetTutorDetailByName } from '../../../redux/actions/tutorActions';
import { userGetSingleProfile } from '../../../redux/actions/studentActions';
import { userGetTrialPackages } from '../../../redux/actions/packagesActions';
import { setSelectedTutor } from '../../../redux/actions/bookActions';

class TutorDetails extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			isLoading_student: false,
			notification: {},
			notification_student: {},
			errors: {},
			showCreditsInfo: false,
			isMobileScreen: false,
			youtubeID: '',
			tutor_data: {},
			student_data: {},
			session_options: [],
			isOpenLoginModal: false,
			default_avatar: '/assets/img/icon/male.svg',
			// Reviews List
			isLoading_reviews: false,
			notification_reviews: {},
			reviews_data: [],
			reviews_visible: 3,
			// Book Data
			appointment_date: moment().add(1, 'day').endOf('day').toDate(),
			session_type: {
				label: 'Online Session',
				value: 'skype',
			},
			person: {
				label: '1 person',
				value: 1,
				minus_percentage: 0,
			},
			session_duration: {
				label: '1 hour',
				value: 60,
				price: 1,
			},
			book_price: 1,
			online_trial_package: {},
			live_trial_package: {},
			selected_trial_package: {},
			uspData: {},
		};
	}

	componentWillMount = () => {
		if (this.props.location.state) {
			window.scrollTo(0, 0);

			const { data } = this.props.location.state;

			const history_appointment = [];
			const today = moment(new Date()).format('MM/DD/YYYY');

			if (data.appointments.length > 0) {
				data.appointments.map((data) => {
					const appointmentDate = data.appointment_date;
					const history = moment(appointmentDate).isBefore(today);
					if (history || data.isCanceled) {
						history_appointment.push(data);
					}

					return data;
				});
			}

			const query = queryString.parse(data.youtube_link);
			let youtubeID = query[Object.keys(query)[0]];

			if (!youtubeID) {
				const splitURL = data.youtube_link.split('/');
				youtubeID = splitURL[splitURL.length - 1];
			}

			const session_options = [];

			const live_session_available = data.session_type.find((item) => item.value === 'live');

			if (live_session_available) {
				session_options.push({
					value: 'live',
					label: 'Live session',
				});
			}

			session_type_options.map((type) => {
				let result = data.session_type.map((session) => {
					const session_exist = session_options.find((item) => item.value === session.value);
					if (session.value === type.value && !session_exist) {
						session_options.push(type);
						return session;
					}
					return session;
				});
				return result;
			});

			let session_duration;

			if (session_options[0].value === 'skype') {
				session_duration = {
					label: '1 hour',
					value: 60,
					price: 1,
				};
				this.getTrialPackages('skype');
			} else {
				session_duration = {
					label: '1.5 hours',
					value: 90,
					price: 1.5,
				};
				this.getTrialPackages('live');
			}

			if (this.props.auth.isAuthenticated) {
				this.getStudentData();
				this.setState({
					session_type: session_options[0],
					reviews_data: data.reviews,
					tutor_data: {
						...data,
						total_session:
							history_appointment.length === 0 && data.reviews.length > 0
								? data.reviews.length
								: history_appointment.length,
					},
					youtubeID,
					session_options,
					session_duration,
				});
			} else {
				this.setState({
					session_type: session_options[0],
					reviews_data: data.reviews,
					tutor_data: {
						...data,
						total_session:
							history_appointment.length === 0 && data.reviews.length > 0
								? data.reviews.length
								: history_appointment.length,
					},
					youtubeID,
					session_options,
					session_duration,
				});
			}
		} else {
			this._isMounted = true;
			const getDisplayName = this.props.match.params.name.split('-');
			if (getDisplayName[0] === 'tutor') {
				getDisplayName.splice(getDisplayName[0], 1);
			}
			const display_name = getDisplayName.join(' ');
			this.getTutorDetailByName(display_name);
		}
	};

	componentWillUnmount = () => {
		this._isMounted = false;
	};

	getUSP = async () => {
		let apiUrl = `${DOMAIN}/wp-json/wp/v2/layout/42777`;

		const res = await axios.get(apiUrl);

		if (res.status === 200) {
			const usps = res.data.acf.usp.map((item) => item.usps);
			const uspData = {
				id: res.data.id,
				title: 'Why study with a qualified Flowently tutor?',
				usps,
			};

			this.setState({ uspData });
		}
	};

	getTrialPackages = (default_selected) => {
		this.props
			.userGetTrialPackages()
			.then((res) => {
				if (res.status === 200) {
					const { data } = res.message;

					if (data) {
						let live_trial_package = {};
						let online_trial_package = {};

						data.map((item) => {
							if (item.for_session === 'live') live_trial_package = item;
							if (item.for_session === 'online') online_trial_package = item;
							return item;
						});

						this.setState({
							live_trial_package,
							online_trial_package,
							selected_trial_package:
								default_selected === 'live' ? live_trial_package : online_trial_package,
						});
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	getTutorDetailByName = (display_name) => {
		this.setState({ isLoading: true, notification: {}, errors: {} });
		this.props
			.userGetTutorDetailByName({ display_name })
			.then((res) => {
				if (res.status === 200) {
					window.scrollTo(0, 0);

					const { data } = res.message;

					this.props.setSelectedTutor(data);

					const history_appointment = [];
					const today = moment(new Date()).format('MM/DD/YYYY');

					if (data.appointments.length > 0) {
						data.appointments.map((data) => {
							const appointmentDate = data.appointment_date;
							const history = moment(appointmentDate).isBefore(today);

							if (history || data.isCanceled) {
								history_appointment.push(data);
							}

							return data;
						});
					}

					const query = queryString.parse(data.youtube_link);
					let youtubeID = query[Object.keys(query)[0]];

					if (!youtubeID) {
						const splitURL = data.youtube_link.split('/');
						youtubeID = splitURL[splitURL.length - 1];
					}

					const session_options = [];

					const live_session_available = data.session_type.find((item) => item.value === 'live');

					if (live_session_available) {
						session_options.push({
							value: 'live',
							label: 'Live session',
						});
					}

					session_type_options.map((type) => {
						let result = data.session_type.map((session) => {
							const session_exist = session_options.find((item) => item.value === session.value);
							if (session.value === type.value && !session_exist) {
								session_options.push(type);
								return session;
							}
							return session;
						});
						return result;
					});

					let session_duration;

					if (session_options[0].value === 'live') {
						session_duration = {
							label: '1.5 hours',
							value: 90,
							price: 1.5,
						};
						this.getTrialPackages('live');
					} else {
						session_duration = {
							label: '1 hour',
							value: 60,
							price: 1,
						};
						this.getTrialPackages('skype');
					}

					if (this.props.auth.isAuthenticated) {
						this.getStudentData();
						if (this._isMounted) {
							this.setState({
								isLoading: false,
								session_type: session_options[0],
								reviews_data: data.reviews,
								tutor_data: {
									...data,
									total_session:
										history_appointment.length === 0 && data.reviews.length > 0
											? data.reviews.length
											: history_appointment.length,
								},
								youtubeID,
								session_options,
								session_duration,
							});
						}
					} else {
						if (this._isMounted) {
							this.setState({
								isLoading: false,
								session_type: session_options[0],
								reviews_data: data.reviews,
								tutor_data: {
									...data,
									total_session:
										history_appointment.length === 0 && data.reviews.length > 0
											? data.reviews.length
											: history_appointment.length,
								},
								youtubeID,
								session_options,
								session_duration,
							});
						}
					}
				} else {
					this.setState({
						isLoading: false,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	getStudentData = () => {
		this.setState({ isLoading_student: true, notification_student: {} });
		this.props
			.userGetSingleProfile({ email: this.props.auth.user.email })
			.then((res) => {
				if (res.status === 200) {
					this.setState({
						isLoading_student: false,
						student_data: res.message.data,
					});
				} else {
					this.setState({
						isLoading_student: false,
						notification_student: {
							text: 'Internal server error. Please try again later',
							isError: true,
						},
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					isLoading_student: false,
					notification_student: {
						text: 'We are experiencing technical difficulties at the moment, please try again later',
						isError: true,
					},
				});
			});
	};

	screenSize = () => {
		this.setState({ isMobileScreen: window.innerWidth <= 900 });
	};

	componentDidMount = () => {
		this.getUSP();
		window.addEventListener('screenSize', this.screenSize());
	};

	onYoutubeReady = (e) => {
		e.target.stopVideo();
	};

	onChange = (e) => {
		if (e.target.name === 'skype_id') {
			this.setState({
				student_data: {
					...this.state.student_data,
					skype_id: e.target.value,
				},
				errors: {
					...this.state.errors,
					skype_id: null,
				},
			});
		} else {
			this.setState({
				[e.target.name]: e.target.value,
			});
		}
	};

	onChangeDateSelect = (date) => {
		this.setState({
			appointment_date: date,
		});
	};

	onChangeSelect = (e) => {
		if (e.target.name === 'session_type') {
			let default_duration;
			let selected_trial_package = {};
			if (e.target.value.value === 'live') {
				default_duration = {
					label: '1.5 hours',
					value: 90,
					price: 1.5,
				};
				selected_trial_package = this.state.live_trial_package;
			} else {
				default_duration = {
					label: '1 hour',
					value: 60,
					price: 1,
				};
				selected_trial_package = this.state.online_trial_package;
			}
			this.setState({
				session_type: e.target.value,
				session_duration: default_duration,
				selected_trial_package,
			});
		} else {
			this.setState({
				[e.target.name]: e.target.value,
			});
		}
	};

	loadMoreReviewsHandle = () => {
		this.setState((prev) => {
			return {
				reviews_visible: prev.reviews_visible + 3,
			};
		});
	};

	onClickBookNow = (book_price) => {
		const { appointment_date, session_type, session_duration, student_data, person, tutor_data } = this.state;

		const appointment_data = {
			person,
			session_type,
			session_duration,
			book_price,
			appointment_date: moment(appointment_date).format('MM/DD/YYYY'),
		};

		const name = tutor_data.display_name
			? tutor_data.display_name.toLowerCase()
			: `${tutor_data.first_name.toLowerCase()} ${tutor_data.last_name.toLowerCase()}`;
		const params = name.replace(/\s/g, '-');

		this.props.history.push({
			pathname: `/book-language-course/tutor-${params}/booking-overview`,
			state: {
				student_data,
				appointment_data,
				tutor_data,
			},
		});
	};

	openLoginModalHandler = () => {
		document.body.style.overflow = 'hidden';
		this.setState({ isOpenLoginModal: true });
	};

	closeModal = () => {
		document.body.style.overflow = '';
		this.setState({ isOpenLoginModal: false });
	};

	render() {
		const {
			youtubeID,
			isMobileScreen,
			appointment_date,
			session_type,
			person,
			session_duration,
			session_options,
			tutor_data,
			student_data,
			isLoading,
			isLoading_student,
			notification_student,
			reviews_data,
			reviews_visible,
			isOpenLoginModal,
			default_avatar,
			showCreditsInfo,
			uspData,
		} = this.state;

		const { auth: isAuthenticated } = this.props;

		let price = session_duration.price;
		let disc_price = 0;

		if (person.value === 2) {
			disc_price = (price * person.minus_percentage) / 100;
		}

		let book_price = price * person.value - disc_price;

		let session_duration_options = [];

		if (session_type.value === 'live') {
			session_duration_options = [
				{ value: 90, label: '1.5 hours', price: 1.5 },
				{ value: 120, label: '2 hours', price: 2 },
				{ value: 180, label: '3 hours', price: 3 },
			];
		}

		if (session_type.value === 'skype') {
			session_duration_options = [
				{ value: 60, label: '1 hour', price: 1 },
				{ value: 90, label: '1.5 hours', price: 1.5 },
				{ value: 120, label: '2 hours', price: 2 },
				{ value: 180, label: '3 hours', price: 3 },
			];
		}

		if (session_type.value === 'children') {
			session_duration_options = [
				{ value: 30, label: '30 minutes', price: 0.5 },
				{ value: 60, label: '1 hour', price: 1 },
				{ value: 90, label: '1.5 hours', price: 1.5 },
				{ value: 120, label: '2 hours', price: 2 },
				{ value: 180, label: '3 hours', price: 3 },
			];
		}

		const person_options = [
			// minus price in %
			{ value: 1, label: '1 person', minus_percentage: 0 },
			{ value: 2, label: '2 persons', minus_percentage: 50 },
		];

		const youtubeComponentOpts = {
			height: isMobileScreen ? 'auto' : '400px',
			width: isMobileScreen ? 'auto' : '100%',
			playerVars: {
				// https://developers.google.com/youtube/player_parameters
				autoplay: 0,
			},
		};

		let isCreditEnough = false;

		if (isAuthenticated && student_data) {
			isCreditEnough = student_data.credit_balance >= book_price;
		}

		let USP_render = null;
		if (uspData.usps && uspData.usps.length > 0) {
			USP_render = (
				<div className='mt-5'>
					<h1 className='font-weight-bold fs-25 mb-0'>{uspData.title}</h1>
					{uspData.usps.map((item, idx) => (
						<div
							key={`usp-point-${idx}`}
							className='d-flex align-items-center mt-3'
						>
							<i className='fa fa-check-circle fs-20 text-main-blue'></i>
							<p className='mb-0 fs-16 ml-2'>{item}</p>
						</div>
					))}
				</div>
			);
		}

		if (isLoading) {
			return (
				<div className='col-md-12 d-flex align-items-center justify-content-center my-20'>
					<LoadingGift />
				</div>
			);
		} else if (isEmpty(tutor_data?._id) || tutor_data?.teach_language?.length === 0) {
			return (
				<div className={isMobileScreen ? 'row m-auto flex-column' : 'row m-auto'}>
					<div className='col-md-12 mb-20'>
						<button
							className='btn button-transparent border-0 fs-14 d-flex flex-start align-items-center cursor-pointer no-padding'
							onClick={() => this.props.history.push('/')}
						>
							<i className='ion-arrow-left-c fs-20 mr-1' />
							Back
						</button>
						<h1 className='mt-20'>Tutor not found</h1>
					</div>
				</div>
			);
		}

		const render_teach_language = tutor_data.teach_language.map((language, idx) => {
			const lastIdx = tutor_data.teach_language.length - 1;
			if (idx === lastIdx) {
				return (
					<Link
						key={`tutor-label-${idx}`}
						to={`learn-${language.value}`}
					>
						{language.label}
					</Link>
				);
			}
			return (
				<>
					<Link
						key={`tutor-label-${idx}`}
						to={`learn-${language.value}`}
					>
						{language.label}
					</Link>
					{', '}
				</>
			);
		});

		const render_bridge_language = tutor_data.bridge_language.map((language, idx) => {
			const lastIdx = tutor_data.bridge_language.length - 1;
			if (idx === lastIdx) return language.label;
			return `${language.label}, `;
		});

		const render_topics = tutor_data.topics.map((topic, idx) => {
			return (
				<h6
					className='fs-14 mb-1 fw-500'
					key={`topics-${tutor_data._id}-${idx}`}
				>
					<div className='d-flex align-items-center'>
						<i
							className='fa fa-circle mr-2'
							style={{ fontSize: 6 }}
						/>{' '}
						{topic.label}
					</div>
				</h6>
			);
		});

		const render_available_session = tutor_data.time_available.map((time, idx) => {
			const lastIdx = tutor_data.time_available.length - 1;
			if (idx === lastIdx) return time;
			return `${time}, `;
		});

		const render_session_type = tutor_data.session_type.map((type, idx) => {
			const lastIdx = tutor_data.session_type.length - 1;
			if (idx === lastIdx)
				return type.value === 'skype' ? 'Online' : type.value === 'children' ? 'Kids' : type.value;
			return `${type.value === 'skype' ? 'Online' : type.value === 'children' ? 'Kids' : type.value}, `;
		});

		const render_city = tutor_data.city.map((city, idx) => {
			const lastIdx = tutor_data.city.length - 1;
			if (idx === lastIdx) return city.label;
			return `${city.label}, `;
		});

		const reviews_render = reviews_data.slice(0, reviews_visible).map((data, idx) => {
			return (
				<div
					className='card'
					key={`review-no-${idx}`}
				>
					<div className={isMobileScreen ? 'card-body p-20' : 'card-body px-50 py-20'}>
						<Rating
							emptySymbol='fa fa-star-o fs-12 star-rating'
							fullSymbol='fa fa-star fs-12 star-rating'
							initialRating={data.rating}
							readonly
						/>
						{!isEmpty(data.review) ? (
							<p className='mb-0 fs-14 font-weight-bold'>{data.review.title}</p>
						) : null}
						{!isEmpty(data.review) ? (
							<p className='mb-0 fs-12 font-weight-normal'>{data.review.description}</p>
						) : null}
						<div className='d-flex justify-content-between align-items-center'>
							<p
								className='mb-0 fs-10'
								style={{ color: 'rgba(0, 0, 0, 0.5)' }}
							>
								{moment(data.review_date, 'MM/DD/YYYY').format('MMM Do, YYYY')}
							</p>
							<div className='d-flex align-items-center'>
								<p className='mb-0 font-weight-bold fs-12 mr-2'>
									{data.author && data.author.first_name ? data.author.first_name : ''}
									{data.author && data.author.last_name ? data.author.last_name : ''}
								</p>
								<img
									alt=''
									style={{
										width: '30px',
										height: '30px',
										borderRadius: '50%',
										boxShadow: '0px 2px 15px #e7e7e7',
										maxWidth: '35px',
									}}
									src={
										!isEmpty(data.author) && data.author.avatar_display
											? data.author.avatar_display
											: default_avatar
									}
								/>
							</div>
						</div>
					</div>
				</div>
			);
		});

		const tutorAvatar = tutor_data.avatar_display ? tutor_data.avatar_display : default_avatar;

		const todayDate = new Date();
		let creditExpiryDate = '';
		let isCreditExpired = false;

		if (student_data.credit_expired && !isEmpty(student_data.credit_expired)) {
			creditExpiryDate = moment(student_data.credit_expired, 'MM/DD/YYYY');
			isCreditExpired = moment(todayDate).isAfter(creditExpiryDate);
		}

		return (
			<>
				<Helmet>
					<title>
						Tutor{' '}
						{tutor_data.display_name
							? tutor_data.display_name
							: `${tutor_data.first_name} ${tutor_data.last_name}`}
					</title>
					<meta
						name='description'
						content={tutor_data.introduction}
					/>
					<meta
						name='robots'
						content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
					/>
				</Helmet>
				<div className={isMobileScreen ? 'row m-auto flex-column' : 'row m-auto'}>
					<div className='col-md-12 mb-20'>
						<button
							className='btn button-transparent border-0 fs-14 d-flex flex-start align-items-center cursor-pointer no-padding'
							onClick={() => this.props.history.goBack()}
						>
							<i className='ion-arrow-left-c fs-20 mr-1' />
							Back
						</button>
					</div>
					{isLoading ? (
						<div className='col-md-12 d-flex align-items-center justify-content-center my-20'>
							<LoadingGift />
						</div>
					) : null}
					<div className={isMobileScreen ? 'col-12' : 'col-md-8'}>
						<div className={isEmpty(youtubeID) ? 'youtube-iframe m-0' : 'youtube-iframe mb-20'}>
							{isMobileScreen && isEmpty(youtubeID) ? null : isEmpty(youtubeID) ? null : (
								<YouTube
									videoId={youtubeID}
									opts={youtubeComponentOpts}
									onReady={this.onYoutubeReady}
								/>
							)}
						</div>
						<div className='card'>
							<div className='card-body p-20'>
								<div className='row m-auto'>
									<div className={isMobileScreen ? 'col-12' : 'col-md-9'}>
										<div className='row align-items-center m-auto'>
											<div
												className={
													isMobileScreen
														? 'col-12 mx-auto'
														: 'col-md-5 d-flex align-items-center justify-content-center'
												}
											>
												<div
													style={{
														backgroundImage: `url("${tutorAvatar}")`,
														backgroundPosition: 'center',
														backgroundSize: 'cover',
														backgroundRepeat: 'no-repeat',
														borderRadius: '50%',
														boxShadow: '0px 2px 15px #e7e7e7',
														margin: '0 auto',
														height: isMobileScreen ? 160 : 200,
														width: isMobileScreen ? 160 : 200,
													}}
												></div>
											</div>
											<div className={isMobileScreen ? 'col-12 px-0' : 'col-md-7'}>
												<div className='ml-0'>
													<p
														className={
															isMobileScreen
																? 'fs-18 letter-spacing-0 mt-20 mb-0 font-weight-bold text-capitalize'
																: 'fs-18 letter-spacing-0 mb-0 font-weight-bold text-capitalize'
														}
													>
														{tutor_data.display_name
															? tutor_data.display_name
															: `${tutor_data.first_name} ${tutor_data.last_name}`}
													</p>
													<hr
														className='mt-0 mb-1 mx-0'
														style={{
															borderTop: '2px solid var(--mainPink)',
															width: '30px',
														}}
													/>
													{isMobileScreen ? (
														<div className='d-flex align-items-center'>
															<Rating
																emptySymbol='fa fa-star-o fs-12 star-rating'
																fullSymbol='fa fa-star fs-12 star-rating'
																initialRating={tutor_data.total_rating}
																readonly
															/>
															<span className='fs-12 ml-2'>
																{parseInt(tutor_data.total_rating) === 0
																	? '( 0 rating )'
																	: `( ${tutor_data.total_rating} ratings )`}
															</span>
														</div>
													) : null}
													<p className='mb-0 fs-12'>
														{tutor_data.total_session}{' '}
														{tutor_data.total_session > 1 ? 'Sessions' : 'Session'}
													</p>
													<p className='mb-0 fs-12'>
														Living in{' '}
														{tutor_data.living_in_country && tutor_data.living_in_city
															? `${tutor_data.living_in_city.label}, ${tutor_data.living_in_country.label}`
															: tutor_data.country.length > 0 &&
															  tutor_data.city.length > 0
															? `${tutor_data.city[0].label}, ${tutor_data.city[0].country}`
															: '-'}
													</p>
												</div>
											</div>
										</div>
									</div>
									{isMobileScreen ? null : (
										<div className='col-md-3 d-flex justify-content-end'>
											<div className='text-right'>
												<Rating
													emptySymbol='fa fa-star-o fs-16 star-rating'
													fullSymbol='fa fa-star fs-16 star-rating'
													initialRating={tutor_data.total_rating}
													readonly
												/>
												<p className='mb-0 fs-12'>
													{tutor_data.reviews.length}{' '}
													{tutor_data.reviews.length > 1 ? 'Reviews' : 'Review'}
												</p>
											</div>
										</div>
									)}
									<div className='col-12'>
										<hr className='my-20' />
										<h3 className='fs-18 font-weight-bold'>About me</h3>
										<h6 className='fs-15 font-weight-bold mb-2'>
											Background : {tutor_data.background}
										</h6>
										<p className='fs-15'>{tutor_data.introduction}</p>
										<hr className='my-20' />
										<div className='row m-auto'>
											<div className={isMobileScreen ? 'col-12 px-0' : 'col-md-4'}>
												<p className='fs-12 mb-0 text-capitalize'>Teaches</p>
												<span className='fs-14 mb-15 fw-500 lists-teaches-lang'>
													{tutor_data.teach_language.length > 0 ? render_teach_language : '-'}
												</span>
											</div>
											<div className={isMobileScreen ? 'col-12 px-0' : 'col-md-4'}>
												<p className='fs-12 mb-0 text-capitalize'>Also speaks</p>
												<h6 className='fs-14 mb-15 fw-500'>
													{tutor_data.bridge_language.length > 0
														? render_bridge_language
														: '-'}
												</h6>
											</div>
											<div className={isMobileScreen ? 'col-12 px-0' : 'col-md-4'}>
												<p className='fs-12 mb-0 text-capitalize'>Session types</p>
												<h6 className='fs-14 mb-15 fw-500 text-capitalize'>
													{tutor_data.session_type.length > 0 ? render_session_type : '-'}
												</h6>
											</div>
										</div>
										<div className='row m-auto'>
											<div className={isMobileScreen ? 'col-12 px-0' : 'col-md-4'}>
												<p className='fs-12 mb-0 text-capitalize'>City</p>
												<h6 className='fs-14 mb-15 fw-500'>
													{!isEmpty(tutor_data.city) ? render_city : '-'}
												</h6>
											</div>
											<div className={isMobileScreen ? 'col-12 px-0' : 'col-md-4'}>
												<p className='fs-12 mb-0 text-capitalize'>Available sessions</p>
												<h6 className='fs-14 mb-15 fw-500 text-capitalize'>
													{tutor_data.time_available.length > 0
														? render_available_session
														: '-'}
												</h6>
											</div>
										</div>
										<div className='row m-auto'>
											<div className={isMobileScreen ? 'col-12 px-0' : 'col-md-12'}>
												<p className='fs-12 mb-1 text-capitalize'>Topics</p>
												{tutor_data.topics.length > 0 ? render_topics : '-'}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<h5 className='font-weight-bold fs-20 mb-20'>Reviews ({tutor_data.reviews.length})</h5>
						{reviews_render}
						{reviews_visible < reviews_data.length && (
							<div className={isMobileScreen ? 'text-center mb-30' : 'text-right'}>
								<button
									onClick={this.loadMoreReviewsHandle}
									className='btn button-transparent border-transparent text-dark fs-14 letter-spacing-0 p-0'
								>
									Load more reviews
								</button>
							</div>
						)}
					</div>
					<div className={isMobileScreen ? 'col-12' : 'col-md-4'}>
						<div className='card'>
							<div className={isMobileScreen ? 'card-body p-20' : 'card-body p-40'}>
								<h1 className='mb-0 fs-20 font-weight-bold text-center'>Book this Tutor</h1>
								<hr className='my-20' />
								<DateSelectGroup
									minDate={moment().add(1, 'day').endOf('day').toDate()}
									name='appointment_date'
									label='Session date'
									value={appointment_date}
									dateAvailableArray={tutor_data.day_available}
									onChange={this.onChangeDateSelect}
									isDisabled={isLoading}
								/>
								<SelectFieldGroup
									label='Session type'
									value={session_type}
									options={session_options}
									onChange={(val) =>
										this.onChangeSelect({
											target: {
												name: 'session_type',
												value: val,
											},
										})
									}
									isClearable={false}
									isDisabled={isLoading}
									isSearchable={false}
									labelWeightNormal={false}
								/>
								<SelectFieldGroup
									label='Session duration'
									value={session_duration}
									options={session_duration_options}
									onChange={(val) =>
										this.onChangeSelect({
											target: {
												name: 'session_duration',
												value: val,
											},
										})
									}
									isClearable={false}
									isDisabled={isLoading}
									isSearchable={false}
									labelWeightNormal={false}
								/>
								<SelectFieldGroup
									label='How many persons'
									value={person}
									options={person_options}
									onChange={(val) =>
										this.onChangeSelect({
											target: {
												name: 'person',
												value: val,
											},
										})
									}
									isClearable={false}
									isDisabled={isLoading}
									isSearchable={false}
									labelWeightNormal={false}
								/>
								<div className='d-flex align-items-center justify-content-between mt-25 position-relative'>
									<div className='d-flex align-items-center'>
										<img
											src='/assets/img/icon/flowently-credit.svg'
											alt=''
										/>
										<p className='mb-0 font-weight-bold ml-2'>Flowently credit</p>
									</div>
									<div className='d-flex align-items-center'>
										<p className='mb-0 font-weight-bold fs-16'>{book_price}</p>
										{!isCreditEnough ? (
											<img
												src='/assets/img/icon/notif.svg'
												alt='notif icon'
												onClick={() =>
													this.setState({
														showCreditsInfo: !this.state.showCreditsInfo,
													})
												}
												onMouseOver={() =>
													this.setState({
														showCreditsInfo: true,
													})
												}
												onMouseOut={() =>
													this.setState({
														showCreditsInfo: false,
													})
												}
												className='cursor-pointer ml-2'
											/>
										) : null}
									</div>
									<div
										style={{
											opacity: showCreditsInfo ? 1 : 0,
											width: showCreditsInfo ? '91%' : '85%',
											visibility: showCreditsInfo ? 'visible' : 'hidden',
										}}
										className='p-20 credits-info'
									>
										<p className='fs-12 text-black mb-0 px-10'>
											With Flowently credits you can book your session with a private tutor
											directly from your account.
										</p>
										<hr className='my-10' />
										<p className='fs-12 text-black mb-0 px-10'>
											You can choose to buy credits for a single session or pick one of our
											discount packages when booking your tutor.
										</p>
										<hr className='my-10' />
										<p className='fs-12 text-black mb-0 px-10'>
											A Flowently credit costs € 45 when booking a single session or starting as
											low as € 30 when choosing one of the discount packages.
										</p>
									</div>
								</div>
								<hr className='my-20' />
								{isLoading_student ? (
									<div className='text-center'>
										<MDSpinner />
									</div>
								) : (
									<div>
										{!isEmpty(notification_student) ? (
											<Notification notification={notification_student} />
										) : isLoading_student ? (
											<div>
												<LoadingGift />
												<p className='mb-0 fs-14 text-center letter-spacing-0'>
													Getting your current flowently credit
												</p>
											</div>
										) : (
											<div>
												{this.props.auth.isAuthenticated ? (
													<div>
														{isCreditExpired ? (
															<p className='mb-0 fs-14 text-center text-danger'>
																Your credit has expired.
																<br />
																You can't book tutor with your expired credits. You need
																to top up your credit balance to book this tutor.
															</p>
														) : (
															<p className='mb-0 fs-14 text-center'>
																You have
																<span className='fs-14 text-main-pink mx-1'>
																	{!isEmpty(student_data)
																		? `${student_data.credit_balance.toFixed(
																				2
																		  )} flowently credits`
																		: '0 flowently credit'}{' '}
																</span>
																left on your account.
															</p>
														)}
														<div className='mt-20 text-center'>
															<button
																onClick={() => this.onClickBookNow(book_price)}
																className='btn btn-main-pink py-10'
																style={{
																	width: '100%',
																}}
															>
																Book now
															</button>
														</div>
													</div>
												) : (
													<div>
														<button
															onClick={this.openLoginModalHandler}
															className='btn btn-main-pink py-10'
															style={{
																width: '100%',
															}}
														>
															Book now
														</button>
													</div>
												)}
											</div>
										)}
										{USP_render}
									</div>
								)}
							</div>
						</div>
					</div>
					{isOpenLoginModal ? (
						<LoginModal
							history={this.props.history}
							isMobileScreen={isMobileScreen}
							closeModal={this.closeModal}
							getStudentData={this.getStudentData}
							selectedTutorName={this.props.match.params.name}
						/>
					) : null}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default withRouter(
	connect(mapStateToProps, {
		userGetSingleProfile,
		setSelectedTutor,
		userGetTrialPackages,
		userGetTutorDetailByName,
	})(TutorDetails)
);
