import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MDSpinner from 'react-md-spinner';

import Notification from '../common/Notification';
import TextFieldGroupLogin from '../common/TextFieldGroupLogin';
import CustomCheckbox from '../common/CustomCheckbox';

import { connect } from 'react-redux';
import validateInput from '../common/validations/login';
import { cleanCache, userLogin } from '../../redux/actions/authActions';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      isLoading: false,
      notification: {},
      errors: {},
      type: 'student',
      isMobileScreen: false,
    };
  }

  componentWillMount = () => {
    const { isAuthenticated } = this.props.auth;
    const pathname = this.props.history.location.pathname.includes('/login');
    if (isAuthenticated && pathname) {
      this.props.history.push('/');
    }
  };

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentDidMount() {
    window.addEventListener('screenSize', this.screenSize());
  }

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, notification: {}, errors: {} });
    if (this.isValid()) {
      const dataToSubmit = {
        type: this.state.type,
        email: this.state.email,
        password: this.state.password,
        rememberMe: this.state.rememberMe,
      };
      this.props.userLogin(dataToSubmit).then((res) => {
        if (res.status === 200) {
          this.setState({ isLoading: false });
          this.props.history.push('/');
        } else if (res.status === 401) {
          this.setState({
            isLoading: false,
            notification: {
              text: res.message,
              isError: true,
              path: '/set-password/student',
              email: this.state.email,
              type: this.state.type,
            },
          });
        } else if (res.status === 404) {
          this.setState({
            isLoading: false,
            notification: {
              text: res.message,
              path: '/login/tutor',
              isError: true,
            },
          });
        } else {
          this.setState({
            isLoading: false,
            notification: {
              text: res.message,
              isError: true,
            },
          });
        }
      });
    } else {
      this.setState({
        isLoading: false,
        notification: {
          text: 'Please complete the form',
          isError: true,
        },
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeRememberMe = () => {
    this.setState({ rememberMe: !this.state.rememberMe });
  };

  render() {
    const {
      email,
      password,
      isLoading,
      rememberMe,
      notification,
      errors,
      type,
      isMobileScreen,
    } = this.state;

    return (
      <div>
        <h5 className="text-center fs-26 font-weight-bold mb-4">
          Login to book your session
        </h5>
        {notification.text && <Notification notification={notification} />}
        <form className="" onSubmit={this.onSubmit}>
          <TextFieldGroupLogin
            autoFocus={true}
            error={errors.email}
            label="Email"
            onChange={this.onChange}
            value={email}
            field="email"
          />
          <TextFieldGroupLogin
            error={errors.password}
            label="Password"
            onChange={this.onChange}
            value={password}
            field="password"
            type="password"
          />
          <div className="form-group flexbox">
            <label className=" px-1">
              <CustomCheckbox
                onChange={this.onChangeRememberMe}
                className="mr-3"
                checked={rememberMe}
              />
              Keep me logged in
            </label>
            <Link
              to={`/forget-password/${type}`}
              disabled={isLoading}
              className="primary-hover fs-13">
              Forgot password?
            </Link>
          </div>

          {isMobileScreen ? (
            <div className="d-flex justify-content-center fs-12 mt-30 px-20 ">
              <Link to="/login/tutor" className="primary-hover fs-13">
                Login as tutor
              </Link>
            </div>
          ) : (
            <div className="d-flex justify-content-between fs-12 mt-30 px-20 ">
              <Link to="/login/tutor" className="primary-hover fs-13">
                Login as tutor
              </Link>
              <Link to="/signup" className="primary-hover fs-13">
                Don`t have an account? Sign up
              </Link>
            </div>
          )}

          <div className="form-group mt-10">
            <button
              disabled={isLoading}
              className="btn btn-block p-10 btn-main-pink fs-16 fw-500">
              {isLoading ? <MDSpinner /> : 'Login'}
            </button>
          </div>

          {isMobileScreen ? (
            <div className="d-flex my-20 fs-12 justify-content-center">
              <Link to="/signup" className="primary-hover fs-13">
                Don`t have an account? Sign up
              </Link>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { userLogin, cleanCache })(LoginForm);
