import isEmpty from 'lodash/isEmpty';

export function validateEditInput(data, type) {
  let errors = {};

  if (isEmpty(data.time_session)) {
    errors.time_session = 'Time is required';
  }

  if (type === 'live') {
    if (isEmpty(data.meeting_point)) {
      errors.meeting_point = 'Meeting point is required';
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}
