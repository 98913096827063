import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MDSpinner from 'react-md-spinner';

import validateInput from '../common/validations/forgotPassword';
import TextFieldGroupLogin from '../common/TextFieldGroupLogin';
import Notification from '../common/Notification';

import { connect } from 'react-redux';
import { userForgotPassword } from '../../redux/actions/authActions';

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      type: 'student',
      isLoading: false,
      notification: {},
      errors: {},
    };
  }

  componentWillMount = () => {
    if (
      this.props.history.location.state &&
      this.props.history.location.state.email
    ) {
      this.setState({
        email: this.props.history.location.state.email,
      });
    }
    if (
      this.props.history.location.state &&
      this.props.history.location.state.type
    ) {
      this.setState({
        type: this.props.history.location.state.type,
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  isValid() {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, notification: {} });
    if (this.isValid()) {
      const dataToSubmit = {
        email: this.state.email,
        type: this.state.type,
      };

      this.props
        .userForgotPassword(dataToSubmit)
        .then((res) => {
          if (res.status !== 200) {
            this.setState({
              isLoading: false,
              notification: {
                text: res.message,
                isError: true,
              },
            });
          } else {
            this.setState({
              isLoading: false,
              notification: {
                text: "We've sent your reset token. Check your email to reset your password.",
                isError: false,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
            notification: {
              text: 'Failed to sending reset token through email. Please try again later.',
              isError: true,
            },
          });
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { email, type, isLoading, notification, errors } = this.state;

    return (
      <div className="card card-round card-shadowed px-50 py-30 w-400px mb-0">
        <img
          className="logo-login"
          src="/assets/img/logo.png"
          alt="logo icon"
        />
        {notification.text && <Notification notification={notification} />}
        <p className="fs-14 text-center">
          We`ll send you instructions via email
        </p>
        <form className="" onSubmit={this.onSubmit}>
          <TextFieldGroupLogin
            autoFocus={true}
            error={errors.email}
            label="Email"
            onChange={this.onChange}
            value={email}
            field="email"
            disabled={isLoading}
          />
          <div className="form-group">
            <button
              disabled={isLoading}
              className="btn btn-block p-6 btn-main-pink fs-14 fw-500">
              {isLoading ? <MDSpinner /> : 'Send instructions'}
            </button>
            <Link
              to={`/login/${type}`}
              disabled={isLoading}
              className="btn btn-block p-6 btn-main-pink fs-14 fw-500">
              Login
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(null, { userForgotPassword })(SetPassword);
