import isEmpty from 'lodash/isEmpty';
import Validator from 'validator';

export function validateProfile(data) {
  let errors = {};

  if (isEmpty(data.first_name)) {
    errors.first_name = 'First name is required';
  }
  if (isEmpty(data.last_name)) {
    errors.last_name = 'Last name is required';
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

export function validateInputLoginDetails(data) {
  let errors = {};

  if (isEmpty(data.current_password)) {
    errors.current_password = 'Please input your current password';
  }
  if (isEmpty(data.new_password)) {
    errors.new_password = 'Please input your new password';
  }
  if (isEmpty(data.confirm_password)) {
    errors.confirm_password = 'Please confirm your new password';
  } else {
    if (data.new_password !== data.confirm_password) {
      errors.confirm_password = 'Password does not match with new password';
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

export function validateAddressDetails(data) {
  let errors = {};

  if (isEmpty(data.phone)) {
    errors.phone = 'Phone number is required';
  }
  if (isEmpty(data.street_name)) {
    errors.street_name = 'Street name is required';
  }
  if (isEmpty(data.house_number)) {
    errors.house_number = 'House number is required';
  }
  if (isEmpty(data.postcode)) {
    errors.postcode = 'Postal code is required';
  }
  if (isNaN(data.phone)) {
    errors.phone = 'Phone number must be a number';
  }
  if (isEmpty(data.living_in_country)) {
    errors.living_in_country = 'Please select country you lives in';
  } else {
    if (isEmpty(data.living_in_city)) {
      errors.living_in_city = 'Please select country you lives in';
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

export function validateEmergencyContact(data) {
  let errors = {};

  if (isEmpty(data.email_ec)) {
    errors.email_ec = 'Email is required';
  } else {
    if (!Validator.isEmail(data.email_ec)) {
      errors.email_ec = 'Please insert a valid email';
    }
  }

  if (isEmpty(data.full_name_ec)) {
    errors.full_name_ec = 'Fullname is required';
  }

  if (isEmpty(data.street_name_ec)) {
    errors.street_name_ec = 'Street name is required';
  }

  if (isEmpty(data.house_number_ec)) {
    errors.house_number_ec = 'House number is required';
  }

  if (isEmpty(data.postcode_ec)) {
    errors.postcode_ec = 'Postal code is required';
  }

  if (isEmpty(data.phone_ec)) {
    errors.phone_ec = 'Phone number is required';
  } else {
    if (isNaN(data.phone_ec)) {
      errors.phone_ec = 'Phone number must be a number';
    }
  }

  if (isEmpty(data.country_ec)) {
    errors.country_ec = 'Please select country you lives in';
  } else {
    if (isEmpty(data.city_ec)) {
      errors.city_ec = 'Please select country you lives in';
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

export function validateInputReview(data, rating) {
  let errors = {};

  if (rating === '' || parseInt(rating) === 0) {
    errors.rating_value = "Rating can't be empty";
  }

  // REMOVE REQUIRED REVIEW TITLE - REQ CLIENT
  // if (!isEmpty(data.review)) {
  //   if (isEmpty(data.review.title)) {
  //     errors.review_title = 'This field is required';
  //   }
  // } else {
  //   errors.review_title = 'This field is required';
  // }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}
