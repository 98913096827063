import React, { Component } from 'react';
import moment from 'moment';
import { isEmpty, remove } from 'lodash';

import MDSpinner from 'react-md-spinner';
import Notification from '../../../common/Notification';
import SwitchButton from '../../../common/SwitchButton';
import CustomCheckbox from '../../../common/CustomCheckbox';
import SelectFieldGroup from '../../../common/SelectFieldGroup';
import TextAreaFieldGroup from '../../../common/TextAreaFieldGroup';
import TextFieldGroupLogin from '../../../common/TextFieldGroupLogin';
import MultiSelectFieldGroup from '../../../common/MultiSelectFieldGroup';
import SelectLanguageFieldGroup from '../../../common/SelectLanguageFieldGroup';

import {
  // gender_options,
  session_type_options,
  // language_options,
  topics_options,
  timezone_options,
} from '../../../common/SelectOptionsData';

import { connect } from 'react-redux';
import {
  validateDisplayInfo,
  validatePersonalInfo,
} from '../../../common/validations/tutorProfile';
import {
  userGetSingleProfile,
  userUpdateProfileInfo,
  userUploadAvatar,
} from '../../../../redux/actions/tutorActions';
import {
  userGetAllCountries,
  userGetAllLanguages,
  userGetAllWorldCountries,
  userGetCountryDetails,
} from '../../../../redux/actions/commonActions';

class TutorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfile: false,
      data: {
        // Personal Info
        first_name: '',
        last_name: '',
        date_of_birth: '',
        gender: null,
        skype_id: '',
        timezone: null,
        // Display Info
        display_name: '',
        background: '',
        introduction: '',
        recent_youtube_link: '',
        new_youtube_link: '',
        country: [],
        city: [],
        teach_language: [],
        bridge_language: [],
        session_type: [],
        topics: [],
        closeAppointment: false,
        day_available: [],
        time_available: [],
      },
      videoInputEdit: false,
      language_options: [],
      country_options: [],
      city_options: [{ city: [] }],
      isLoading_fetch: true,
      isLoading_personal: false,
      isLoading_display: false,
      errors_personal: {},
      errors_display: {
        country: [null],
        city: [null],
      },
      notification: {},
      notification_personal: {
        // text: "Please complete the form below",
        // isError: true
      },
      notification_display: {},
      editPersonalSuccess: false,
      editDisplaySuccess: false,
      isMobileScreen: false,
      isOpenUploadForm: false,
      isLoading_image: false,
      imageFile: null,
      previewImg: '/assets/img/icon/male.svg',
      errors_image: '',
    };
  }

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentDidMount = () => {
    window.addEventListener('screenSize', this.screenSize());
    window.scrollTo(0, 0);
    this.setState({ isLoading_fetch: true, errors: {}, notification: {} });
    this.getAllWorldCountries();
    this.getAllLanguages();
    this.props
      .userGetSingleProfile({ _id: this.props.auth.user.id })
      .then((res) => {
        if (res.status === 200) {
          const data = {
            ...res.message.data,
            recent_youtube_link: res.message.data.youtube_link,
            new_youtube_link: res.message.data.youtube_link,
            date_of_birth: res.message.data.date_of_birth
              ? moment(res.message.data.date_of_birth, 'MM/DD/YYYY').format(
                  'DD-MM-YYYY',
                )
              : '',
          };

          if (res.message.data.country && !isEmpty(res.message.data.country)) {
            let new_city_options = [];
            res.message.data.country.map((item, idx) => {
              new_city_options.push({ city: [] });

              this.props
                .userGetCountryDetails({ country_code: item.value })
                .then((response) => {
                  if (!isEmpty(response.message.data)) {
                    new_city_options[idx] = {
                      city: response.message.data[0].city_options,
                    };
                  }
                  this.setState({
                    isLoading_fetch: false,
                    showProfile: true,
                    city_options: new_city_options,
                    data,
                  });
                })
                .catch((error) => {
                  console.log(error);
                  this.setState({ isLoading_fetch: false });
                });

              return item;
            });
          } else {
            this.getAllWorldCountries();
            this.setState({
              isLoading_fetch: false,
              showProfile: true,
              data,
            });
          }
        } else {
          this.setState({
            isLoading_fetch: false,
            notification: {
              text: 'Failed to load your profile data. Please try again later.',
              isError: true,
            },
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading_fetch: false,
          notification: {
            text: 'Failed to load your profile data. Please try again later.',
            isError: true,
          },
        });
      });
  };

  getAllLanguages = () => {
    this.props
      .userGetAllLanguages()
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            language_options: res.message.data,
          });
          return res.message.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllWorldCountries = () => {
    this.props
      .userGetAllWorldCountries()
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            country_options: res.message.data,
          });
          return res.message.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChangePersonal = (e) => {
    const data = {
      ...this.state.data,
      [e.target.name]: e.target.value,
    };
    this.setState({
      data,
      errors_personal: {
        ...this.state.errors_personal,
        [e.target.name]: null,
      },
    });
  };

  onChangeDisplay = (e) => {
    if (e.target.name === 'background') {
      const words_length = e.target.value.split(' ').length;

      if (words_length === 11) {
        const value = this.state.data.background;
        const data = {
          ...this.state.data,
          background: value,
        };
        this.setState({
          data,
          errors_display: {
            ...this.state.errors_display,
            background: null,
          },
        });
      } else {
        const value = e.target.value;
        const data = {
          ...this.state.data,
          background: value,
        };
        this.setState({
          data,
          errors_display: {
            ...this.state.errors_display,
            background: null,
          },
        });
      }
    } else if (e.target.name === 'introduction') {
      const words_length = e.target.value.split(' ').length;
      if (words_length === 100) {
        const value = this.state.data.introduction;
        const data = {
          ...this.state.data,
          introduction: value,
        };
        this.setState({
          data,
          errors_display: {
            ...this.state.errors_display,
            introduction: null,
          },
        });
      } else {
        const value = e.target.value;
        const data = {
          ...this.state.data,
          introduction: value,
        };
        this.setState({
          data,
          errors_display: {
            ...this.state.errors_display,
            introduction: null,
          },
        });
      }
    } else {
      const data = {
        ...this.state.data,
        [e.target.name]: e.target.value,
      };
      this.setState({
        data,
        errors_display: {
          ...this.state.errors_display,
          [e.target.name]: null,
        },
      });
    }
  };

  onChangeDate = (date) => {
    const data = {
      ...this.state.data,
      date_of_birth: date,
    };
    this.setState({ data });
  };

  onChangeSelect = (e) => {
    const data = {
      ...this.state.data,
      [e.target.name]: e.target.value,
    };

    this.setState({
      data,
      errors_personal: {
        ...this.state.errors_personal,
        [e.target.name]: null,
      },
      errors_display: {
        ...this.state.errors_display,
        [e.target.name]: null,
      },
    });
  };

  onChangeSwitch = () => {
    const data = {
      ...this.state.data,
      closeAppointment: !this.state.data.closeAppointment,
    };
    this.setState({ data });
  };

  onChangeDayAvailability = (e) => {
    const { data } = this.state;

    let new_data = [...data.day_available];

    if (data.day_available.includes(e.target.name)) {
      new_data = remove(data.day_available, (item) => {
        return item !== e.target.name;
      });
    } else {
      new_data.push(e.target.name);
    }

    this.setState({
      data: {
        ...data,
        day_available: new_data,
      },
    });
  };

  onChangeTimeAvailability = (e) => {
    const { data } = this.state;

    let new_data = [...data.time_available];

    if (data.time_available.includes(e.target.name)) {
      new_data = remove(data.time_available, (item) => {
        return item !== e.target.name;
      });
    } else {
      new_data.push(e.target.name);
    }

    this.setState({
      data: {
        ...data,
        time_available: new_data,
      },
    });
  };

  isValidPersonalInfo = () => {
    const { errors, isValid } = validatePersonalInfo(this.state.data);
    if (!isValid) {
      this.setState({ errors_personal: errors });
    }
    return isValid;
  };

  isValidDisplayInfo = () => {
    const { errors, isValid } = validateDisplayInfo(this.state.data);
    if (!isValid) {
      this.setState({ errors_display: errors });
    }
    return isValid;
  };

  onSubmitPersonalInfo = (e) => {
    e.preventDefault();
    const { data } = this.state;
    this.setState({
      isLoading_personal: true,
      notification_personal: {},
      errors_personal: {},
    });
    const dataToSubmit = {
      _id: this.props.auth.user.id,
      type: 'tutor',
      data: {
        first_name: data.first_name,
        last_name: data.last_name,
        date_of_birth: moment(data.date_of_birth, 'DD-MM-YYYY').format(
          'MM/DD/YYYY',
        ),
        gender: data.gender,
        skype_id: data.skype_id,
        timezone: data.timezone,
      },
    };
    if (this.isValidPersonalInfo()) {
      this.props.userUpdateProfileInfo(dataToSubmit).then((res) => {
        if (res.status === 200) {
          this.setState({
            isLoading_personal: false,
            data: {
              ...res.message.data,
              date_of_birth: moment(
                res.message.data.date_of_birth,
                'MM/DD/YYYY',
              ).format('DD-MM-YYYY'),
            },
            editPersonalSuccess: true,
          });
          setTimeout(() => {
            this.setState({ editPersonalSuccess: false });
          }, 2000);
        } else {
          this.setState({
            isLoading_personal: false,
            notification_personal: {
              text: 'We are experiencing technical difficulties at the moment, please try again later',
              isError: true,
            },
          });
        }
      });
    } else {
      this.setState({
        isLoading_personal: false,
        notification_personal: {
          text: 'Please complete the form above',
          isError: true,
        },
      });
    }
  };

  onSubmitDisplayInfo = (e) => {
    e.preventDefault();
    const { data } = this.state;
    this.setState({
      isLoading_display: true,
      notification_display: {},
      errors_display: {},
    });
    const dataToSubmit = {
      _id: this.props.auth.user.id,
      type: 'tutor',
      data: {
        display_name: data.display_name,
        background: data.background,
        introduction: data.introduction,
        country: data.country,
        city: data.city,
        teach_language: data.teach_language,
        bridge_language: data.bridge_language,
        session_type: data.session_type,
        topics: data.topics,
        closeAppointment: data.closeAppointment,
        day_available: data.day_available,
        time_available: data.time_available,
        youtube_link: data.youtube_link,
      },
    };
    if (this.isValidDisplayInfo()) {
      this.props.userUpdateProfileInfo(dataToSubmit).then((res) => {
        if (res.status === 200) {
          this.setState({
            isLoading_display: false,
            data: {
              ...res.message.data,
              // recent_youtube_link: res.message.data.youtube_link,
              // new_youtube_link: res.message.data.youtube_link
              youtube_link: res.message.data.youtube_link,
            },
            editDisplaySuccess: true,
          });
          setTimeout(() => {
            this.setState({ editDisplaySuccess: false });
          }, 2000);
        } else {
          this.setState({
            isLoading_display: false,
            notification_display: {
              text: 'We are experiencing technical difficulties at the moment, please try again later',
              isError: true,
            },
          });
        }
      });
    } else {
      this.setState({
        isLoading_display: false,
        notification_display: {
          text: 'Please complete the form above',
          isError: true,
        },
      });
    }
  };

  loadOptionsCountry = (inputValue, callback) => {
    this.props
      .userGetAllCountries({ query: inputValue })
      .then((response) => {
        callback(response.message.data);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  onChangeSelectCountry = (val, idx) => {
    const { data, city_options, errors_display } = this.state;

    const new_city_options = city_options.map((item, sidx) => {
      if (idx !== sidx) {
        return item;
      } else {
        return {
          ...item,
          city: val.city_options,
        };
      }
    });

    const new_country = data.country.map((item, sidx) => {
      if (idx !== sidx) {
        return item;
      } else {
        return {
          value: val.value,
          label: val.label,
        };
      }
    });

    if (data.country.length === 0 && idx === 0) {
      new_country.push({
        value: val.value,
        label: val.label,
      });
    }

    const new_city = data.city.map((item, sidx) => {
      if (idx !== sidx) {
        return item;
      } else {
        return null;
      }
    });

    const country_error = errors_display.country.map((item, sidx) => {
      if (idx !== sidx) {
        return item;
      } else {
        return null;
      }
    });

    this.setState({
      data: {
        ...data,
        country: new_country,
        city: new_city,
      },
      errors_display: {
        ...errors_display,
        country: country_error,
      },
      city_options: new_city_options,
    });
  };

  onChangeUploadImage = (e) => {
    this.setState({
      errors_image: '',
      imageFile: e.target.files[0],
      previewImg: URL.createObjectURL(e.target.files[0]),
    });
  };

  cancelUpload = () => {
    this.setState({
      imageFile: null,
      errors_image: '',
      previewImg: '/assets/img/icon/male.svg',
      isOpenUploadForm: false,
    });
  };

  onSaveImageUpload = () => {
    this.setState({ errors_image: '', isLoading_image: true });
    const dataToSubmit = {
      file: this.state.imageFile,
      _id: this.props.auth.user.id,
    };
    this.props
      .userUploadAvatar(dataToSubmit)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            isLoading_image: false,
            previewImg: '',
            data: {
              ...this.state.data,
              avatar_display: res.avatar,
            },
            isOpenUploadForm: false,
          });
        } else {
          this.setState({
            isLoading_image: false,
            errors_image:
              'Failed to upload image. Please make sure the uploaded image matches with the above conditions',
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading_image: false,
          errors_image: 'Internal server error. Please try again later',
        });
      });
  };

  addCityHandler = () => {
    const { data, city_options } = this.state;
    const added_data = null;

    this.setState({
      data: {
        ...data,
        country: data.country.concat([added_data]),
        city: data.city.concat([added_data]),
      },
      city_options: city_options.concat({ city: [] }),
    });
  };

  removeCityHandler = (idx) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        country: data.country.filter((s, sidx) => idx !== sidx),
        city: data.city.filter((s, sidx) => idx !== sidx),
      },
    });
  };

  onChangeCity = (value, idx) => {
    const { data, errors_display } = this.state;
    const new_data = data.city.map((item, sidx) => {
      if (idx !== sidx) {
        return item;
      } else {
        return value;
      }
    });

    if (data.city.length === 0 && idx === 0) {
      new_data.push(value);
    }

    const city_error = errors_display.city.map((item, sidx) => {
      if (idx !== sidx) {
        return item;
      } else {
        return null;
      }
    });

    this.setState({
      data: {
        ...data,
        city: new_data,
      },
      errors_display: {
        ...errors_display,
        city: city_error,
      },
    });
  };

  render() {
    const {
      isMobileScreen,
      data,
      // videoInputEdit,
      language_options,
      country_options,
      city_options,
      showProfile,
      isLoading_personal,
      isLoading_display,
      notification,
      notification_personal,
      notification_display,
      errors_personal,
      errors_display,
      editPersonalSuccess,
      editDisplaySuccess,
      isOpenUploadForm,
      imageFile,
      previewImg,
      errors_image,
      isLoading_image,
      isLoading_fetch,
    } = this.state;

    let render_city = null;

    if (data.city && data.city.length > 0) {
      render_city = data.city.map((item, idx) => {
        return (
          <div key={`City-${idx + 1}`} className="row m-auto">
            <div className={idx === 0 ? 'col-md-6 p-0' : 'col-md-6 p-0'}>
              <div className={isMobileScreen ? '' : 'pr-10'}>
                <SelectLanguageFieldGroup
                  loadingRender={isLoading_fetch}
                  label="Country"
                  labelClass={!isMobileScreen && idx > 0 ? 'd-none' : ''}
                  labelWeightNormal={true}
                  value={data.country[idx]}
                  options={country_options}
                  onChange={(val) => this.onChangeSelectCountry(val, idx)}
                  error={
                    errors_display && errors_display.country
                      ? errors_display.country[idx]
                      : null
                  }
                  isClearable={false}
                  isDisabled={isLoading_display}
                  isSearchable={true}
                />
              </div>
            </div>
            <div className={idx === 0 ? 'col-md-6 p-0' : 'col-md-5 p-0'}>
              <div className={isMobileScreen ? '' : 'pl-10'}>
                <SelectLanguageFieldGroup
                  loadingRender={isLoading_fetch}
                  label="City"
                  labelClass={!isMobileScreen && idx > 0 ? 'd-none' : ''}
                  labelWeightNormal={true}
                  value={item}
                  options={city_options[idx].city}
                  onChange={(val) => this.onChangeCity(val, idx)}
                  error={
                    errors_display && errors_display.city
                      ? errors_display.city[idx]
                      : null
                  }
                  isClearable={false}
                  isDisabled={isLoading_display}
                  isSearchable={true}
                />
              </div>
            </div>
            {idx > 0 ? (
              <div
                className={
                  isMobileScreen
                    ? 'col-md-1'
                    : 'col-md-1 d-flex align-items-center p-0'
                }>
                <SelectLanguageFieldGroup
                  loadingRender={isLoading_fetch}
                  label="Delete button"
                  labelClass={!isMobileScreen && idx > 0 ? 'd-none' : 'd-none'}
                  buttonClass={isMobileScreen ? 'm-auto' : ''}
                  buttonText={isMobileScreen ? 'Remove' : ''}
                  deleteButton={true}
                  onClick={() => this.removeCityHandler(idx)}
                />
              </div>
            ) : null}
            {idx !== data.city.length - 1 && isMobileScreen ? (
              <div className="col-12">
                <hr
                  className={idx === 0 ? 'my-20' : 'mt-0 mb-20'}
                  style={{ borderTop: '1px solid #e1e1e1' }}
                />
              </div>
            ) : null}
          </div>
        );
      });
    }

    const avatarImg = isEmpty(data.avatar_display)
      ? previewImg
      : isOpenUploadForm
      ? previewImg
      : data.avatar_display;

    let success_sessions = [];
    if (data.appointments) {
      success_sessions = data.appointments.filter(
        (item) => item.booking_status === 'success',
      );
    }

    const total_sessions = success_sessions.length;
    const total_reviews =
      data.reviews && !isEmpty(data.reviews) ? data.reviews.length : 0;
    const total_ratings = data.total_rating ? data.total_rating : 0;

    return (
      <div className="col-md-8">
        <div className="card">
          <div
            className={
              isMobileScreen
                ? 'card-body px-20 pt-20 pb-0'
                : 'card-body px-40 pt-40 pb-0'
            }>
            <div className="mb-20">
              <h3 className="fw-600 fs-20 p-20">Tutor Record</h3>
              <div
                className="row m-auto"
                style={{ paddingLeft: 18, paddingRight: 18 }}>
                <div
                  className="col-md-4 d-flex justify-content-center align-items-center tutor-record-box"
                  style={{
                    borderRadius: isMobileScreen
                      ? '8px 8px 0 0'
                      : '25px 0 0 25px',
                    border: 'solid 1.2px #e1e1e1',
                    padding: 0,
                  }}>
                  <p className="my-10">
                    {total_sessions}{' '}
                    {total_sessions > 1 ? 'sessions' : 'session'}
                  </p>
                </div>
                <div
                  className="col-md-4 d-flex justify-content-center align-items-center tutor-record-box"
                  style={{
                    borderRadius: 0,
                    border: 'solid 1.2px #e1e1e1',
                    padding: 0,
                  }}>
                  <p className="my-10">
                    {total_reviews} {total_reviews > 1 ? 'reviews' : 'review'}
                  </p>
                </div>
                <div
                  className="col-md-4 d-flex justify-content-center align-items-center tutor-record-box"
                  style={{
                    borderRadius: isMobileScreen
                      ? '0 0 8px 8px'
                      : '0 25px 25px 0',
                    border: 'solid 1.2px #e1e1e1',
                    padding: 0,
                  }}>
                  <p className="my-10">
                    {total_ratings} total{' '}
                    {total_ratings > 1 ? 'ratings' : 'rating'}
                  </p>
                </div>
              </div>
            </div>
            <h3 className="fw-600 fs-20 p-20">Personal Information</h3>
            <div className="row mx-auto mb-40 px-20">
              <div className="col-md-3 px-20 d-flex justify-content-center align-items-center">
                <div
                  style={{
                    backgroundImage: `url("${avatarImg}")`,
                    backgroundPosition: 'top',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '50%',
                    boxShadow: '0px 2px 15px #e7e7e7',
                    height: 150,
                    width: 150,
                    margin: isMobileScreen ? 'auto' : 0,
                  }}></div>
              </div>
              <div
                className={
                  isMobileScreen ? 'col-md-9 mt-30' : 'col-md-9 pl-40'
                }>
                <h5>Edit Profile Photo</h5>
                <ul className="pl-20" style={{ color: '#6B6B6B' }}>
                  <li>At least 500 x 500 pixels</li>
                  <li>JPG, PNG, BMP formats only</li>
                  <li>Maximum size of 2 MB</li>
                </ul>
                {isOpenUploadForm ? (
                  <form encType="multipart/form-data">
                    <div className="input-group form-type-line file-group">
                      <input
                        type="text"
                        name="avatar"
                        style={{
                          borderBottom: errors_image ? '1px solid red' : '',
                        }}
                        className="form-control file-value"
                        placeholder="Choose file..."
                        readOnly
                      />
                      <input
                        type="file"
                        multiple={true}
                        ref="avatar"
                        name="avatar"
                        onChange={this.onChangeUploadImage}
                      />
                      {!imageFile ? (
                        <span className="input-group-append">
                          <button
                            style={{ borderRadius: 4 }}
                            className="btn btn-main-pink file-browser"
                            type="button">
                            <i className="fa fa-upload mr-2"></i>
                            Choose file
                          </button>
                          <button
                            onClick={this.cancelUpload}
                            style={{ borderRadius: 4 }}
                            className="btn border-0 button-transparent text-dark"
                            type="button">
                            <i className="ion-close-round f mr-2"></i>
                          </button>
                        </span>
                      ) : null}
                      {isLoading_image ? (
                        <MDSpinner />
                      ) : imageFile ? (
                        <span className="input-group-append">
                          <button
                            onClick={this.cancelUpload}
                            style={{ borderRadius: 4 }}
                            className="btn button-transparent mr-2"
                            type="button">
                            Cancel
                          </button>
                          <button
                            onClick={this.onSaveImageUpload}
                            style={{ borderRadius: 4 }}
                            className="btn btn-main-pink"
                            type="button">
                            Save
                          </button>
                        </span>
                      ) : null}
                    </div>
                  </form>
                ) : (
                  <div>
                    <button
                      onClick={() => this.setState({ isOpenUploadForm: true })}
                      className="btn button-transparent btn-w-xl d-block"
                      style={{
                        borderWidth: 2,
                        margin: isMobileScreen ? 'auto' : '0',
                      }}>
                      Upload photo
                    </button>
                  </div>
                )}
                {errors_image && (
                  <div className="form-control-feedback">{errors_image}</div>
                )}
              </div>
            </div>
            <Notification notification={notification} />
            {showProfile ? (
              <div className="row m-auto">
                <div className="col-md-6">
                  <TextFieldGroupLogin
                    loadingRender={isLoading_fetch}
                    label="First Name"
                    field="first_name"
                    value={data.first_name}
                    placeholder="Type something"
                    onChange={this.onChangePersonal}
                    disabled={isLoading_personal}
                    error={errors_personal.first_name}
                  />
                </div>
                <div className="col-md-6">
                  <TextFieldGroupLogin
                    loadingRender={isLoading_fetch}
                    label="Last Name"
                    field="last_name"
                    value={data.last_name}
                    placeholder="Type something"
                    onChange={this.onChangePersonal}
                    disabled={isLoading_personal}
                    error={errors_personal.last_name}
                  />
                </div>
                {/* <div className="col-md-6">
                  <DateSelectGroup
                    loadingRender={isLoading_fetch}
                    value={data.date_of_birth}
                    label='Birthday'
                    type="input-mask"
                    name="date_of_birth"
                    isOptional={true}
                    onChange={this.onChangePersonal}
                    isDisabled={isLoading_personal}
                    error={errors_personal.date_of_birth}
                  />
                </div> */}
                <div className="col-md-6">
                  <SelectFieldGroup
                    label="Time zone"
                    value={data.timezone}
                    options={timezone_options}
                    onChange={(val) =>
                      this.onChangeSelect({
                        target: { name: 'timezone', value: val },
                      })
                    }
                    isClearable={false}
                    isDisabled={isLoading_personal}
                    isSearchable={true}
                    loadingRender={isLoading_fetch}
                    error={errors_personal.timezone}
                  />
                </div>
                <div className="col-md-12 mt-10">
                  <Notification notification={notification_personal} />
                </div>
              </div>
            ) : null}
          </div>
          <div
            className={
              isMobileScreen
                ? 'card-footer px-20 text-center bg-white'
                : 'card-footer text-center px-20 bg-white'
            }>
            {editPersonalSuccess ? (
              <label className="fs-13 mb-0 text-success d-flex align-items-center justify-content-center mt-0 mb-10">
                <span>Changes saved successfully</span>
                <i className="fa fa-check-circle-o fs-20 mx-2"></i>
              </label>
            ) : null}
            {isLoading_personal ? (
              <MDSpinner />
            ) : (
              <button
                onClick={this.onSubmitPersonalInfo}
                className="btn btn-main-pink letter-spacing-0">
                Save changes
              </button>
            )}
          </div>
        </div>
        <div className="card">
          <div
            className={
              isMobileScreen
                ? 'card-body px-35 pt-35 pb-0'
                : 'card-body px-60 pt-60 pb-0'
            }>
            <h3 className="fw-600 fs-20 mb-20">Display Information</h3>
            <TextFieldGroupLogin
              loadingRender={isLoading_fetch}
              label="Display Name"
              field="display_name"
              value={data.display_name}
              placeholder="Type something"
              onChange={this.onChangeDisplay}
              disabled={isLoading_display}
              error={errors_display.display_name}
            />
            <TextFieldGroupLogin
              loadingRender={isLoading_fetch}
              label="Professional background (max. 10 words)"
              field="background"
              value={data.background}
              placeholder="Type something"
              onChange={this.onChangeDisplay}
              disabled={isLoading_display}
              error={errors_display.background}
            />
            <TextAreaFieldGroup
              label="Introduction (max. 100 words)"
              field="introduction"
              value={data.introduction}
              placeholder="Type something"
              onChange={this.onChangeDisplay}
              disabled={isLoading_display}
              error={errors_display.introduction}
            />
            {data.city.length > 0 ? (
              render_city
            ) : (
              <div className="row m-auto">
                <div className="col-md-6 p-0">
                  <div className={isMobileScreen ? '' : 'pr-10'}>
                    <SelectLanguageFieldGroup
                      loadingRender={isLoading_fetch}
                      label="Country"
                      // labelClass={!isMobileScreen && idx > 0 ? "d-none" : ''}
                      labelWeightNormal={true}
                      value={data.country[0]}
                      options={country_options}
                      onChange={(val) => this.onChangeSelectCountry(val, 0)}
                      error={
                        errors_display && errors_display.country
                          ? errors_display.country[0]
                          : null
                      }
                      isClearable={false}
                      isDisabled={isLoading_display}
                      isSearchable={true}
                    />
                  </div>
                </div>
                <div className="col-md-6 p-0">
                  <div className={isMobileScreen ? '' : 'pl-10'}>
                    <SelectLanguageFieldGroup
                      loadingRender={isLoading_fetch}
                      label="City"
                      // labelClass={!isMobileScreen && idx > 0 ? "d-none" : ''}
                      labelWeightNormal={true}
                      value={data.city[0]}
                      options={city_options[0].city}
                      onChange={(val) => this.onChangeCity(val, 0)}
                      error={
                        errors_display && errors_display.city
                          ? errors_display.city[0]
                          : null
                      }
                      isClearable={false}
                      isDisabled={isLoading_display}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </div>
            )}
            {data.city.length <= 4 ? (
              <div className="text-center">
                <button
                  onClick={this.addCityHandler}
                  className="btn button-transparent border-transparent m-auto align-items-center px-15 mb-20"
                  style={{
                    display: isMobileScreen ? 'flex' : 'inline-flex',
                  }}>
                  <i className="ion-plus-round text-info fs-14 mr-1 cursor-pointer"></i>
                  <label className="fs-14 mb-0 text-info cursor-pointer">
                    Add more city
                  </label>
                </button>
              </div>
            ) : null}
            <MultiSelectFieldGroup
              loadingRender={isLoading_fetch}
              label="Language(s) I can teach"
              value={data.teach_language}
              options={language_options}
              onChange={(val) =>
                this.onChangeSelect({
                  target: { name: 'teach_language', value: val },
                })
              }
              error={errors_display.teach_language}
              isClearable={false}
              isDisabled={isLoading_display}
              isSearchable={false}
            />
            <MultiSelectFieldGroup
              loadingRender={isLoading_fetch}
              label="Bridge Language"
              value={data.bridge_language}
              options={language_options}
              onChange={(val) =>
                this.onChangeSelect({
                  target: { name: 'bridge_language', value: val },
                })
              }
              error={errors_display.bridge_language}
              isClearable={false}
              isDisabled={isLoading_display}
              isSearchable={false}
            />
            <MultiSelectFieldGroup
              loadingRender={isLoading_fetch}
              label="Session Type"
              value={data.session_type}
              options={session_type_options}
              onChange={(val) =>
                this.onChangeSelect({
                  target: { name: 'session_type', value: val },
                })
              }
              error={errors_display.session_type}
              isClearable={false}
              isDisabled={isLoading_display}
              isSearchable={false}
            />
            <MultiSelectFieldGroup
              loadingRender={isLoading_fetch}
              label="Topics Available"
              value={data.topics}
              options={topics_options}
              onChange={(val) =>
                this.onChangeSelect({ target: { name: 'topics', value: val } })
              }
              error={errors_display.topics}
              isClearable={false}
              isDisabled={isLoading_display}
              isSearchable={false}
            />
            <TextFieldGroupLogin
              loadingRender={isLoading_fetch}
              label="Add video introduction"
              field="youtube_link"
              value={data.youtube_link || ''}
              placeholder="https://youtu.be/9fVxcpFSUdo"
              onChange={this.onChangeDisplay}
              disabled={isLoading_display}
              error={errors_display.youtube_link}
            />
            <div className="h-90px">
              <label className="col-form-label font-weight-bold fs-15">
                Availability
              </label>
              <div className="row mx-auto mt-1 mb-10">
                <div className="col-md-3 px-0">
                  <label className="mb-10 d-flex align-items-center">
                    <CustomCheckbox
                      name="weekdays"
                      checked={data.day_available.includes('weekdays')}
                      onChange={this.onChangeDayAvailability}
                      disabled={isLoading_display}
                    />
                    <span className="fw-400 ml-2 fs-14 position-relative">
                      Weekdays
                    </span>
                  </label>
                </div>
                <div className="col-md-3 px-0">
                  <label className="mb-10 d-flex align-items-center">
                    <CustomCheckbox
                      name="weekend"
                      checked={data.day_available.includes('weekend')}
                      onChange={this.onChangeDayAvailability}
                      disabled={isLoading_display}
                    />
                    <span className="fw-400 ml-2 fs-14 position-relative">
                      Weekends
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className={isMobileScreen ? 'mt-35' : 'h-90px'}>
              <label className="col-form-label font-weight-bold letter-spacing-0 fs-15">
                What time of the day are you generally available ? Select as
                many as possible time slots.
              </label>
              <div className="row mx-auto mt-1 mb-10">
                <div className="col-md-4 px-0">
                  <label className="mb-10 d-flex align-items-center">
                    <CustomCheckbox
                      name="morning"
                      checked={data.time_available.includes('morning')}
                      onChange={this.onChangeTimeAvailability}
                      disabled={isLoading_display}
                    />
                    <span className="fw-400 ml-2 fs-14 position-relative">
                      Morning ( 8 - 12 )
                    </span>
                  </label>
                </div>
                <div className="col-md-4 px-0">
                  <label className="mb-10 d-flex align-items-center">
                    <CustomCheckbox
                      name="day"
                      checked={data.time_available.includes('day')}
                      onChange={this.onChangeTimeAvailability}
                      disabled={isLoading_display}
                    />
                    <span className="fw-400 ml-2 fs-14 position-relative">
                      Afternoon ( 12 - 18 )
                    </span>
                  </label>
                </div>
                <div className="col-md-4 px-0">
                  <label className="mb-10 d-flex align-items-center">
                    <CustomCheckbox
                      name="evening"
                      checked={data.time_available.includes('evening')}
                      onChange={this.onChangeTimeAvailability}
                      disabled={isLoading_display}
                    />
                    <span className="fw-400 ml-2 fs-14 position-relative">
                      Evening ( 18 - 22 )
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {/* <div className='mb-30'>
              <label className="col-form-label font-weight-bold letter-spacing-0 fs-15">Add video</label>
              <div
                className={isMobileScreen ? 'd-block' : 'd-flex justify-content-between align-items-center' }
                style={{ flexDirection: isMobileScreen ? 'column' : 'row'}}
              >
                <EditableTextInput
                  field='new_youtube_link'
                  empty_value_message="No video uploaded"
                  value={data.new_youtube_link}
                  placeholder='https://youtube.com/your-video-id'
                  onChange={this.onChangeYoutubeLink}
                  disabled={isLoading_display}
                  isEditing={videoInputEdit}
                  error={errors_display.new_youtube_link}
                />
                { !videoInputEdit ?
                  <img className='mt-2' onClick={() => this.setState({ videoInputEdit: true })} src="/assets/img/icon/edit.svg" alt='' />
                : null }
                { videoInputEdit ?
                  <div className={isMobileScreen ? 'd-flex mt-2' : 'd-flex'}>
                    <button onClick={this.onCancelYoutubeLink} className={isMobileScreen ? 'btn btn-outline-main-pink mr-2' : 'btn btn-outline-main-pink mx-2'}>Cancel</button>
                    <button onClick={this.onSaveYoutubeLink} className='btn btn-main-pink'>Save</button>
                  </div>
                : null }
              </div>
            </div> */}
            <hr className="my-10" style={{ border: '1px solid #f9f9f9' }} />
            <div className="d-flex justify-content-between align-items-center">
              <label className="col-form-label font-weight-bold letter-spacing-0 fs-15">
                Currently not accepting new students
              </label>
              <div>
                <SwitchButton
                  value={data.closeAppointment}
                  onChange={this.onChangeSwitch}
                />
              </div>
            </div>
            <p className="mb-0 fs-14" style={{ color: 'rgba(0, 0, 0, 0.7)' }}>
              If you set it ON, only NEW students will not be able to make an
              appointment with you
            </p>
          </div>
          <div
            className={isMobileScreen ? 'card-body px-35' : 'card-body px-60'}>
            <Notification notification={notification_display} />
          </div>
          <div
            className={
              isMobileScreen
                ? 'card-footer px-20 text-center bg-white'
                : 'card-footer text-center px-20 bg-white'
            }>
            {editDisplaySuccess ? (
              <label className="fs-13 mb-0 text-success d-flex align-items-center justify-content-center mt-0 mb-10">
                <span>Changes saved successfully</span>
                <i className="fa fa-check-circle-o fs-20 mx-2"></i>
              </label>
            ) : null}
            <button
              onClick={this.onSubmitDisplayInfo}
              className="btn btn-main-pink letter-spacing-0">
              Save changes
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  userGetSingleProfile,
  userUpdateProfileInfo,
  userGetAllCountries,
  userUploadAvatar,
  userGetAllWorldCountries,
  userGetCountryDetails,
  userGetAllLanguages,
})(TutorProfile);
