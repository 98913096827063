import React from 'react';
import ForgetPassword from './forgetPassword';

function ForgetPasswordPage(props) {
  const { history, match } = props;

  return (
    <div
      className="my-50 center-vh pace-done mx-auto"
      style={{ maxWidth: 1528 }}>
      <ForgetPassword
        history={history}
        type={match.params.type}
        match={match}
      />
    </div>
  );
}

export default ForgetPasswordPage;
