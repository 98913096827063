import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';
import QS from 'query-string';

import validateInput from '../common/validations/resetPassword';
import Notification from '../common/Notification';
import TextFieldGroupLogin from '../common/TextFieldGroupLogin';

import { connect } from 'react-redux';
import { userResetPassword } from '../../redux/actions/authActions';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      email: '',
      new_password: '',
      repeat_new_password: '',
      isLoading: false,
      notification: {},
      errors: {},
    };
  }

  componentWillMount() {
    let queries = QS.parse(this.props.history.location.search);
    if (queries && queries.token) this.setState({ token: queries.token });
    if (queries && queries.email) this.setState({ email: queries.email });
    if (queries && queries.type) this.setState({ type: queries.type });
  }

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name]: null,
      },
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, notification: {}, errors: {} });
    if (this.isValid()) {
      const dataToSubmit = {
        token: this.state.token,
        password: this.state.new_password,
        type: this.state.type,
      };

      this.props
        .userResetPassword(dataToSubmit)
        .then((res) => {
          if (res.status !== 200) {
            this.setState({
              isLoading: false,
              notification: {
                text: res.message,
                isError: true,
              },
            });
          } else {
            this.setState({
              isLoading: false,
              notification: {
                text: 'Your password has been changed successfully.',
                isError: false,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
            notification: {
              text: 'We are experiencing technical difficulties at the moment, please try again later.',
              isError: true,
            },
          });
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      email,
      new_password,
      repeat_new_password,
      isLoading,
      notification,
      errors,
    } = this.state;

    return (
      <div className="card card-round card-shadowed px-50 py-30 w-400px mb-0">
        <img
          className="logo-login"
          src="/assets/img/logo.png"
          alt="logo icon"
        />
        {notification.text && <Notification notification={notification} />}
        <form className="" onSubmit={this.onSubmit}>
          <TextFieldGroupLogin
            autoFocus={true}
            error={errors.email}
            label="Email"
            onChange={this.onChange}
            value={email}
            field="identifier"
            disabled
          />
          <TextFieldGroupLogin
            error={errors.new_password}
            label="New Password"
            onChange={this.onChange}
            value={new_password}
            field="new_password"
            type="password"
            disabled={isLoading}
          />
          <TextFieldGroupLogin
            error={errors.repeat_new_password}
            label="Repeat New Password"
            onChange={this.onChange}
            value={repeat_new_password}
            field="repeat_new_password"
            type="password"
            disabled={isLoading}
          />
          <div className="form-group mt-25">
            <button
              disabled={isLoading}
              className="btn btn-block p-10 btn-main-pink fs-16 fw-500">
              {isLoading ? <MDSpinner /> : 'Create password'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(null, { userResetPassword })(ResetPassword);
